import {
  SET_SELECT_FIELD_OPTIONS,
  SET_USER,
  SET_ARTICLE_SEARCH_FORM_DATA,
  SET_ARTICLE_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_ARTICLE_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_CLUSTER_SEARCH_FORM_DATA,
  SET_CLUSTER_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_CLUSTER_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_VERSION_HISTORY_TABLE_CONFIG_PAGE_INDEX,
  SET_VERSION_HISTORY_TABLE_CONFIG_PAGE_SIZE,
  SET_INDEX_SEARCH_FORM_DATA,
  SET_INDEX_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_INDEX_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_MY_WORK_TABLE_CONFIG_PAGE_INDEX,
  SET_MY_WORK_TABLE_CONFIG_PAGE_SIZE,
  SET_MY_WORK_TABLE_CONFIG_FILTERS,
  SET_LOGIN_MODAL_CONFIG,
  SET_LOGIN_MODAL_CONFIG_IS_OPEN,
  SET_LOGIN_MODAL_CONFIG_ID,
  SET_MY_WORK_SORT_CONFIG,
  SET_FEATURE_SEARCH_FORM_DATA,
  SET_FEATURE_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_FEATURE_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_FULL_TEXT_SEARCH_FORM_DATA,
  SET_FULL_TEXT_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_FULL_TEXT_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_RECENT_ARTICLES_TABLE_CONFIG_PAGE_INDEX,
  SET_RECENT_ARTICLES_TABLE_CONFIG_PAGE_SIZE,
} from './action-types';

const reducer = (state = {}, action) => {
  if (typeof action === 'undefined') {
    return state;
  }

  switch (action.type) {
    case SET_SELECT_FIELD_OPTIONS:
      return {
        ...state,
        [action.selectFieldOptionKey]: action.selectFieldOptions,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case SET_ARTICLE_SEARCH_FORM_DATA:
      return {
        ...state,
        articleSearchFormData: action.articleSearchFormData,
      };
    case SET_ARTICLE_SEARCH_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        articleSearchTablePageIndex: action.articleSearchTablePageIndex,
      };
    case SET_ARTICLE_SEARCH_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        articleSearchTablePageSize: action.articleSearchTablePageSize,
      };
    case SET_CLUSTER_SEARCH_FORM_DATA:
      return {
        ...state,
        clusterSearchFormData: action.clusterSearchFormData,
      };
    case SET_CLUSTER_SEARCH_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        clusterSearchTablePageIndex: action.clusterSearchTablePageIndex,
      };
    case SET_CLUSTER_SEARCH_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        clusterSearchTablePageSize: action.clusterSearchTablePageSize,
      };
    case SET_VERSION_HISTORY_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        versionHistoryTablePageIndex: action.versionHistoryTablePageIndex,
      };
    case SET_VERSION_HISTORY_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        versionHistoryTablePageSize: action.versionHistoryTablePageSize,
      };
    case SET_INDEX_SEARCH_FORM_DATA:
      return {
        ...state,
        indexSearchFormData: action.indexSearchFormData,
      };
    case SET_INDEX_SEARCH_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        indexSearchTablePageIndex: action.indexSearchTablePageIndex,
      };
    case SET_INDEX_SEARCH_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        indexSearchTablePageSize: action.indexSearchTablePageSize,
      };
    case SET_MY_WORK_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        myWorkTablePageIndex: action.myWorkTablePageIndex,
      };
    case SET_MY_WORK_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        myWorkTablePageSize: action.myWorkTablePageSize,
      };
    case SET_MY_WORK_SORT_CONFIG:
      return {
        ...state,
        myWorkSortConfig: action.myWorkSortConfig,
      };
    case SET_MY_WORK_TABLE_CONFIG_FILTERS:
      return {
        ...state,
        myWorkFilters: action.myWorkFilters,
      };
    case SET_LOGIN_MODAL_CONFIG:
      return {
        ...state,
        loginModalConfig: action.config,
      };
    case SET_LOGIN_MODAL_CONFIG_IS_OPEN:
      return {
        ...state,
        loginModalConfig: { ...state.loginModalConfig, isOpen: action.isOpen },
      };
    case SET_LOGIN_MODAL_CONFIG_ID:
      return {
        ...state,
        loginModalConfig: { ...state.loginModalConfig, id: action.id },
      };
    case SET_FEATURE_SEARCH_FORM_DATA:
      return {
        ...state,
        featureSearchFormData: action.featureSearchFormData,
      };
    case SET_FEATURE_SEARCH_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        featureSearchTablePageIndex: action.featureSearchTablePageIndex,
      };
    case SET_FEATURE_SEARCH_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        featureSearchTablePageSize: action.featureSearchTablePageSize,
      };
    case SET_FULL_TEXT_SEARCH_FORM_DATA:
      return {
        ...state,
        fullTextSearchFormData: action.fullTextSearchFormData,
      };
    case SET_FULL_TEXT_SEARCH_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        fullTextSearchTablePageIndex: action.fullTextSearchTablePageIndex,
      };
    case SET_FULL_TEXT_SEARCH_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        fullTextSearchTablePageSize: action.fullTextSearchTablePageSize,
      };
    case SET_RECENT_ARTICLES_TABLE_CONFIG_PAGE_INDEX:
      return {
        ...state,
        recentArticlesTablePageIndex: action.recentArticlesTablePageIndex,
      };
    case SET_RECENT_ARTICLES_TABLE_CONFIG_PAGE_SIZE:
      return {
        ...state,
        recentArticlesTablePageSize: action.recentArticlesTablePageSize,
      };
    default:
      return state;
  }
};

export default reducer;
