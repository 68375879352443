import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, Label, Form, Input, FormGroup, Alert } from 'reactstrap';
import { BsPlusCircle } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import useFormHandler from '../../hooks/useFormHandler';
import ArticleDistributionDetailTable from './ArticleDistributionDetailTable/ArticleDistributionDetailTable';
import ArticleSearchModal from '../../components/ArticleSearchModal/ArticleSearchModal';
import useArticleApi from '../../hooks/useArticleApi';
import { checkForEmptyData } from '../../utils/form-utils';
import refineArticleSearchResults from './ArticleDistributionDetailPage.utils';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import useArticleDistributionListApi from '../../hooks/useArticleDistributionListApi';

const ArticleDistributionDetailPage = () => {
  const { distributionId } = useParams();
  const { formData, formError, handleInputChange, handleResetForm, setFormError, setFormData } = useFormHandler({
    name: '',
    distributionId,
  });

  const { getArticles, articlesData, errorGettingArticles, loadingArticles, abortGettingArticles } = useArticleApi();
  const {
    deleteArticle,
    deleteData,
    errorDeletingArticle,
    deletingArticle,
    getArticleDistribution,
    articleDistributionData,
    addArticle,
    addData,
    errorAddingArticle,
    addingArticle,
  } = useArticleDistributionListApi();
  const [articleSearchRequestData, setArticleSearchRequestData] = useState();
  const [articleDataResults, setArticleDataResults] = useState(null);
  const [errorGettingArticlesMessage, setErrorGettingArticlesMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showArticleSearchModal, setShowArticleSearchModal] = useState(false);
  const [articleId, setShowArticleId] = useState();
  const [articleName, setArticleName] = useState();
  const [distributionName, setDistributionName] = useState('');
  const [addState, setAddState] = useState();
  const [deleteState, setDeleteState] = useState();
  const loading = loadingArticles || deletingArticle || addingArticle;

  const onConfirmDeleteButtonClick = () => {
    deleteArticle(distributionId, articleId);
    setDeleteState(true);
    setShowConfirmModal(false);
    setArticleName(formData.name);
  };

  const handleArticleRemovalFromDistributions = (value) => {
    setShowConfirmModal(true);
    setShowArticleId(value);
  };

  // add is successful, show the toast and do a search on article name
  useEffect(() => {
    if (addData && addState && !loading) {
      setArticleSearchRequestData({ distributionId, name: articleName });
      toast.success('Article added');
      setAddState(false);
    }
  }, [addData, addState, articleName, distributionId, loading]);

  // delete is successful, show the toast and reroute to homepage
  useEffect(() => {
    if (deleteData && deleteState && !loading) {
      setArticleSearchRequestData({ distributionId, name: articleName });
      toast.success('Article deleted');
      setDeleteState(false);
    }
  }, [articleName, deleteData, deleteState, distributionId, loading]);

  // Set the results to article data if it exists
  useEffect(() => {
    if (articlesData) {
      setArticleDataResults(refineArticleSearchResults(articlesData?.articles));

      setArticleSearchRequestData(null);
    }
  }, [articlesData]);

  // if there is an error while deleting the article, show the error toast
  useEffect(() => {
    if (errorDeletingArticle) {
      const errorStatus = errorDeletingArticle.status;

      if (errorStatus !== 401) {
        toast.error('Article cannot be deleted');
      }
    }
  }, [errorDeletingArticle]);

  useEffect(() => {
    if (articleDistributionData) {
      setDistributionName(articleDistributionData.name);
    }
  }, [articleDistributionData]);

  // Get distribution name on mount
  useEffect(() => {
    getArticleDistribution(distributionId);
  }, [distributionId, getArticleDistribution]);

  const handleSelectArticle = (article = {}) => {
    setShowArticleSearchModal(false);

    setFormData({
      ...formData,
      name: article.articleName || '',
    });

    setAddState(true);
    addArticle(distributionId, article.articleId || '');

    setArticleName(article.articleName);
  };

  // Every time articleSearchRequestData state is set make sure articleSearchRequestData
  // exists and is not empty and if so set appropriate state and get articles.
  // This will happen on mount (if there is data for the form in Redux) or on form submit.
  useEffect(() => {
    if (articleSearchRequestData && !checkForEmptyData(articleSearchRequestData)) {
      getArticles(articleSearchRequestData);
      setArticleSearchRequestData(null);
    }
  }, [articleSearchRequestData, getArticles]);

  // If there is an error during the fetch (400 or 404) remove the current articles
  // data from state and set the error messaging in state.
  useEffect(() => {
    if (errorGettingArticles) {
      const errorStatus = errorGettingArticles?.status;

      setArticleSearchRequestData(null);

      if (errorStatus === 404 || errorStatus === 400) {
        setErrorGettingArticlesMessage('No articles were found.');
      } else if (errorGettingArticles.message === 'The user aborted a request.') {
        setErrorGettingArticlesMessage('');
      } else {
        setErrorGettingArticlesMessage('There was an issue loading the articles.');
      }
    }
  }, [errorGettingArticles]);

  // to handle the different error statuses and respond accordingly, 403 indicates a duplicate
  useEffect(() => {
    if (errorAddingArticle) {
      const errorStatus = errorAddingArticle?.status;

      if (errorStatus === 400) {
        toast.error('Article cannot be added');
      }

      if (errorStatus === 403) {
        toast.error('Article already is a part of the distribution');
      }
    }
  }, [errorAddingArticle]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.name === '') {
      setFormError('The form is empty.');
    } else {
      setFormError('');

      setErrorGettingArticlesMessage('');

      setArticleDataResults(null);

      setArticleSearchRequestData(formData);
    }
  };

  const onFormReset = (e) => {
    e.preventDefault();

    setArticleDataResults(null);
    setArticleSearchRequestData(null);
    setErrorGettingArticlesMessage('');
    handleResetForm();
  };

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Distribution Detail {distributionId}</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="mb-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Distribution Detail: {distributionName}</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-center align-items-center">
              <Form onSubmit={handleSubmit} data-testid="articleSearchForm" onReset={onFormReset}>
                <FormGroup>
                  <Label for="name" className="me-3">
                    Name:
                  </Label>
                  <Input
                    className="me-3"
                    id="name"
                    name="name"
                    onChange={handleInputChange}
                    placeholder="Article Name..."
                    value={formData.name}
                  />
                </FormGroup>

                <Button type="submit" color="primary" className="me-2" disabled={loadingArticles}>
                  {loadingArticles ? 'Searching...' : 'Search'}
                </Button>
                {loadingArticles && (
                  <Button type="button" color="link" onClick={abortGettingArticles}>
                    Cancel
                  </Button>
                )}
              </Form>
            </div>

            <div className="d-flex justify-content-center align-items-center mb-3">
              <Button
                className="d-flex align-items-center"
                color="link"
                onClick={() => setShowArticleSearchModal(true)}
              >
                <BsPlusCircle className="me-1" />
                Add article
              </Button>
            </div>

            <div className="d-flex justify-content-center align-items-center mt-3">
              <Alert
                color="danger"
                className={classnames(alert, 'mb-0')}
                isOpen={!!formError}
                toggle={() => setFormError('')}
                fade={false}
              >
                {formError}
              </Alert>
            </div>

            {!errorGettingArticlesMessage && !loading && !articleDataResults && (
              <p>Use the form to search for articles...</p>
            )}
            {loading && <SpinnerComponent size={spinnerSize1} setFullVh={false} />}
            {articleDataResults && !loading && (
              <ArticleDistributionDetailTable
                data={articleDataResults}
                handleArticleRemovalFromDistributions={handleArticleRemovalFromDistributions}
              />
            )}
            {errorGettingArticlesMessage && !loading && errorGettingArticlesMessage}
            {showArticleSearchModal && (
              <ArticleSearchModal
                toggle={() => setShowArticleSearchModal(false)}
                handleSelectArticle={handleSelectArticle}
              />
            )}
            {showConfirmModal && (
              <ConfirmModal
                toggle={() => setShowConfirmModal(false)}
                header="Confirm Delete"
                confirmButton="Delete"
                message="Are you sure you want to remove the article from the distribution?"
                showButtons
                onConfirmButtonClick={onConfirmDeleteButtonClick}
              />
            )}
          </Col>
        </Row>
      </Container>
    </DefaultTemplate>
  );
};

export default ArticleDistributionDetailPage;
