import React, { lazy, Suspense } from 'react';

const LazyArticleDetailPage = lazy(() => import('./ArticleDetailPage'));

const ArticleDetailPage = (props) => (
  <Suspense fallback={null}>
    <LazyArticleDetailPage {...props} />
  </Suspense>
);

export default ArticleDetailPage;
