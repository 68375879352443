import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { BsPlusCircle } from 'react-icons/bs';

import useGroupApi from '../../hooks/useGroupApi';
import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import BackLink from '../../components/BackLink/BackLink';
import { Routes } from '../../App.constants';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import GroupDetailMetadata from './GroupDetailMetadata/GroupDetailMetadata';
import GroupDetailTable from './GroupDetailTable/GroupDetailTable';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import UserSelectModal from '../../components/UserSelectModal/UserSelectModal';
import buildUsersTableRowsData from '../UsersPage/UsersPage.utils';

const GroupDetailPage = () => {
  const { groupId } = useParams();
  const {
    getGroup,
    groupData,
    errorGettingGroup,
    loadingGroup,
    editGroup,
    editData,
    errorEditingGroup,
    editingGroup,
    deleteUserFromGroup,
    deleteUserData,
    errorDeletingUserGroup,
    deletingUserGroup,
    addUserToGroup,
    addUserData,
    errorAddingUserGroup,
    addingUserGroup,
  } = useGroupApi();
  const [tableRowsData, setTableRowsData] = useState([]);
  const [groupMetadata, setGroupMetadata] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUserSelectModal, setShowUserSelectModal] = useState(false);
  const [userId, setUserId] = useState();
  const backLinkState = useLocation().state?.backLink;
  const loading = editingGroup || deletingUserGroup || loadingGroup || addingUserGroup;

  const handleDeleteUsers = (value) => {
    setShowConfirmModal(true);
    setUserId(value);
  };

  const onConfirmDeleteButtonClick = () => {
    deleteUserFromGroup(groupId, userId);
    setShowConfirmModal(false);
    setUserId(null);
  };

  const handleSelectUser = (value) => {
    addUserToGroup(groupId, value.userId);
  };

  // If the users data is retrieved successfully set the toast, close the modal and reload page
  useEffect(() => {
    if (addUserData) {
      toast.success('User added to group');
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [addUserData]);

  // If there is an error during the fetch (400 or 404) set the errorm message and remove the
  // current subjects
  useEffect(() => {
    if (errorAddingUserGroup) {
      const errorStatus = errorAddingUserGroup?.status;

      if (errorStatus === 404) {
        toast.error('Cannot add user');
      } else if (errorStatus === 400) {
        toast.error('Invalid input');
      } else if (errorStatus === 403) {
        toast.error('User with that name already exists');
      }
    }
  }, [errorAddingUserGroup]);

  // delete is successful, show the toast and reroute to homepage
  useEffect(() => {
    if (deleteUserData) {
      toast.success('User deleted');
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [deleteUserData]);

  // if there is an error while deleting the article, show the error toast
  useEffect(() => {
    if (errorDeletingUserGroup) {
      const errorStatus = errorDeletingUserGroup.status;

      if (errorStatus === 400) {
        toast.error('Invalid input');
      }

      if (errorStatus === 404) {
        toast.error('Unable to find user');
      } else toast.error('Cannot be deleted');
    }
  }, [errorDeletingUserGroup]);

  // Get group data on mount
  useEffect(() => {
    getGroup(groupId);
  }, [groupId, getGroup]);

  useEffect(() => {
    if (groupData) {
      setGroupMetadata(groupData);
      setTableRowsData(buildUsersTableRowsData(groupData.users));
    }
  }, [groupData]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Group Detail {groupId}</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <BackLink to={backLinkState?.to || Routes.GROUPS.toLink()} linkText={backLinkState?.text || 'Groups'} />
            <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Group Detail</h1>
              {/* <HelpIcon link="group-details" id="groupDetail" /> */}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={5} className="my-4">
            {groupMetadata && (
              <GroupDetailMetadata
                groupMetadata={groupMetadata}
                setGroupMetadata={setGroupMetadata}
                editGroupData={editData}
                groupId={groupId}
                onUpdate={editGroup}
                error={errorEditingGroup}
                loading={editingGroup}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <Button className="d-flex align-items-center" color="link" onClick={() => setShowUserSelectModal(true)}>
                <BsPlusCircle className="me-1" />
                Add user
              </Button>
            </div>
            {errorGettingGroup && <p>There was an issue loading the group data.</p>}
            {(loading || !groupData) && (
              <div className="mt-3">
                <SpinnerComponent size={spinnerSize1} setFullVh={false} />
              </div>
            )}
            {tableRowsData.length === 0 && !loading && <p>There are no users for the group.</p>}
            {tableRowsData.length !== 0 && !loading && (
              <GroupDetailTable rowsData={tableRowsData} handleDeleteUsers={handleDeleteUsers} />
            )}
            {showConfirmModal && (
              <ConfirmModal
                toggle={() => setShowConfirmModal(false)}
                header="Confirm Delete"
                confirmButton="Delete"
                message="Are you sure you want to delete this user from this group?"
                showButtons
                onConfirmButtonClick={onConfirmDeleteButtonClick}
              />
            )}
            {showUserSelectModal && (
              <UserSelectModal toggle={() => setShowUserSelectModal(false)} handleSelectUser={handleSelectUser} />
            )}
          </Col>
        </Row>
      </Container>
    </DefaultTemplate>
  );
};

export default GroupDetailPage;
