import formatPostDate from '../../utils/date-utils';
import { buildContributorsList } from '../ArticleDetailPage/ArticleDetailTabs/ArticleDetailVersions/ArticleDetailVersionsTable/ArticleDetailVersionsTable.utils';

const refineRecentArticles = (results) =>
  results.map((result) => {
    const { articleId, name, titleXml, articleTypeName, postDate, history } = result;

    const postDateValue = formatPostDate(postDate);
    const articleVersionHistoryType = history?.type || '';
    const articleVersionHistoryDescXml = history?.descriptionXml || '';
    const contributors = buildContributorsList(history?.contributors);

    return {
      articleId,
      name,
      titleXml,
      articleTypeName,
      postDateValue,
      postDate,
      articleVersionHistoryType,
      articleVersionHistoryDescXml,
      contributors,
    };
  });

export default refineRecentArticles;
