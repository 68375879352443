import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { MdDelete } from 'react-icons/md';

import { Routes } from '../../../App.constants';
import TextColumnFilter from '../../../components/TextColumnFilter/TextColumnFilter';

const groupDetailTableColumns = (handleDeleteUser) => [
  {
    Header: 'User ID',
    accessor: 'userId',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { value } = e;

      return (
        <Link to={Routes.USER_DETAIL.toLink({ userId: value, isUser: 'null' })} className="p-0">
          {value}
        </Link>
      );
    },
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Username',
    accessor: 'username',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Email',
    accessor: 'email',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Active',
    accessor: 'active',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Trash',
    accessor: 'userId',
    id: 'delete-user',
    disableFilters: true,
    disableSortBy: true,
    Cell: (e) => {
      const { row, value } = e;
      const { id } = row;

      return (
        <Button
          className="p-0"
          type="button"
          color="link"
          aria-label="Trash"
          data-testid={`trash-${id}`}
          onClick={() => handleDeleteUser(value)}
        >
          <MdDelete size={18} />
        </Button>
      );
    },
  },
];

export default groupDetailTableColumns;
