import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import useP2PInstitutionalApi from '../../../../hooks/useP2PInstitutionalApi';
import SpinnerComponent from '../../../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../../../components/SpinnerComponent/SpinnerComponent.constants';
import buildInstitutionalFeaturesData from './InstitutionalStatusFeatures.utils';
import InstitutionalStatusFeaturesTable from './InstitutionalStatusFeaturesTable/InstitutionalStatusFeaturesTable';

const InstitutionalStatusFeatures = ({ formData }) => {
  const {
    getInstitutionalFeatures,
    institutionalFeaturesData,
    errorGettingInstitutionalFeatures,
    gettingInstitutionalFeatures,
  } = useP2PInstitutionalApi();
  const noInstitutionalFeaturesFoundMessage = <p className="mt-3">There are no features.</p>;
  const tableRowsData = institutionalFeaturesData
    ? buildInstitutionalFeaturesData(institutionalFeaturesData?.features)
    : null;

  useEffect(() => {
    const result = new Date(formData.date);

    result.setDate(result.getDate() + 1);

    getInstitutionalFeatures({
      startedAfter: `${formData.date?.getFullYear()}-${formData.date?.getMonth() + 1}-${formData.date?.getDate()}`,
      startedBefore: `${formData.date?.getFullYear()}-${result.getMonth() + 1}-${result.getDate()}`,
      pending: formData.pending,
    });
  }, [formData.date, formData.onlyWips, formData.pending, getInstitutionalFeatures]);

  return (
    <>
      <Helmet>
        <title>AM: Institutional Status: Features</title>
      </Helmet>

      {errorGettingInstitutionalFeatures?.status === 404 && noInstitutionalFeaturesFoundMessage}
      {errorGettingInstitutionalFeatures?.status !== 404 && errorGettingInstitutionalFeatures && (
        <p className="mt-3">There was an issue loading the institutional features.</p>
      )}
      {(gettingInstitutionalFeatures || !institutionalFeaturesData) && !errorGettingInstitutionalFeatures && (
        <div className="mt-3">
          <SpinnerComponent size={spinnerSize1} setFullVh={false} />
        </div>
      )}
      {tableRowsData && !gettingInstitutionalFeatures && !errorGettingInstitutionalFeatures && (
        <InstitutionalStatusFeaturesTable data={tableRowsData} />
      )}
    </>
  );
};

InstitutionalStatusFeatures.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default InstitutionalStatusFeatures;
