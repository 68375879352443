import { useCallback } from 'react';
import { stringify } from 'query-string';

import { API_MAX_PER_PAGE, ELSIE_API } from '../App.constants';
import useFetchWithState from './useFetchWithState';
import useAuthApi from './useAuthApi';

const useP2PInternationalApi = () => {
  const { getAuthCookies } = useAuthApi();
  const { encodedTicketFromCookie } = getAuthCookies();
  const {
    amFetchWithState: getInternationalArticlesData,
    data: internationalArticlesData,
    error: errorGettingInternationalArticles,
    loading: gettingInternationalArticles,
  } = useFetchWithState();
  const {
    amFetchWithState: p2pInternationalArticleData,
    data: p2pIntArticleData,
    error: errorP2PingIntArticleGroup,
    loading: p2pingIntArticleGroup,
  } = useFetchWithState();

  const getInternationalArticles = useCallback(
    (internationalArticlesSearchFormData) => {
      getInternationalArticlesData(
        `${ELSIE_API}/p2p/international/articles?${stringify(internationalArticlesSearchFormData, {
          arrayFormat: 'comma',
        })}&perPage=${API_MAX_PER_PAGE}`
      );
    },
    [getInternationalArticlesData]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const p2pInternationalArticle = useCallback(
    (requestBody) => {
      p2pInternationalArticleData(`${ELSIE_API}/p2p/international/batches/0/articles`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [p2pInternationalArticleData, encodedTicketFromCookie]
  );

  return {
    getInternationalArticles,
    internationalArticlesData,
    errorGettingInternationalArticles,
    gettingInternationalArticles,
    p2pInternationalArticle,
    p2pIntArticleData,
    errorP2PingIntArticleGroup,
    p2pingIntArticleGroup,
  };
};

export default useP2PInternationalApi;
