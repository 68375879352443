import React from 'react';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { Helmet } from 'react-helmet';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import ConsumerStatusTabs from './ConsumerStatusTabs/ConsumerStatusTabs';
import { CONSUMER_STATUS_TOPICS_FORM_INITIAL_STATE } from './ConsumerStatusTabs/ConsumerStatusTabs.constants';
import useFormHandler from '../../hooks/useFormHandler';
import styles from './ConsumerStatusTabs/ConsumerStatusTabs.module.scss';

const ConsumerStatusPage = () => {
  const { datepicker } = styles;
  const { formData, setFormData, handleInputChange } = useFormHandler(CONSUMER_STATUS_TOPICS_FORM_INITIAL_STATE);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Consumer Status</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Consumer P2P Status</h1>

              <div className="d-flex align-items-center justify-content-center">
                <Label className="me-2 mb-3" for="date">
                  Date:
                </Label>
                <FormGroup>
                  <DatePicker
                    showIcon
                    name="date"
                    id="date"
                    selected={formData.date}
                    className={classnames(datepicker, 'me-3 p-1')}
                    onChange={(update) => {
                      setFormData({
                        ...formData,
                        date: update,
                      });
                    }}
                    maxDate={new Date()}
                  />
                </FormGroup>

                <FormGroup className="me-2">
                  <FormGroup switch>
                    <Input
                      type="switch"
                      id="pending"
                      name="pending"
                      checked={formData.pending}
                      onChange={handleInputChange}
                      disabled={formData.date.toISOString().slice(0, 10) !== new Date().toISOString().slice(0, 10)}
                    />
                    <Label check for="showPendingSwitch">
                      Show Pending:
                    </Label>
                  </FormGroup>
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ConsumerStatusTabs formData={formData} handleInputChange={handleInputChange} />
          </Col>
        </Row>
      </Container>
    </DefaultTemplate>
  );
};

export default ConsumerStatusPage;
