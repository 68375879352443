import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useFeatureApi from '../../../../hooks/useFeatureApi';
import SpinnerComponent from '../../../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../../../components/SpinnerComponent/SpinnerComponent.constants';
import buildFeatureVersionsTableRowsData from './FeatureDetailVersions.utils';
import FeatureDetailVersionsTable from './FeatureDetailVersionsTable/FeatureDetailVersionsTable';

const FeatureDetailVersions = ({ featureId }) => {
  const { getFeatureVersions, featureVersionsData, errorGettingFeatureVersions, loadingFeatureVersions } =
    useFeatureApi();
  const [tableRowsData, setTableRowsData] = useState([]);
  const noVersionsFoundMessage = <p className="mt-3">There are no versions for this feature.</p>;

  // On mount get the feature's versions.
  useEffect(() => {
    getFeatureVersions(featureId);
  }, [featureId, getFeatureVersions]);

  // Once the feature versions data is returned build the versions table rows
  // data and set the applicable state.
  useEffect(() => {
    if (featureVersionsData) {
      setTableRowsData(buildFeatureVersionsTableRowsData(featureVersionsData.versions));
    }
  }, [featureVersionsData]);

  if (errorGettingFeatureVersions?.status === 404) {
    return noVersionsFoundMessage;
  }

  if (errorGettingFeatureVersions) {
    return <p className="mt-3">There was an issue loading the feature versions.</p>;
  }

  if (loadingFeatureVersions || !featureVersionsData) {
    return (
      <div className="mt-3">
        <SpinnerComponent size={spinnerSize1} setFullVh={false} />
      </div>
    );
  }

  if (tableRowsData.length === 0) {
    return noVersionsFoundMessage;
  }

  return <FeatureDetailVersionsTable rowsData={tableRowsData} featureId={featureId} />;
};

FeatureDetailVersions.propTypes = {
  featureId: PropTypes.string.isRequired,
};

export default FeatureDetailVersions;
