import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { MdModeEdit } from 'react-icons/md';

import useAuthApi from '../../hooks/useAuthApi';

const EditDeleteButtons = ({ edit, handleEdit, handleDelete, handleCancel, handleSave, hideDelete }) => {
  const { userDataFromStore } = useAuthApi();
  const { isAdmin } = userDataFromStore;

  return (
    <div className="d-flex justify-content-end my-3">
      {!edit && (
        <>
          <Button type="button" color="link" onClick={handleEdit}>
            <MdModeEdit className="mb-1" /> Edit
          </Button>
          {isAdmin && !hideDelete && (
            <Button type="button" color="danger" onClick={handleDelete} className="text-white">
              Delete
            </Button>
          )}
        </>
      )}
      {edit && (
        <>
          <Button type="reset" color="link" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="reset" color="success" onClick={handleSave} className="px-3 text-white">
            Save
          </Button>
        </>
      )}
    </div>
  );
};

EditDeleteButtons.defaultProps = {
  hideDelete: false,
};

EditDeleteButtons.propTypes = {
  edit: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  hideDelete: PropTypes.bool,
};

export default EditDeleteButtons;
