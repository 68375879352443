import { useCallback } from 'react';

import { BESSIE_API, API_MAX_PER_PAGE } from '../App.constants';
import useFetchWithState from './useFetchWithState';
import useAuthApi from './useAuthApi';

const usePlanningApi = () => {
  const { getAuthCookies } = useAuthApi();
  const { encodedTicketFromCookie } = getAuthCookies();

  const {
    amFetchWithState: getUsersData,
    data: usersData,
    error: errorGettingUsers,
    loading: gettingUsers,
  } = useFetchWithState();
  const {
    amFetchWithState: deleteUserData,
    data: deleteData,
    error: errorDeletingUser,
    loading: deletingUser,
  } = useFetchWithState();
  const {
    amFetchWithState: addUserData,
    data: addData,
    error: errorAddingUser,
    loading: addingUser,
  } = useFetchWithState();
  const {
    amFetchWithState: getUserData,
    data: userData,
    error: errorGettingUser,
    loading: loadingUser,
  } = useFetchWithState();
  const {
    amFetchWithState: editUserData,
    data: editData,
    error: errorEditingUser,
    loading: editingUser,
  } = useFetchWithState();
  const {
    amFetchWithState: deleteGroupFromUserData,
    data: deleteGroupData,
    error: errorDeletingGroupUser,
    loading: deletingGroupUser,
  } = useFetchWithState();
  const {
    amFetchWithState: addGroupToUserData,
    data: addGroupData,
    error: errorAddingGroupUser,
    loading: addingGroupUser,
  } = useFetchWithState();

  const getUsers = useCallback(
    (inactive) => {
      getUsersData(`${BESSIE_API}/users?perPage=${API_MAX_PER_PAGE}&inactive=${inactive}`);
    },
    [getUsersData]
  );

  /**
   * @param  {string} userId - user ID which has to be deleted
   */
  const deleteUser = useCallback(
    (userId) => {
      deleteUserData(`${BESSIE_API}/users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [deleteUserData, encodedTicketFromCookie]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const addUser = useCallback(
    (requestBody) => {
      addUserData(`${BESSIE_API}/users`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [addUserData, encodedTicketFromCookie]
  );

  /**
   * @param  {string} userId - user ID
   */
  const getUser = useCallback(
    (userId) => {
      getUserData(`${BESSIE_API}/users/${userId}`);
    },
    [getUserData]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   * @param  {string} userId - user ID
   */
  const editUser = useCallback(
    (requestBody, userId) => {
      editUserData(`${BESSIE_API}/users/${userId}`, {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [editUserData, encodedTicketFromCookie]
  );

  /**
   * @param  {string} userId - user ID which has to be deleted
   * @param  {string} groupId - user ID to which it belongs
   */
  const deleteGroupFromUser = useCallback(
    (groupId, userId) => {
      deleteGroupFromUserData(`${BESSIE_API}/users/${userId}/groups/${groupId}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [deleteGroupFromUserData, encodedTicketFromCookie]
  );

  /**
   * @param  {string} userId - user ID which has to be added
   * @param  {string} groupId - user ID to which it belongs
   */
  const addGroupToUser = useCallback(
    (groupId, userId) => {
      addGroupToUserData(`${BESSIE_API}/users/${userId}/groups/${groupId}`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [addGroupToUserData, encodedTicketFromCookie]
  );

  return {
    getUsers,
    usersData,
    errorGettingUsers,
    gettingUsers,
    deleteUser,
    deleteData,
    errorDeletingUser,
    deletingUser,
    addUser,
    addData,
    errorAddingUser,
    addingUser,
    getUser,
    userData,
    errorGettingUser,
    loadingUser,
    editUser,
    editData,
    errorEditingUser,
    editingUser,
    deleteGroupFromUser,
    deleteGroupData,
    errorDeletingGroupUser,
    deletingGroupUser,
    addGroupToUser,
    addGroupData,
    errorAddingGroupUser,
    addingGroupUser,
  };
};

export default usePlanningApi;
