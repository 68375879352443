import React from 'react';
import { Label, Form, FormGroup, Button, Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from 'react-select';

import { selectTheme, defineSelectFieldStyles } from '../../../utils/select-field-utils';
import useFormHandler from '../../../hooks/useFormHandler';
import styles from './AllWorkForm.module.scss';
import { ALL_WORK_FORM_INITIAL_STATE, AllWorkWIPSelectCustomStyles } from './AllWorkForm.constants';

const AllWorkForm = ({ loading, onSearch, onReset, abort, workflowOptions }) => {
  const { alert } = styles;
  const { formData, formError, handleResetForm, setFormError, isFormEmpty, handleSelectChange } =
    useFormHandler(ALL_WORK_FORM_INITIAL_STATE);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormEmpty()) {
      setFormError('');

      onSearch({ ...formData, processDefinitionKey: formData.processDefinitionKey.value });
    }
  };

  const onFormReset = (e) => {
    e.preventDefault();

    onReset();
    handleResetForm();
  };

  return (
    <Form onSubmit={handleSubmit} data-testid="articleCompletedWorkSearchForm" onReset={onFormReset}>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <FormGroup className="me-3">
          <Label for="processDefinitionKey">Workflow:</Label>
          <Select
            name="processDefinitionKey"
            value={formData.processDefinitionKey}
            onChange={handleSelectChange}
            options={workflowOptions.sort((a, b) => a.label.localeCompare(b.label))}
            placeholder="Select Workflow..."
            theme={selectTheme}
            styles={defineSelectFieldStyles(AllWorkWIPSelectCustomStyles)}
          />
        </FormGroup>
        <div className="d-flex justify-content-center align-items-center mt-2">
          <Button type="submit" color="primary" className="me-2" disabled={loading}>
            {loading ? 'Searching...' : 'Search'}
          </Button>
          {loading && (
            <Button type="button" color="link" onClick={abort}>
              Cancel
            </Button>
          )}
          {!loading && (
            <Button type="reset" color="link">
              Reset
            </Button>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center mt-3">
          <Alert
            color="danger"
            className={classnames(alert, 'mb-0')}
            isOpen={!!formError}
            toggle={() => setFormError('')}
            fade={false}
          >
            {formError}
          </Alert>
        </div>
      </div>
    </Form>
  );
};

AllWorkForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  abort: PropTypes.func.isRequired,
  workflowOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};

export default AllWorkForm;
