import { useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';

import useFetchWithState from './useFetchWithState';
import useFetch from './useFetch';
import {
  BESSIE_API,
  EDITORIAL_API,
  COOKIE_ATTRIBUTES,
  COOKIE_TICKET_NAME,
  COOKIE_USER_NAME,
  COOKIE_TICKET_ENCODED_NAME,
} from '../App.constants';
import { setUser, setMyWorkSortConfig } from '../store/action-creators';
import useUserLoginModal from './useUserLoginModal';
import { ADMIN_PERMISSION_GROUPS, EDITOR_PERMISSION_GROUPS } from './constants/useAuthApi.constants';

const useAuthApi = () => {
  const { amFetchWithState: signIn, data: signInData, error: errorSigningIn, loading: signingIn } = useFetchWithState();
  const {
    amFetchWithState: signOut,
    data: signOutData,
    error: errorSigningOut,
    loading: signingOut,
  } = useFetchWithState();
  const {
    amFetchWithState: getUserData,
    data: userData,
    error: errorGettingUser,
    loading: gettingUser,
  } = useFetchWithState();
  const { amFetch: validateTicketRequest } = useFetch();
  const dispatch = useDispatch();
  const userDataFromStore = useSelector((state) => state.user);
  const { closeModal } = useUserLoginModal();

  /**
   * @param  {JSON Object} requestBody - stringified data from user login form
   */
  const signInUser = (requestBody) => {
    signIn(`${EDITORIAL_API}/login/user`, {
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'POST',
      body: requestBody,
    });
  };

  /**
   * @param  {string} username - signed in user's username
   */
  const getUser = useCallback(
    (username) => {
      getUserData(`${BESSIE_API}/user/${username}`);
    },
    [getUserData]
  );

  // On a successful authentication store the Alfresco auth ticket and
  // other auth data in browser cookies and get the user data.
  useEffect(() => {
    if (signInData) {
      const { ticket, user, authorization } = signInData;

      Cookies.set(COOKIE_TICKET_NAME, ticket, COOKIE_ATTRIBUTES);
      Cookies.set(COOKIE_TICKET_ENCODED_NAME, authorization, COOKIE_ATTRIBUTES);
      Cookies.set(COOKIE_USER_NAME, user, COOKIE_ATTRIBUTES);

      getUser(user);
    }
  }, [signInData, getUser]);

  const signOutUser = () => {
    const { ticketFromCookie: ticket } = getAuthCookies();
    const requestBody = JSON.stringify({ ticket });

    signOut(`${EDITORIAL_API}/logout/ticket`, {
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'POST',
      body: requestBody,
    });
  };

  // Clear out auth cookies as well as user data from Redux store.
  const clearAuthCookiesAndUserReduxStoreData = useCallback(() => {
    Cookies.remove(COOKIE_TICKET_ENCODED_NAME, COOKIE_ATTRIBUTES);
    Cookies.remove(COOKIE_USER_NAME, COOKIE_ATTRIBUTES);
    Cookies.remove(COOKIE_TICKET_NAME, COOKIE_ATTRIBUTES);

    dispatch(setUser(null));
    dispatch(setMyWorkSortConfig([{ id: 'started', desc: false }]));
  }, [dispatch]);

  const getAuthCookies = useCallback(
    () => ({
      encodedTicketFromCookie: Cookies.get(COOKIE_TICKET_ENCODED_NAME, COOKIE_ATTRIBUTES),
      usernameFromCookie: Cookies.get(COOKIE_USER_NAME, COOKIE_ATTRIBUTES),
      ticketFromCookie: Cookies.get(COOKIE_TICKET_NAME, COOKIE_ATTRIBUTES),
    }),
    []
  );

  // If the user is successfully signed out remove the cookies
  // from the browser and clear the user data from Redux.
  // In order to be safe in the case of an error during sign out
  // we will perform the same steps.
  useEffect(() => {
    if (signOutData || errorSigningOut) {
      clearAuthCookiesAndUserReduxStoreData();
    }
  }, [signOutData, errorSigningOut, clearAuthCookiesAndUserReduxStoreData]);

  // Once the user data has been retrieved store
  // persist it in Redux.
  useEffect(() => {
    if (userData) {
      const { username, displayName, userId, firstName, lastName, groups, email } = userData;
      // Determine user permissions.
      const isAdmin = !!groups.find((group) => ADMIN_PERMISSION_GROUPS.includes(group.groupId));
      const isEditor = !!groups.find((group) => EDITOR_PERMISSION_GROUPS.includes(group.groupId));

      dispatch(setUser({ username, displayName, userId, firstName, lastName, groups, email, isAdmin, isEditor }));

      // Once signed in completely close the login modal.
      closeModal();
    }
  }, [userData, dispatch, closeModal]);

  // If there is an error getting the user
  // clear auth cookies and user data from Redux store.
  useEffect(() => {
    if (errorGettingUser) {
      clearAuthCookiesAndUserReduxStoreData();
    }
  }, [errorGettingUser, clearAuthCookiesAndUserReduxStoreData]);

  // Perform a check to make sure the user is authenticated.
  const checkIfUserIsAuthenticated = useCallback(async () => {
    /**
     * @param  {string} ticket - Alfresco auth ticket from cookie
     */
    const validateTicket = async (ticket) => {
      const requestBody = JSON.stringify({ ticket });

      return validateTicketRequest(`${EDITORIAL_API}/login/ticket`, {
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        method: 'POST',
        body: requestBody,
      });
    };

    const { encodedTicketFromCookie, usernameFromCookie, ticketFromCookie } = getAuthCookies();

    if (encodedTicketFromCookie && usernameFromCookie && ticketFromCookie) {
      try {
        const validateTicketResponse = await validateTicket(ticketFromCookie);
        const ticketFromValidateTicketResponse = validateTicketResponse.ticket;

        if (!ticketFromValidateTicketResponse) {
          throw validateTicketResponse;
        }

        if (!userDataFromStore && !gettingUser) {
          getUser(usernameFromCookie);
        }
      } catch {
        clearAuthCookiesAndUserReduxStoreData();
      }
    } else {
      clearAuthCookiesAndUserReduxStoreData();
    }
  }, [
    clearAuthCookiesAndUserReduxStoreData,
    getUser,
    userDataFromStore,
    validateTicketRequest,
    gettingUser,
    getAuthCookies,
  ]);

  return {
    signInUser,
    errorSigningIn,
    signingIn,
    signOutUser,
    signingOut,
    errorGettingUser,
    gettingUser,
    userDataFromStore,
    checkIfUserIsAuthenticated,
    getAuthCookies,
  };
};

export default useAuthApi;
