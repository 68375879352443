import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { GrFormView } from 'react-icons/gr';

import useWorkflowApi from '../../hooks/useWorkflowApi';
import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import BackLink from '../../components/BackLink/BackLink';
import { Routes } from '../../App.constants';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import WIPDetailMetadata from './WIPDetailMetadata/WIPDetailMetadata';
import WIPDetailTable from './WIPDetailTable/WIPDetailTable';
import { buildWorkflowTableRowsData } from './WIPDetailPage.utils';

const WIPDetailPage = () => {
  const { articleId, processId } = useParams();
  const {
    getWorkflows,
    workflowsData,
    errorGettingWorkflows,
    loadingWorkflows,
    getWorkflow,
    workflowData,
    errorGettingWorkflow,
    loadingWorkflow,
    getViewLink,
    viewLinkData,
  } = useWorkflowApi();
  const [tableRowsData, setTableRowsData] = useState([]);
  const [viewLink, setViewLink] = useState('');
  const backLinkState = useLocation().state?.backLink;

  // Get workflow data on mount
  useEffect(() => {
    if (processId === 'null') {
      getWorkflows({ articleId });
    } else {
      getWorkflow(processId);
    }
  }, [articleId, getWorkflow, getWorkflows, processId]);

  useEffect(() => {
    if (workflowsData) {
      getWorkflow(workflowsData?.processes[0]?.entry?.id);
    }
  }, [getWorkflow, workflowsData]);

  useEffect(() => {
    if (workflowData) {
      setTableRowsData(buildWorkflowTableRowsData(workflowData?.process.tasks));
      getViewLink(workflowData?.process.id);
    }
  }, [getViewLink, workflowData]);

  useEffect(() => {
    if (viewLinkData) {
      setViewLink(viewLinkData?.oxygenUrl);
    }
  }, [getViewLink, viewLinkData, workflowData]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: WIP Detail 1234</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <BackLink
              to={backLinkState?.to || Routes.ARTICLE_SEARCH.toLink()}
              linkText={backLinkState?.text || 'Article Search'}
            />
            <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Work in Progress Details</h1>
              <div className="d-flex align-items-center">
                {viewLink && (
                  <a id="view" href={viewLink} target="_blank" rel="noreferrer noopener">
                    <GrFormView size={32} /> View
                  </a>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {(loadingWorkflows || loadingWorkflow) && (
              <div className="mt-3">
                <SpinnerComponent size={spinnerSize1} setFullVh={false} />
              </div>
            )}
            {workflowData && !loadingWorkflow && <WIPDetailMetadata data={workflowData?.process} />}
            {(errorGettingWorkflows || errorGettingWorkflow) && <p>There was an issue loading the workflow data.</p>}
            {tableRowsData.length !== 0 && !loadingWorkflow && <WIPDetailTable data={tableRowsData} />}
          </Col>
        </Row>
      </Container>
    </DefaultTemplate>
  );
};

export default WIPDetailPage;
