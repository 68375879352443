import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../../../components/DefaultTable/DefaultTable.module.scss';
import { setMyWorkTableConfigFilters, setMyWorkSortConfig } from '../../../store/action-creators';

const CustomMyWorkTable = ({ getTableProps, getTableBodyProps, headerGroups, page, prepareRow, sortBy, filters }) => {
  const dispatch = useDispatch();
  const sortState = useSelector((state) => state.myWorkSortConfig);

  useEffect(() => {
    if (sortBy) {
      dispatch(setMyWorkSortConfig(sortBy));
    }
  }, [dispatch, sortBy]);

  useEffect(() => {
    if (filters) {
      dispatch(setMyWorkTableConfigFilters(filters));
    }
  }, [dispatch, filters]);

  return (
    <Table responsive bordered {...getTableProps()} className="table-hover">
      <thead className={classnames('thead-light', styles.header)}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              let desc = null;

              let asc = null;

              sortState.forEach((sortSt) => {
                if (sortSt?.id === column.id && sortSt?.desc === true) {
                  desc = true;
                }

                if (sortSt?.id === column.id && sortSt?.desc === false) {
                  asc = true;
                }
              });

              return (
                <th
                  {...column.getHeaderProps()}
                  className={classnames(
                    {
                      [styles.descSortTableHeaderBorder]: desc,
                      [styles.ascSortTableHeaderBorder]: asc,
                    },
                    'align-top'
                  )}
                >
                  <div>
                    <span {...column.getSortByToggleProps()}>{column.render('Header')} </span>
                  </div>
                  {column.canFilter && <div>{column.render('Filter')}</div>}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

CustomMyWorkTable.propTypes = {
  getTableProps: PropTypes.func.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      getFooterGroupProps: PropTypes.func,
      getHeaderGroupProps: PropTypes.func,
      headers: PropTypes.arrayOf(PropTypes.shape()),
    })
  ).isRequired,
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  prepareRow: PropTypes.func.isRequired,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    })
  ),
  filters: PropTypes.arrayOf(PropTypes.shape()),
};

CustomMyWorkTable.defaultProps = {
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: null,
      desc: null,
    })
  ),
  filters: PropTypes.arrayOf(PropTypes.shape({ id: null, value: null })),
};

export default CustomMyWorkTable;
