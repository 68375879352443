import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import GroupDetailMetadataTable from './GroupDetailMetadataTable/GroupDetailMetadataTable';
import GroupDetailMetadataForm from './GroupDetailMetadataForm/GroupDetailMetadataForm';

const GroupDetailMetadata = ({ groupMetadata, setGroupMetadata, editGroupData, groupId, onUpdate, error, loading }) => {
  const [edit, setEdit] = useState(false);

  // if editGroupData exists, send toast feedback to user, toggle from the form to the table
  // we need a separate state for this so as to trigger the toast on only edit update and not on page load
  useEffect(() => {
    if (editGroupData) {
      toast.success('Group details updated');
      setEdit(false);
      setGroupMetadata(editGroupData);
    }
  }, [editGroupData, setGroupMetadata]);

  const onCancel = () => {
    setEdit(false);
  };

  const onEdit = () => {
    setEdit(true);
  };

  return (
    <>
      {edit ? (
        <GroupDetailMetadataForm
          groupMetadata={groupMetadata}
          onUpdate={onUpdate}
          error={error}
          onCancel={onCancel}
          groupId={groupId}
          loading={loading}
        />
      ) : (
        <GroupDetailMetadataTable groupMetadata={groupMetadata} onEdit={onEdit} />
      )}
    </>
  );
};

GroupDetailMetadata.defaultProps = {
  groupMetadata: null,
  editGroupData: null,
  error: null,
};

GroupDetailMetadata.propTypes = {
  groupMetadata: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  groupId: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  editGroupData: PropTypes.shape(),
  error: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
  setGroupMetadata: PropTypes.func.isRequired,
};

export default GroupDetailMetadata;
