import React from 'react';
import classnames from 'classnames';
import Header from '../../components/Header/Header';
import ChildrenProp from '../../prop-types/ChildrenProp';
import styles from './DefaultTemplate.module.scss';
import UserLoginModal from '../../components/UserLoginModal/UserLoginModal';

const DefaultTemplate = ({ children }) => (
  <>
    <UserLoginModal />

    <Header />

    <main className={classnames(styles.content, 'mb-5')}>{children}</main>
  </>
);

DefaultTemplate.propTypes = {
  children: ChildrenProp.isRequired,
};

export default DefaultTemplate;
