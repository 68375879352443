import formatPostDate from '../../utils/date-utils';

/**
 * @param  {Object} articleData - article data from endpoint response
 * @return  {Object} articleMetadata - refined article data for article metadata section
 */
const buildArticleMetadata = (articleData) => {
  const currentVersion = articleData?.versions.find((version) => version.current) || null;

  const { name: articleName, articleId, articleType, subject, isPerson } = articleData;
  const { name, articleTypeId } = articleType;

  const subjectId = subject?.subjectId || null;
  const subjectNotes = subject?.notes || '';
  const subjectName = subject?.name || '';
  const titleXml = articleData?.titleXml || '';
  const invertedTitleXml = articleData?.invertedTitleXml || '';
  const notes = articleData?.notes || '';
  const inwork = articleData.inwork ? 'Y' : 'N';
  const stable = articleData.stable ? 'Y' : 'N';
  const purged = articleData.purged ? 'Y' : 'N';
  const mediaCount = articleData?.mediaCount;
  const wordCount = currentVersion?.wordCount;
  const postDateValue = formatPostDate(currentVersion?.postDate);
  const topicId = articleData?.topics?.[0]?.topicId || null;
  const identifier = articleData?.topics?.[0]?.identifier || null;
  const displayUrl = articleData?.topics?.[0]?.displayUrl || null;

  // lexile can be null, which gives a warning for the value prop being equal to null
  const lexile = currentVersion?.lexile || undefined;
  const clusterName = articleData?.cluster?.name || '';
  const clusterId = articleData?.cluster?.clusterId || null;
  const cluster = articleData?.cluster ? { name: clusterName, clusterId } : null;

  // if versions exist, set to true
  const versionsFlag = articleData?.versions.length > 0;

  return {
    articleId,
    articleName,
    articleType: { name, articleTypeId },
    subject: { name: subjectName, id: subjectId, notes: subjectNotes },
    titleXml,
    invertedTitleXml,
    notes,
    inwork,
    stable,
    purged,
    mediaCount,
    wordCount,
    postDateValue,
    lexile,
    isPerson,
    cluster,
    versionsFlag,
    topicId,
    identifier,
    displayUrl,
  };
};

export default buildArticleMetadata;
