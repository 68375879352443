/**
 * @param {Object} workflowCreateFormData -  data from workflow create form
 * @param {string} username - the current username
 * @return {Object} - object containing the correct keys needed for the workflow create endpoint
 */
const buildWorkflowCreateRequestData = (workflowCreateFormData, username, articleId, wftype, featureId) => {
  const { cluster, name, workflow } = workflowCreateFormData;

  const payload = {
    wipName: name,
    username,
    processKey: workflow.value,
    cluster: cluster.value,
    wfType: wftype,
  };

  if (articleId)
    return {
      ...payload,
      articleId,
    };

  return {
    ...payload,
    featureId,
  };
};

export default buildWorkflowCreateRequestData;
