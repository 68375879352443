import React from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { Helmet } from 'react-helmet';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';

const ReportsPage = () => {
  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Reports</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Reports</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1>Editorial</h1>
            <ListGroup className="mb-3">
              <ListGroupItem>
                <a
                  href="https://drive.google.com/drive/folders/1RO_FR0iD4tsKEIkoH-hQSlssH9Joy36m?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Post Reports
                </a>
              </ListGroupItem>
            </ListGroup>

            <h1>Data Releases</h1>
            <ListGroup>
              <ListGroupItem>
                <a
                  href="https://drive.google.com/drive/folders/1GBQrAnRxqpsYgSkWL1pqzOiGA8Vbfqq-?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Britannica.com [Consumer]
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://drive.google.com/drive/folders/1LBeqziZJlSlb3fDPIisHz4KQhdrYzSh5?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  B2 sites, Kids
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://drive.google.com/drive/folders/1COj2xjb0WZsZ8VxxIaH2rsogRoAvaC5y?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  B2 International: Moderna, Escolar, and Escola
                </a>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </DefaultTemplate>
  );
};

export default ReportsPage;
