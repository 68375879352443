import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';

import useFeatureApi from '../../hooks/useFeatureApi';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import HelpIcon from '../../components/HelpIcon/HelpIcon';
import RecentFeaturesTable from './RecentFeaturesTable/RecentFeaturesTable';
import refineRecentFeatures from './RecentFeaturesPage.utils';

const RecentFeaturesPage = () => {
  const { getRecentFeatures, recentFeaturesData, errorGettingRecentFeatures, loadingRecentFeatures } = useFeatureApi();
  const [rowsData, setRowsData] = useState([]);

  useEffect(() => {
    getRecentFeatures();
  }, [getRecentFeatures]);

  useEffect(() => {
    if (recentFeaturesData) {
      setRowsData(refineRecentFeatures(recentFeaturesData?.features));
    }
  }, [recentFeaturesData]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Recently Updated Features</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Recently Updated Features</h1>
              <HelpIcon link="_Feature_Search" id="recentFeatures" />
            </div>
          </Col>
        </Row>

        {recentFeaturesData && !loadingRecentFeatures && <RecentFeaturesTable data={rowsData} />}
        {errorGettingRecentFeatures && !loadingRecentFeatures && <p> No new features in the last 7 days</p>}
        {loadingRecentFeatures && <SpinnerComponent size={spinnerSize1} setFullVh={false} />}
      </Container>
    </DefaultTemplate>
  );
};

export default RecentFeaturesPage;
