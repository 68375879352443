import React, { lazy, Suspense } from 'react';

const LazyCompletedWorkSearchPage = lazy(() => import('./CompletedWorkSearchPage'));

const CompletedWorkSearchPage = (props) => (
  <Suspense fallback={null}>
    <LazyCompletedWorkSearchPage {...props} />
  </Suspense>
);

export default CompletedWorkSearchPage;
