import { string, object } from 'yup';

const featureDetailSchema = object().shape({
  name: string()
    .required({
      field: 'name',
      errorMessage: 'Required field.',
    })
    .matches(/^[=\s\]\x5B0-9a-zA-Z!#$%&'()+,-]+$/, {
      excludeEmptyString: true,
      message: {
        field: 'name',
        errorMessage: "Only letters, numbers, spaces, and !#$%&'()+,-=[]",
      },
    }),
});

export default featureDetailSchema;
