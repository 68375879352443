import { useCallback } from 'react';
import { stringify } from 'query-string';

import { API_MAX_PER_PAGE, ELSIE_API } from '../App.constants';
import useFetchWithState from './useFetchWithState';
import useAuthApi from './useAuthApi';

const useP2PSyndicationApi = () => {
  const { getAuthCookies } = useAuthApi();
  const { encodedTicketFromCookie } = getAuthCookies();
  const {
    amFetchWithState: getSyndicationArticlesData,
    data: syndicationArticlesData,
    error: errorGettingSyndicationArticles,
    loading: gettingSyndicationArticles,
  } = useFetchWithState();
  const {
    amFetchWithState: p2pSyndicationArticleData,
    data: p2pSynArticleData,
    error: errorP2PingSynArticleGroup,
    loading: p2pingSynArticleGroup,
  } = useFetchWithState();

  const getSyndicationArticles = useCallback(
    (syndicationArticlesSearchFormData) => {
      getSyndicationArticlesData(
        `${ELSIE_API}/p2p/syndication/articles?${stringify(syndicationArticlesSearchFormData, {
          arrayFormat: 'comma',
        })}&perPage=${API_MAX_PER_PAGE}`
      );
    },
    [getSyndicationArticlesData]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const p2pSyndicationArticle = useCallback(
    (requestBody) => {
      p2pSyndicationArticleData(`${ELSIE_API}/p2p/syndication/batches/0/articles`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [p2pSyndicationArticleData, encodedTicketFromCookie]
  );

  return {
    getSyndicationArticles,
    syndicationArticlesData,
    errorGettingSyndicationArticles,
    gettingSyndicationArticles,
    p2pSyndicationArticle,
    p2pSynArticleData,
    errorP2PingSynArticleGroup,
    p2pingSynArticleGroup,
  };
};

export default useP2PSyndicationApi;
