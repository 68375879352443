import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Form, FormGroup, Label, Input, Button, Alert, FormFeedback, FormText } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { selectTheme, defineSelectFieldStyles } from '../../../utils/select-field-utils';
import useFormHandler from '../../../hooks/useFormHandler';
import { Routes, EDITORIAL_API } from '../../../App.constants';
import { WIPArticleCreateSchema, WIPFeatureCreateSchema } from './WIPCreateForm.schema';
import useWorkflowApi from '../../../hooks/useWorkflowApi';
import useAuthApi from '../../../hooks/useAuthApi';
import { WIP_CREATE_FORM_INITIAL_STATE, CreateWIPSelectCustomStyles } from './WIPCreateForm.constants';
import useUserLoginModal from '../../../hooks/useUserLoginModal';
import buildWorkflowCreateRequestData from './WIPCreateForm.utils';

const WIPCreateForm = ({ articleTypeId, name, articleId, wftype, featureId, featureProductId }) => {
  const {
    formData,
    formError,
    validationErrors,
    handleInputChange,
    handleSelectChange,
    handleResetForm,
    isFormValid,
    setFormData,
    setFormError,
  } = useFormHandler(WIP_CREATE_FORM_INITIAL_STATE);
  const {
    addWorkflow,
    addWorkflowData,
    errorAddingWorkflow,
    addingWorkflow,
    getWorkflowsList,
    workflowsListData,
    getProjectsList,
    projectsListData,
  } = useWorkflowApi();
  const history = useHistory();
  const { userDataFromStore } = useAuthApi();
  const { username } = userDataFromStore;
  const [refinedFormDataForRequest, setRefinedFormDataForRequest] = useState(null);
  const [dataFromWorkflows, setDataFromWorkflows] = useState(null);
  const [workflowOptions, setWorkflowOptions] = useState([]);
  const [clusterOptions, setClusterOptions] = useState([]);

  useUserLoginModal({
    onModalClose: () => {
      addWorkflow(refinedFormDataForRequest);
    },
    onCloseActionId: `POST ${EDITORIAL_API}/workflow/processes`,
  });

  // Get workflow workflows list data on mount with articleTypeId, and project cluster list
  useEffect(() => {
    getWorkflowsList(articleTypeId ? { articleTypeId } : { featureProductId });
    getProjectsList();
  }, [articleTypeId, featureProductId, getProjectsList, getWorkflowsList]);

  useEffect(() => {
    if (workflowsListData && projectsListData && dataFromWorkflows === null) {
      const { workflows } = workflowsListData;
      const { suffix, project } = workflows[0]?.entry;
      const { projects } = projectsListData;

      const WORKFLOW_OPTIONS = [];

      let CLUSTER_OPTIONS = [];

      workflows
        .filter((workflow) => workflow.entry.wftype === wftype)
        .map((option) => WORKFLOW_OPTIONS.push({ value: option.entry.processDefinitionKey, label: option.entry.name }));

      setWorkflowOptions(WORKFLOW_OPTIONS);

      CLUSTER_OPTIONS = projects.filter((projectCluster) => projectCluster?.entry.name === project)[0]?.entry.clusters;

      setClusterOptions(
        CLUSTER_OPTIONS.map((option) => ({
          value: option,
          label: option,
        }))
      );

      setFormData({
        ...formData,
        name: suffix ? name + suffix : name,
        project,
        workflow: WORKFLOW_OPTIONS.length === 1 ? WORKFLOW_OPTIONS[0] : null,
      });
      setDataFromWorkflows(0);
    }
  }, [dataFromWorkflows, formData, name, projectsListData, setFormData, wftype, workflowsListData]);

  // if addWIPData exists, send toast feedback to user and redirect to the WIP Detail page
  // for the created WIP id
  useEffect(() => {
    if (addWorkflowData) {
      toast.success('WIP created');
      history.push(Routes.MY_WORK.toLink());
    }
  }, [addWorkflowData, history]);

  // to handle the different error statuses and respond accordingly, 403 indicates a duplicate
  useEffect(() => {
    if (errorAddingWorkflow) {
      const errorStatus = errorAddingWorkflow?.status;

      if (errorStatus === 403) {
        toast.error({ name: 'Article already in work' });
      } else {
        toast.error('WIP cannot be created');
      }
    }
  }, [errorAddingWorkflow]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid(articleId ? WIPArticleCreateSchema : WIPFeatureCreateSchema)) {
      const refinedFormData = buildWorkflowCreateRequestData(formData, username, articleId, wftype, featureId);

      setRefinedFormDataForRequest(refinedFormData);
      addWorkflow(refinedFormData);
    }
  };

  const onFormReset = (e) => {
    e.preventDefault();
    handleResetForm();
  };

  return (
    <div className="d-flex align-items-start justify-content-center">
      <Form onSubmit={handleSubmit} onReset={onFormReset} data-testid="WIPCreateForm">
        <FormGroup>
          <Label for="name">Name: </Label>
          <Input
            id="name"
            name="name"
            onChange={handleInputChange}
            placeholder="WIP Name..."
            invalid={!!validationErrors?.name}
            value={formData.name}
          />
          <FormFeedback>{validationErrors.name}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for="project">Project: </Label>
          <Input
            id="project"
            name="project"
            onChange={handleInputChange}
            placeholder="Project..."
            invalid={!!validationErrors?.project}
            value={formData.project}
            disabled
          />
          <FormFeedback>{validationErrors.project}</FormFeedback>
        </FormGroup>

        <FormGroup className="mb-3">
          <Label for="cluster">Cluster: </Label>
          <div className="d-flex align-self-center align-items-center justify-content-center">
            <Select
              aria-labelledby="cluster"
              name="cluster"
              id="cluster"
              value={formData.cluster}
              onChange={handleSelectChange}
              options={clusterOptions}
              placeholder="Select Cluster..."
              theme={selectTheme}
              styles={defineSelectFieldStyles(CreateWIPSelectCustomStyles)}
            />
          </div>
          {!!validationErrors.cluster && <FormText color="danger">{validationErrors.cluster}</FormText>}
        </FormGroup>

        <FormGroup className="mb-3">
          <Label for="workflow">Workflow: </Label>
          <div className="d-flex align-self-center align-items-center justify-content-center">
            {workflowOptions.length === 1 ? (
              <Input
                id="workflow"
                name="workflow"
                onChange={handleInputChange}
                value={formData.workflow.label}
                disabled
              />
            ) : (
              <Select
                name="workflow"
                value={formData.workflow}
                onChange={handleSelectChange}
                options={workflowOptions}
                placeholder="Select Workflow..."
                theme={selectTheme}
                styles={defineSelectFieldStyles(CreateWIPSelectCustomStyles)}
              />
            )}
          </div>
          {!!validationErrors.workflow && <FormText color="danger">{validationErrors.workflow}</FormText>}
        </FormGroup>

        <div className="d-flex justify-content-center align-items-center">
          <Button type="submit" color="primary" className="me-2" disabled={addingWorkflow}>
            {addingWorkflow ? 'Creating...' : 'Create'}
          </Button>
          <Button type="reset" color="link" disabled={addingWorkflow}>
            Reset
          </Button>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-3">
          <Alert
            color="danger"
            className={classnames(alert, 'mb-0')}
            isOpen={!!formError}
            toggle={() => setFormError('')}
            fade={false}
          >
            {formError}
          </Alert>
        </div>
      </Form>
    </div>
  );
};

WIPCreateForm.defaultProps = {
  articleTypeId: null,
  name: '',
  articleId: null,
  wftype: 'editing',
  featureId: null,
  featureProductId: null,
};

WIPCreateForm.propTypes = {
  articleTypeId: PropTypes.number,
  name: PropTypes.string,
  articleId: PropTypes.number,
  wftype: PropTypes.string,
  featureId: PropTypes.number,
  featureProductId: PropTypes.number,
};

export default WIPCreateForm;
