import React, { lazy, Suspense } from 'react';

const LazyArticleClusterSearchPage = lazy(() => import('./ArticleClusterSearchPage'));

const ArticleClusterSearchPage = (props) => (
  <Suspense fallback={null}>
    <LazyArticleClusterSearchPage {...props} />
  </Suspense>
);

export default ArticleClusterSearchPage;
