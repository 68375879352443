import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';

const DateTableCell = ({ id, dateString, timeString }) => (
  <>
    {dateString && (
      <>
        <p id={id} className="mb-0">
          {dateString}
        </p>
        {timeString && (
          <UncontrolledTooltip placement="right" target={id} data-testid="date-table-cell-tooltip">
            {timeString}
          </UncontrolledTooltip>
        )}
      </>
    )}
  </>
);

DateTableCell.defaultProps = {
  dateString: null,
  timeString: null,
};

DateTableCell.propTypes = {
  id: PropTypes.string.isRequired,
  dateString: PropTypes.string,
  timeString: PropTypes.string,
};

export default DateTableCell;
