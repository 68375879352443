import React, { useEffect, useMemo } from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import DataProp from '../../../prop-types/ArticleSearchTableProps';
import ArticleSearchPageColumns from './ArticleSearchTable.columns';
import { API_MAX_PER_PAGE } from '../../../App.constants';
import DefaultTable from '../../../components/DefaultTable/DefaultTable';
import stringFilterType from '../../../components/DefaultTable/DefaultTable.utils';
import DefaultTableControls from '../../../components/DefaultTableControls/DefaultTableControls';
import {
  setArticleSearchTableConfigPageIndex,
  setArticleSearchTableConfigPageSize,
} from '../../../store/action-creators';

const ArticleSearchTable = ({ data, includesDeletesBoolean, actionSelect, handleAcceptAction }) => {
  const dispatch = useDispatch();
  const pageIndexFromRedux = useSelector((state) => state.articleSearchTablePageIndex);
  const pageSizeFromRedux = useSelector((state) => state.articleSearchTablePageSize);
  const columns = useMemo(
    () => ArticleSearchPageColumns(actionSelect, handleAcceptAction),
    [actionSelect, handleAcceptAction]
  );

  const numOfRows = data.length;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      filterTypes: stringFilterType,
      initialState: {
        hiddenColumns: includesDeletesBoolean ? [] : ['deleted'],
        pageIndex: pageIndexFromRedux,
        pageSize: pageSizeFromRedux,
      },
      manualPagination: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    dispatch(setArticleSearchTableConfigPageIndex(pageIndex));
  }, [pageIndex, dispatch]);

  useEffect(() => {
    dispatch(setArticleSearchTableConfigPageSize(pageSize));
  }, [dispatch, pageSize]);

  return (
    <div className="mt-3 overflow-visible">
      {numOfRows < API_MAX_PER_PAGE ? (
        <p>
          Articles found: <strong>{numOfRows}</strong>
        </p>
      ) : (
        <p>
          Articles found: <strong>{API_MAX_PER_PAGE}</strong> (API max), from <strong>{numOfRows}</strong>.
        </p>
      )}
      <DefaultTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
      />
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
    </div>
  );
};

ArticleSearchTable.defaultProps = {
  data: [],
  includesDeletesBoolean: false,
  actionSelect: {},
  handleAcceptAction: null,
};

ArticleSearchTable.propTypes = {
  data: DataProp,
  includesDeletesBoolean: PropTypes.bool,
  actionSelect: PropTypes.shape(),
  handleAcceptAction: PropTypes.func,
};

export default ArticleSearchTable;
