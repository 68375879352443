import { string, object } from 'yup';

export const WIPArticleCreateSchema = object().shape({
  name: string()
    .required({
      field: 'name',
      errorMessage: 'Required field.',
    })
    .matches(/^[=\s\]\x5B0-9a-z!#$%&'()+,-]+$/, {
      excludeEmptyString: true,
      message: {
        field: 'name',
        errorMessage: "Only lowercase letters, numbers, spaces, and !#$%&'()+,-=[]",
      },
    }),
  project: string().required({
    field: 'project',
    errorMessage: 'Required field.',
  }),
  cluster: object().shape({
    label: string().required({
      field: 'cluster',
      errorMessage: 'Required field.',
    }),
  }),
  workflow: object().shape({
    label: string().required({
      field: 'workflow',
      errorMessage: 'Required field.',
    }),
  }),
});

export const WIPFeatureCreateSchema = object().shape({
  name: string()
    .required({
      field: 'name',
      errorMessage: 'Required field.',
    })
    .matches(/^[=\s\]\x5B0-9a-zA-Z!#$%&'()+,-]+$/, {
      excludeEmptyString: true,
      message: {
        field: 'name',
        errorMessage: "Only letters, numbers, spaces, and !#$%&'()+,-=[]",
      },
    }),
  project: string().required({
    field: 'project',
    errorMessage: 'Required field.',
  }),
  cluster: object().shape({
    label: string().required({
      field: 'cluster',
      errorMessage: 'Required field.',
    }),
  }),
  workflow: object().shape({
    label: string().required({
      field: 'workflow',
      errorMessage: 'Required field.',
    }),
  }),
});
