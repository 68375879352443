import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { usePagination, useFilters, useSortBy, useTable } from 'react-table';

import { API_MAX_PER_PAGE } from '../../../App.constants';
import DefaultTable from '../../DefaultTable/DefaultTable';
import DefaultTableControls from '../../DefaultTableControls/DefaultTableControls';
import stringFilterType from '../../DefaultTable/DefaultTable.utils';
import userSelectModalTableColumns from './UserSelectModalTable.column';

const UserSelectModalTable = ({ rowsData, handleSelectUser }) => {
  const numOfRows = rowsData.length;
  const memoizedColumns = useMemo(() => userSelectModalTableColumns(handleSelectUser), [handleSelectUser]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data: rowsData,
      columns: memoizedColumns,
      filterTypes: stringFilterType,
      manualPagination: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div>
      {numOfRows < API_MAX_PER_PAGE ? (
        <p>
          Users found: <strong>{numOfRows}</strong>
        </p>
      ) : (
        <p>
          Users found: <strong>{API_MAX_PER_PAGE}</strong> (API max), from <strong>{numOfRows}</strong>.
        </p>
      )}
      <DefaultTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
      />
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
    </div>
  );
};

UserSelectModalTable.defaultProps = {
  rowsData: [],
};

UserSelectModalTable.propTypes = {
  rowsData: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number,
      userName: PropTypes.string,
      userTypeName: PropTypes.string,
      inWorkValueToDisplay: PropTypes.string,
      stableValueToDisplay: PropTypes.string,
    })
  ),
  handleSelectUser: PropTypes.func.isRequired,
};

export default UserSelectModalTable;
