import formatPostDate from '../../../../utils/date-utils';

/**
 * @param  {Object} institutionalArticlesData - institutional articles data from backend
 * @return  {Array} - array of objects with data in each object needed for a row of the institutional articles table
 */
const buildInstitutionalArticlesData = (institutionalArticlesData = []) => {
  return (
    institutionalArticlesData?.map((data) => ({
      ...data,
      postedDate: formatPostDate(data.postedDate),
      publishedDate: formatPostDate(data.batch.publishEnd),
      batchId: data.batch.batchId,
    })) || []
  );
};

export default buildInstitutionalArticlesData;
