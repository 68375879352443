import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setLoginModalConfig, setLoginModalConfigId, setLoginModalConfigIsOpen } from '../store/action-creators';
import usePrevious from './usePrevious';

const useUserLoginModal = ({ onModalClose, onCloseActionId } = {}) => {
  const loginModalConfig = useSelector((state) => state.loginModalConfig);
  const { isOpen, id: idFromRedux } = loginModalConfig;
  const previousIsOpen = usePrevious(isOpen);
  const dispatch = useDispatch();

  // If the previous isOpen state is not equal
  // to the current state and the current state
  // is false we know for sure the modal is being
  // closed after being open.
  // If this is the case and if there is an on
  // close method defined and the ID set for this
  // instance of useUserLoginModal is the same as the
  // ID set in Redux (see useFetch) then invoke
  // the on close method and reset the login modal config
  // ID state.
  useEffect(() => {
    if (previousIsOpen !== isOpen && !isOpen && onModalClose && idFromRedux === onCloseActionId) {
      onModalClose();
      dispatch(setLoginModalConfigId(null));
    }
  }, [onModalClose, idFromRedux, previousIsOpen, isOpen, onCloseActionId, dispatch]);

  /**
   * @param  {String} id - unique ID for login modal
   */
  const openModal = useCallback(
    (id) => {
      dispatch(setLoginModalConfig({ id, isOpen: true }));
    },
    [dispatch]
  );

  const closeModal = useCallback(() => {
    dispatch(setLoginModalConfigIsOpen(false));
  }, [dispatch]);

  return {
    isOpen,
    openModal,
    closeModal,
  };
};

export default useUserLoginModal;
