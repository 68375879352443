import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

const ConfirmModal = ({ toggle, header, confirmButton, message, showButtons, onConfirmButtonClick }) => {
  return (
    <div>
      <Modal isOpen toggle={toggle} centered size="md" data-testid="confirm-modal">
        <ModalHeader toggle={toggle} className="text-primary fw-bold border-bottom border-primary border-1">
          {header}
        </ModalHeader>
        <ModalBody>{message}</ModalBody>
        {showButtons && (
          <ModalFooter>
            <Button color="danger" onClick={onConfirmButtonClick} className="text-white">
              {confirmButton}
            </Button>
            <Button color="link" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

ConfirmModal.defaultProps = {
  showButtons: true,
};

ConfirmModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  confirmButton: PropTypes.string.isRequired,
  showButtons: PropTypes.bool,
  onConfirmButtonClick: PropTypes.func.isRequired,
};

export default ConfirmModal;
