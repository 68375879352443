import React, { lazy, Suspense } from 'react';

const LazyArticleClusterDetailPage = lazy(() => import('./ArticleClusterDetailPage'));

const ArticleClusterDetailPage = (props) => (
  <Suspense fallback={null}>
    <LazyArticleClusterDetailPage {...props} />
  </Suspense>
);

export default ArticleClusterDetailPage;
