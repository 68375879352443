/**
 * @param  {Array} articlesSearchData - article search data from backend - format -> [{articleData}]
 * @return  {Array} - array of objects with data in each object needed for a row of the article table
 */
const buildArticleSearchTableRowsData = (articlesSearchData = []) =>
  articlesSearchData
    ?.map((articleData) => {
      const { stable } = articleData;

      let stableValueToDisplay = null;

      if (stable !== undefined && stable !== null) {
        stableValueToDisplay = stable ? 'Y' : 'N';
      }

      return {
        articleId: articleData?.articleId,
        articleTypeName: articleData?.articleType?.name,
        articleName: articleData?.name,
        stableValueToDisplay,
      };
    })
    .sort((a, b) => {
      const articleIdA = a.articleId;
      const articleIdB = b.articleId;

      if (articleIdA < articleIdB) {
        return -1;
      }

      if (articleIdA > articleIdB) {
        return 1;
      }

      return 0;
    }) || [];

export default buildArticleSearchTableRowsData;
