import React from 'react';
import { Table, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { MdModeEdit } from 'react-icons/md';

const GroupDetailMetadataTable = ({ groupMetadata, onEdit }) => (
  <>
    <Table>
      <tbody>
        <tr>
          <th scope="row" className="border-0 text-end">
            Name:
          </th>
          <td className="border-0">{groupMetadata.name}</td>
        </tr>
        <tr>
          <th scope="row" className="border-0 pt-3 text-end">
            Description:
          </th>
          <td className="border-0 pt-3">{groupMetadata.description}</td>
        </tr>
      </tbody>
    </Table>

    <div className="d-flex justify-content-center align-items-center">
      <Button type="button" color="link" onClick={onEdit}>
        <MdModeEdit className="mb-1" /> Edit
      </Button>
    </div>
  </>
);

GroupDetailMetadataTable.defaultProps = {
  groupMetadata: {},
};

GroupDetailMetadataTable.propTypes = {
  groupMetadata: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  onEdit: PropTypes.func.isRequired,
};

export default GroupDetailMetadataTable;
