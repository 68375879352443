import React from 'react';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

import { Routes } from '../../../App.constants';
import styles from './RecentArticlesTable.module.scss';
import TextColumnFilter from '../../../components/TextColumnFilter/TextColumnFilter';
import DateTableCell from '../../../components/DateTableCell/DateTableCell';

const RecentArticlesPageColumns = [
  {
    Header: 'Article ID',
    accessor: 'articleId',
    disableFilters: true,
    Cell: (e) => {
      const { value } = e;

      return <Link to={Routes.ARTICLE_DETAIL.toLink({ articleId: value })}>{value}</Link>;
    },
  },
  {
    Header: 'Name',
    accessor: 'name',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { value, row } = e;
      const { original } = row;
      const { articleId } = original;

      return <Link to={Routes.ARTICLE_VIEW.toLink({ articleId, versionId: 'null' })}>{value}</Link>;
    },
  },
  {
    Header: 'Title',
    accessor: 'titleXml',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Type',
    accessor: 'articleTypeName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Posted',
    disableFilters: true,
    accessor: 'postDate',
    Cell: (e) => {
      const { row } = e;
      const { original, id } = row;
      const { postDateValue } = original;

      return (
        <DateTableCell id={`row${id}`} dateString={postDateValue?.dateMonthYear} timeString={postDateValue?.time} />
      );
    },
  },
  {
    Header: 'Article History Type',
    accessor: 'articleVersionHistoryType',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Article History Description',
    accessor: 'articleVersionHistoryDescXml',
    disableFilters: true,
    disableSortBy: true,
    Cell: (d) => {
      const { value } = d;

      return (
        <Highlighter highlightClassName={styles.highlight} textToHighlight={value} searchWords={[/<\/?[a-z]+>/i]} />
      );
    },
  },
  {
    Header: 'Editor/Author',
    accessor: 'contributors',
    disableFilters: true,
    disableSortBy: true,
  },
];

export default RecentArticlesPageColumns;
