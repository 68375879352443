import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import FeatureCreateForm from './FeatureCreateForm/FeatureCreateForm';

const FeatureCreatePage = () => {
  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Create New Feature</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Create New Feature</h1>
              {/* <HelpIcon link="new-feature" id="newFeature" /> */}
            </div>
          </Col>
        </Row>
        <FeatureCreateForm />
      </Container>
    </DefaultTemplate>
  );
};

export default FeatureCreatePage;
