import formatPostDate from '../../../../utils/date-utils';

/**
 * @param  {Object} featureVersionsData - feature versions data from backend
 * @return  {Array} - array of objects with data in each object needed for a row of the feature versions table
 */
const buildFeatureVersionsTableRowsData = (featureVersionsData = {}) => {
  return featureVersionsData?.map((featureVersionData) => ({
    updated: formatPostDate(featureVersionData.updated),
    featureId: featureVersionData.featureId,
  }));
};

export default buildFeatureVersionsTableRowsData;
