import { string, object } from 'yup';

const userLoginSchema = object().shape({
  username: string().required({
    field: 'username',
    errorMessage: 'Required field.',
  }),
  password: string().required({
    field: 'password',
    errorMessage: 'Required field.',
  }),
});

export default userLoginSchema;
