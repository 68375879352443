import React from 'react';
import { Label, Form, FormGroup, Button, Alert, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SelectField from '../../../components/SelectField/SelectField';
import { sortSelectFieldOptionsByLabel } from '../../../utils/select-field-utils';
import { filterInactiveArticleTypes } from '../../../utils/form-utils';
import useFormHandler from '../../../hooks/useFormHandler';
import styles from './FullTextSearchForm.module.scss';

const FullTextSearchForm = ({
  loading,
  abort,
  onSearch,
  onReset,
  setFullTextSearchFormData,
  fullTextSearchFormData,
}) => {
  const { formData, formError, handleSelectChange, handleResetForm, isFormEmpty, setFormError, handleInputChange } =
    useFormHandler(
      {
        text: '',
        articleTypes: null,
        corpus: null,
        tags: false,
      },
      fullTextSearchFormData,
      setFullTextSearchFormData
    );
  const { input } = styles;
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormEmpty()) {
      setFormError('');

      onSearch(formData);
    }
  };

  const onFormReset = (e) => {
    e.preventDefault();

    onReset();
    handleResetForm();
  };

  return (
    <Form onSubmit={handleSubmit} onReset={onFormReset} data-testid="fullTextSearchForm">
      <div className="d-flex flex-wrap align-items-center justify-content-center">
        <FormGroup className="me-3">
          <Label htmlFor="text">Text:</Label>
          <Input
            name="text"
            id="text"
            className={classnames(input, 'me-3')}
            value={formData.text}
            onChange={handleInputChange}
            placeholder="Text.."
          />
        </FormGroup>
        <FormGroup className="no-gutters">
          <Label htmlFor="articleTypes">Article Type:</Label>
          <SelectField
            onChange={handleSelectChange}
            name="articleTypes"
            id="articleTypes"
            isMulti={false}
            placeholder="Select Article Type..."
            reduxKey="articleTypes"
            valueKey="articleTypeId"
            value={formData?.articleTypes}
            labelKey="name"
            endpoint="/articles/articletypes"
            isDisabled={false}
            sortMethod={sortSelectFieldOptionsByLabel}
            filterMethod={filterInactiveArticleTypes}
          />
        </FormGroup>
        <FormGroup>
          <Label for="corpus">Corpus:</Label>
          <SelectField
            onChange={handleSelectChange}
            name="corpus"
            isMulti={false}
            id="corpus"
            placeholder="Select Corpus..."
            reduxKey="corpusTypes"
            valueKey="corpusId"
            value={formData.corpus}
            labelKey="name"
            endpoint="/articles/corpustypes"
            isDisabled={!!formData.articleTypes}
          />
        </FormGroup>
        <FormGroup className="mt-4">
          <FormGroup check className="me-3" key="tags">
            <Label check for="tags">
              Include Tags
            </Label>
            <Input type="checkbox" id="tags" name="tags" checked={formData.tags} onChange={handleInputChange} />
          </FormGroup>
        </FormGroup>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <Button type="submit" color="primary" className="me-2" disabled={loading}>
          {loading ? 'Searching...' : 'Search'}
        </Button>
        {loading && (
          <Button type="button" color="link" onClick={abort}>
            Cancel
          </Button>
        )}
        {!loading && (
          <Button type="reset" color="link">
            Reset
          </Button>
        )}
      </div>
      <div className="d-flex justify-content-center align-items-center mt-3">
        <Alert
          color="danger"
          className={classnames(alert, 'mb-0')}
          isOpen={!!formError}
          toggle={() => setFormError('')}
          fade={false}
        >
          {formError}
        </Alert>
      </div>
    </Form>
  );
};

FullTextSearchForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  abort: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  setFullTextSearchFormData: PropTypes.func.isRequired,
  fullTextSearchFormData: PropTypes.shape({}).isRequired,
};

export default FullTextSearchForm;
