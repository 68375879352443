/**
 * @param  {array} searchResults - an array of search results from the backend
 * @return  {array} - an array of search results with only necessary data included
 */
const refineFeatureSearchResults = (searchResults) =>
  searchResults.map((searchResult) => {
    const { featureId, name, featureType, language, tier, inwork, purged } = searchResult;

    const { name: featureTypeName, product } = featureType;
    const { name: languageName } = language;
    const { name: tierName } = tier;
    const { productId } = product;

    return {
      featureId,
      name,
      featureTypeName,
      languageName,
      tierName,
      productId,
      inwork: inwork ? 'Y' : 'N',
      deleted: purged ? 'Y' : 'N',
    };
  });

export default refineFeatureSearchResults;
