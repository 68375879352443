/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, FormGroup, Input, Label } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { BsPlusCircle } from 'react-icons/bs';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import useUserApi from '../../hooks/useUserApi';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import UsersTable from './UsersTable/UsersTable';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import AddUserModal from '../../components/AddUserModal/AddUserModal';
import buildUsersTableRowsData from './UsersPage.utils';

const UsersPage = () => {
  const {
    getUsers,
    usersData,
    gettingUsers,
    errorGettingUsers,
    deleteUser,
    deleteData,
    errorDeletingUser,
    deletingUser,
  } = useUserApi();
  const [errorGettingUsersMessage, setErrorGettingUsersMessage] = useState('');
  const [userDataResults, setUserDataResults] = useState();
  const [userId, setShowUserId] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const loading = gettingUsers || deletingUser;
  const [showInactive, setShowInactive] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  useEffect(() => {
    if (errorGettingUsers) {
      const errorStatus = errorGettingUsers?.status;

      if (errorStatus === 404) {
        setErrorGettingUsersMessage('No users found.');
      } else if (errorStatus === 400) {
        setErrorGettingUsersMessage('Bad request');
      } else {
        setErrorGettingUsersMessage('There was an issue loading the processes.');
      }
    }
  }, [errorGettingUsers]);

  // get users on mount
  useEffect(() => {
    getUsers(showInactive);
  }, [getUsers, showInactive]);

  useEffect(() => {
    if (usersData) {
      setUserDataResults(buildUsersTableRowsData(usersData?.users));
    }
  }, [usersData]);

  const handleDeleteUsers = (value) => {
    setShowConfirmModal(true);
    setShowUserId(value);
  };

  const onConfirmDeleteButtonClick = () => {
    deleteUser(userId);
    setShowConfirmModal(false);
  };

  // delete is successful, show the toast and reroute to homepage
  useEffect(() => {
    if (deleteData) {
      toast.success('User deleted');
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [deleteData]);

  // if there is an error while deleting the user, show the error toast
  useEffect(() => {
    if (errorDeletingUser) {
      const errorStatus = errorDeletingUser.status;

      if (errorStatus === 409) {
        toast.error('User not deleted, referenced in other tables. User has been marked inactive.');
        setTimeout(() => window.location.reload(), 1000);
      } else if (errorStatus === 400) {
        toast.error('Invalid input');
      } else if (errorStatus === 404) {
        toast.error('Unable to find user');
      } else toast.error('Cannot be deleted');
    }
  }, [errorDeletingUser]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Users</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Users</h1>
            </div>
          </Col>
        </Row>

        <div className="d-flex justify-content-center align-items-center mb-3">
          <Button className="d-flex align-items-center" color="link" onClick={() => setShowAddUserModal(true)}>
            <BsPlusCircle className="me-1" />
            Add user
          </Button>
        </div>

        {!errorGettingUsersMessage && !loading && !userDataResults && <p>Use the form to search for users...</p>}
        {loading && (
          <div className="mt-3">
            <SpinnerComponent size={spinnerSize1} setFullVh={false} />
          </div>
        )}
        {userDataResults && !loading && (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <FormGroup switch>
                <Input
                  type="switch"
                  id="showInactiveSwitch"
                  name="showInactiveSwitch"
                  checked={showInactive}
                  onChange={() => setShowInactive(!showInactive)}
                />
                <Label check for="showInactiveSwitch">
                  Show Inactive
                </Label>
              </FormGroup>
            </div>
            <UsersTable data={userDataResults} handleDeleteUsers={handleDeleteUsers} />
          </>
        )}
        {errorGettingUsersMessage && !loading && errorGettingUsersMessage}
        {showConfirmModal && (
          <ConfirmModal
            toggle={() => setShowConfirmModal(false)}
            header="Confirm Delete"
            confirmButton="Delete"
            message="Are you sure you want to delete this user?"
            showButtons
            onConfirmButtonClick={onConfirmDeleteButtonClick}
          />
        )}
        {showAddUserModal && <AddUserModal toggle={() => setShowAddUserModal(false)} />}
      </Container>
    </DefaultTemplate>
  );
};

export default UsersPage;
