import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import useP2PConsumerApi from '../../../../hooks/useP2PConsumerApi';
import SpinnerComponent from '../../../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../../../components/SpinnerComponent/SpinnerComponent.constants';
import buildConsumerFeaturesData from './ConsumerStatusFeatures.utils';
import ConsumerStatusFeaturesTable from './ConsumerStatusFeaturesTable/ConsumerStatusFeaturesTable';

const ConsumerStatusFeatures = ({ formData }) => {
  const { getConsumerFeatures, consumerFeaturesData, errorGettingConsumerFeatures, gettingConsumerFeatures } =
    useP2PConsumerApi();
  const noConsumerFeaturesFoundMessage = <p className="mt-3">There are no features.</p>;
  const tableRowsData = consumerFeaturesData ? buildConsumerFeaturesData(consumerFeaturesData?.features) : null;

  useEffect(() => {
    const result = new Date(formData.date);

    result.setDate(result.getDate() + 1);

    getConsumerFeatures({
      startedAfter: `${formData.date?.getFullYear()}-${formData.date?.getMonth() + 1}-${formData.date?.getDate()}`,
      startedBefore: `${formData.date?.getFullYear()}-${result.getMonth() + 1}-${result.getDate()}`,
      pending: formData.pending,
    });
  }, [formData.date, formData.onlyWips, formData.pending, getConsumerFeatures]);

  return (
    <>
      <Helmet>
        <title>AM: Consumer Status: Features</title>
      </Helmet>

      {errorGettingConsumerFeatures?.status === 404 && noConsumerFeaturesFoundMessage}
      {errorGettingConsumerFeatures?.status !== 404 && errorGettingConsumerFeatures && (
        <p className="mt-3">There was an issue loading the consumer features.</p>
      )}
      {(gettingConsumerFeatures || !consumerFeaturesData) && !errorGettingConsumerFeatures && (
        <div className="mt-3">
          <SpinnerComponent size={spinnerSize1} setFullVh={false} />
        </div>
      )}
      {tableRowsData && !gettingConsumerFeatures && !errorGettingConsumerFeatures && (
        <ConsumerStatusFeaturesTable data={tableRowsData} />
      )}
    </>
  );
};

ConsumerStatusFeatures.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default ConsumerStatusFeatures;
