// Utils for select field components
import variables from '../App.module.scss';

/**
 * Overriding react-select default theme allows us to set
 * a few syles that should be consistent for all the instances.
 * Units if not present should default to pixels.
 * The theme object structure is as follows.
 *  {
 *    borderRadius: 4
 *    colors: {
 *      danger: "#DE350B"
        dangerLight: "#FFBDAD"
        neutral0: "hsl(0, 0%, 100%)"
        neutral5: "hsl(0, 0%, 95%)"
        neutral10: "hsl(0, 0%, 90%)"
        neutral20: "hsl(0, 0%, 80%)"
        neutral30: "hsl(0, 0%, 70%)"
        neutral40: "hsl(0, 0%, 60%)"
        neutral50: "hsl(0, 0%, 50%)"
        neutral60: "hsl(0, 0%, 40%)"
        neutral70: "hsl(0, 0%, 30%)"
        neutral80: "hsl(0, 0%, 20%)"
        neutral90: "hsl(0, 0%, 10%)"
        primary: "#2684FF"
        primary25: "#DEEBFF"
        primary50: "#B2D4FF"
        primary75: "#4C9AFF"
 *    },
      spacing: {
        baseUnit: 4 (TODO: This seems to be tied to the controlHeight but I am not exactly sure of its full purpose.)
        controlHeight: 38
        menuGutter: 8
      }
 *  }
 * @param  {Object} theme - default theme styles created by react-select
 * @returns  {Object} - custom theme styles
 */
export const selectTheme = (theme) => ({
  ...theme,
  borderRadius: '0.25rem',
  spacing: {
    ...theme.spacing,
    controlHeight: 31,
    baseUnit: 1,
  },
});

/**
 * Take data gathered from Bessie and refine it for react-select
 * @param  {Array} rawOptions - array of objects with each object containing the keys for a given Bessie endpoint
 * @param  {string} valueKey - key for react-select value in object returned by Bessie
 * @param  {string} labelKey - key for react-select label in object returned by Bessie
 * @returns  {Array} - array of objects with each object containing value and label keys required by react-select
 */
export const createRefinedSelectFieldOptions = (rawOptions, valueKey, labelKey) =>
  rawOptions?.map((rawOption) => ({
    value: rawOption[valueKey],
    label: rawOption[labelKey],
    data: rawOption,
  }));

/**
 * @param  {Array} refinedSelectFieldOptions - array of objects refined for react-select
 * @return  {Array} - array of objects refined for react-select sorted by label
 */
export const sortSelectFieldOptionsByLabel = (refinedSelectFieldOptions) => {
  const sortedRefinedSelectFieldOptions = refinedSelectFieldOptions;

  return sortedRefinedSelectFieldOptions.sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();

    if (labelA < labelB) {
      return -1;
    }

    if (labelA > labelB) {
      return 1;
    }

    return 0;
  });
};

// TODO: I was having difficulty testing this function due to the fact
// that the keys have functions as values and from what I have researched
// it is not possible to compare two functions when testing and checking
// if the received and expected are equal in Jest.
/**
 * Styles from provided variables. Units if not present should default to pixels.
 * control:
 * {
 *   &:hover: {borderColor: "hsl(0, 0%, 70%)"}
 *   alignItems: "center"
 *   backgroundColor: "hsl(0, 0%, 100%)"
 *   borderColor: "hsl(0, 0%, 80%)"
 *   borderRadius: "0.25rem"
 *   borderStyle: "solid"
 *   borderWidth: 1
 *   boxShadow: null
 *   boxSizing: "border-box"
 *   cursor: "default"
 *   display: "flex"
 *   flexWrap: "wrap"
 *   justifyContent: "space-between"
 *   label: "control"
 *   minHeight: 31
 *   outline: "0 !important"
 *   position: "relative"
 *   transition: "all 100ms"
 * }
 * placeholder:
 * {
 *   boxSizing: "border-box"
 *   color: "hsl(0, 0%, 50%)"
 *   label: "placeholder"
 *   marginLeft: 0.5
 *   marginRight: 0.5
 *   position: "absolute"
 *   top: "50%"
 *   transform: "translateY(-50%)"
 * }
 * option:
 * {
 *   :active: {backgroundColor: "#B2D4FF"}
 *   WebkitTapHighlightColor: "rgba(0, 0, 0, 0)"
 *   backgroundColor: "transparent"
 *   boxSizing: "border-box"
 *   color: "inherit"
 *   cursor: "default"
 *   display: "block"
 *   fontSize: "inherit"
 *   label: "option"
 *   padding: "2px 3px"
 *   userSelect: "none"
 *   width: "100%"
 * }
 * menu:
 * {
 *   backgroundColor: "hsl(0, 0%, 100%)"
 *   borderRadius: "0.25rem"
 *   boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)"
 *   boxSizing: "border-box"
 *   label: "menu"
 *   marginBottom: 8
 *   marginTop: 8
 *   position: "absolute"
 *   top: "100%"
 *   width: "100%"
 *   zIndex: 1
 * }
 * @param  {Object} control - custom syle overrides for control
 * @param  {Object} option - custom style overrides for option
 * @param  {Object} menu - custom syle overrides for menu
 * @param  {Object} placeholder - custom style overrides for placeholder
 * @return  {Object} - custom style object
 */
export const defineSelectFieldStyles = ({ control = {}, option = {}, menu = {}, placeholder = {} } = {}) => ({
  control: (provided) => ({
    ...provided,
    padding: '0.125rem 0.125rem 0.125rem 0.375rem',
    borderColor: variables.gray400,
    lineHeight: 1.2,
    fontSize: variables.fontSizeBase,
    minWidth: '15.625rem',
    maxWidth: '15.625rem',
    marginRight: '1rem',
    ...control,
  }),
  option: (provided) => ({
    ...provided,
    padding: '0.125rem 0.375rem',
    ...option,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: variables.zIndexDropdown,
    minWidth: '15.625rem',
    maxWidth: '15.625rem',
    ...menu,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: variables.fontSizeBase,
    color: variables.gray600,
    ...placeholder,
  }),
});
