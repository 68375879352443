import React, { useEffect } from 'react';
import classnames from 'classnames';
import { Form, FormGroup, Label, Input, Button, Alert, FormFeedback, FormText } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import SelectField from '../../../components/SelectField/SelectField';
import useFormHandler from '../../../hooks/useFormHandler';
import { sortSelectFieldOptionsByLabel } from '../../../utils/select-field-utils';
import styles from './FeatureCreateForm.module.scss';
import useFeatureApi from '../../../hooks/useFeatureApi';
import {
  FEATURE_CREATE_FORM_INITIAL_STATE,
  CreateFeatureSelectCustomStyles,
  CreateFeatureSelectInvalidCustomStyles,
} from './FeatureCreateForm.constants';
import featureCreateSchema from './FeatureCreateForm.schema';
import { Routes } from '../../../App.constants';

const FeatureCreateForm = () => {
  const { addFeature, addFeatureData, errorAddingFeature, addingFeature } = useFeatureApi();
  const {
    formData,
    formError,
    handleSelectChange,
    handleResetForm,
    isFormValid,
    setFormError,
    handleInputChange,
    validationErrors,
    setValidationErrors,
    setFormData,
  } = useFormHandler(FEATURE_CREATE_FORM_INITIAL_STATE);
  const { input } = styles;
  const history = useHistory();

  const filterTemplateByProductOrTier = (templates) =>
    templates?.filter(
      (template) =>
        template.featureType.product.name === formData.products?.label &&
        template.featureType.tier.name === formData.tiers?.label
    );

  // if addFeatureData exists, send toast feedback to user and redirect to the Feature Detail page
  // for the created feature id
  useEffect(() => {
    if (addFeatureData) {
      toast.success('Feature created');
      history.push(Routes.FEATURE_DETAIL.toLink({ featureId: addFeatureData.featureId.toString() }));
    }
  }, [addFeatureData, history]);

  // to handle the different error statuses and respond accordingly, 403 indicates a duplicate
  useEffect(() => {
    if (errorAddingFeature) {
      const errorStatus = errorAddingFeature?.status;

      if (errorStatus === 403) {
        setValidationErrors({ ...validationErrors, name: 'Feature Name already exists' });
      } else if (errorStatus !== 401) {
        toast.error('Feature cannot be created');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAddingFeature, setValidationErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid(featureCreateSchema)) {
      const refinedFormData = {
        ...formData,
        featureTemplateId: formData.templates.value,
        languageId: formData.languages.value,
      };

      addFeature(refinedFormData);
    }
  };

  useEffect(() => {
    if (formData.products?.label === 'Consumer' && formData.tiers === null) {
      setFormData({
        ...formData,
        tiers: {
          data: { name: 'High School', tierId: 3 },
          label: 'High School',
          value: 3,
        },
        languages: {
          data: { languageId: 'engus', name: 'English-US' },
          label: 'English-US',
          value: 'engus',
        },
      });
    }
  }, [formData, setFormData]);

  const onFormReset = (e) => {
    e.preventDefault();
    handleResetForm();
  };

  return (
    <div className="d-flex align-items-start justify-content-center">
      <Form data-testid="featureCreateForm" onSubmit={handleSubmit} onReset={onFormReset}>
        <FormGroup>
          <Label for="name">Name: </Label>
          <Input
            id="name"
            name="name"
            className={classnames(input, 'pe-3')}
            onChange={handleInputChange}
            placeholder="Feature Name..."
            value={formData.name}
            invalid={!!validationErrors?.name}
          />
          <FormFeedback>{validationErrors.name}</FormFeedback>
        </FormGroup>

        <FormGroup className="mb-0">
          <Label for="products">Product:</Label>
          <SelectField
            onChange={handleSelectChange}
            name="products"
            id="products"
            isMulti={false}
            placeholder="Select Product..."
            reduxKey="products"
            valueKey="productId"
            value={formData.products}
            labelKey="name"
            endpoint="/feature/products"
            sortMethod={sortSelectFieldOptionsByLabel}
            isDisabled={!!formData.templates}
            stylesToOverride={CreateFeatureSelectCustomStyles}
          />
        </FormGroup>

        <FormGroup className="mb-0">
          <Label for="tiers">Tier:</Label>
          <SelectField
            onChange={handleSelectChange}
            name="tiers"
            id="tiers"
            isMulti={false}
            placeholder="Select Tier..."
            reduxKey="tiers"
            valueKey="tierId"
            value={formData.tiers}
            labelKey="name"
            endpoint="/feature/tiers"
            sortMethod={sortSelectFieldOptionsByLabel}
            isDisabled={!!formData.templates || formData.products?.label === 'Consumer'}
            stylesToOverride={CreateFeatureSelectCustomStyles}
          />
        </FormGroup>

        <FormGroup className="mb-0">
          <Label for="templates">Template:</Label>
          <SelectField
            onChange={handleSelectChange}
            name="templates"
            id="templates"
            isMulti={false}
            placeholder="Select Template..."
            reduxKey="templates"
            valueKey="featureTemplateId"
            value={formData.templates}
            labelKey="name"
            endpoint="/feature/templates"
            sortMethod={sortSelectFieldOptionsByLabel}
            filterMethod={formData.products !== null || formData.tiers !== null ? filterTemplateByProductOrTier : null}
            isDisabled={false}
            stylesToOverride={
              validationErrors.templates ? CreateFeatureSelectInvalidCustomStyles : CreateFeatureSelectCustomStyles
            }
          />
          {!!validationErrors.templates && <FormText color="danger">{validationErrors.templates}</FormText>}
        </FormGroup>

        <FormGroup className="mb-0">
          <Label for="languages">Language:</Label>
          <SelectField
            onChange={handleSelectChange}
            name="languages"
            id="languages"
            isMulti={false}
            placeholder="Select Language..."
            reduxKey="languages"
            valueKey="languageId"
            value={formData.languages}
            labelKey="name"
            endpoint="/feature/languages"
            sortMethod={sortSelectFieldOptionsByLabel}
            isDisabled={formData.products?.label === 'Consumer'}
            stylesToOverride={
              validationErrors.languages ? CreateFeatureSelectInvalidCustomStyles : CreateFeatureSelectCustomStyles
            }
          />
          {!!validationErrors.languages && <FormText color="danger">{validationErrors.languages}</FormText>}
        </FormGroup>

        <div className="d-flex justify-content-center align-items-center">
          <Button type="submit" color="primary" className="me-2" disabled={addingFeature}>
            {addingFeature ? 'Creating...' : 'Create'}
          </Button>
          <Button type="reset" color="link" disabled={addingFeature}>
            Reset
          </Button>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-3">
          <Alert
            color="danger"
            className={classnames(alert, 'mb-0')}
            isOpen={!!formError}
            toggle={() => setFormError('')}
            fade={false}
          >
            {formError}
          </Alert>
        </div>
      </Form>
    </div>
  );
};

export default FeatureCreateForm;
