import React from 'react';
import { Table, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import DateTableCell from '../../../components/DateTableCell/DateTableCell';
import formatPostDate from '../../../utils/date-utils';
import { convertMStoDate } from '../WIPDetailPage.utils';

const WIPDetailMetadata = ({ data }) => {
  const started = formatPostDate(data.startedAt);
  const completed = formatPostDate(data.endedAt);

  return (
    <>
      <Row className="justify-content-center">
        <Col>
          <Table>
            <tbody>
              <tr>
                <th scope="row" className="border-0 text-end">
                  Workflow:
                </th>
                <td className="border-0">{data.processName}</td>
              </tr>
              <tr>
                <th scope="row" className="border-0 text-end">
                  WIP Name:
                </th>
                <td className="border-0">{data.variables?.bpm_description}</td>
              </tr>
              <tr>
                <th scope="row" className="border-0 text-end">
                  Cluster:
                </th>
                <td className="border-0">{data.variables?.eb_cluster}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table>
            <tbody>
              <tr>
                <th scope="row" className="border-0 text-end">
                  Started:
                </th>
                <td className="border-0">
                  <DateTableCell id="started" dateString={started.dateMonthYear} timeString={started.time} />
                </td>
              </tr>
              <tr>
                <th scope="row" className="border-0 text-end">
                  Completed:
                </th>
                <td className="border-0">
                  <DateTableCell id="completed" dateString={completed.dateMonthYear} timeString={completed.time} />
                </td>
              </tr>
              <tr>
                <th scope="row" className="border-0 text-end">
                  Duration:
                </th>
                <td className="border-0">{convertMStoDate(data?.duration)}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table>
            <tbody>
              <tr>
                <th scope="row" className="border-0   text-end">
                  Started By:
                </th>
                <td className="border-0">{data.startUserId}</td>
              </tr>
              <tr>
                <th scope="row" className="border-0   text-end">
                  {data.variables?.eb_articleId ? 'Article ID:' : 'Feature ID:'}
                </th>
                <td className="border-0">
                  {data.variables?.eb_articleId ? data.variables?.eb_articleId : data.variables?.eb_featureId}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

WIPDetailMetadata.defaultProps = {
  data: {},
};

WIPDetailMetadata.propTypes = {
  data: PropTypes.shape(),
};

export default WIPDetailMetadata;
