export const SET_SELECT_FIELD_OPTIONS = 'SET_SELECT_FIELD_OPTIONS';
export const SET_USER = 'SET_USER';
export const SET_ARTICLE_SEARCH_FORM_DATA = 'SET_ARTICLE_SEARCH_FORM_DATA';
export const SET_ARTICLE_SEARCH_TABLE_CONFIG_PAGE_INDEX = 'SET_ARTICLE_SEARCH_TABLE_CONFIG_PAGE_INDEX';
export const SET_ARTICLE_SEARCH_TABLE_CONFIG_PAGE_SIZE = 'SET_ARTICLE_SEARCH_TABLE_CONFIG_PAGE_SIZE';
export const SET_LOGIN_MODAL_CONFIG = 'SET_LOGIN_MODAL_CONFIG';
export const SET_LOGIN_MODAL_CONFIG_IS_OPEN = 'SET_LOGIN_MODAL_CONFIG_IS_OPEN';
export const SET_LOGIN_MODAL_CONFIG_ID = 'SET_LOGIN_MODAL_CONFIG_ID';
export const SET_CLUSTER_SEARCH_FORM_DATA = 'SET_CLUSTER_SEARCH_FORM_DATA';
export const SET_CLUSTER_SEARCH_TABLE_CONFIG_PAGE_INDEX = 'SET_CLUSTER_SEARCH_TABLE_CONFIG_PAGE_INDEX';
export const SET_CLUSTER_SEARCH_TABLE_CONFIG_PAGE_SIZE = 'SET_CLUSTER_SEARCH_TABLE_CONFIG_PAGE_SIZE';
export const SET_INDEX_SEARCH_FORM_DATA = 'SET_INDEX_SEARCH_FORM_DATA';
export const SET_INDEX_SEARCH_TABLE_CONFIG_PAGE_INDEX = 'SET_INDEX_SEARCH_TABLE_CONFIG_PAGE_INDEX';
export const SET_INDEX_SEARCH_TABLE_CONFIG_PAGE_SIZE = 'SET_INDEX_SEARCH_TABLE_CONFIG_PAGE_SIZE';
export const SET_VERSION_HISTORY_TABLE_CONFIG_PAGE_INDEX = 'SET_VERSION_HISTORY_TABLE_CONFIG_PAGE_INDEX';
export const SET_VERSION_HISTORY_TABLE_CONFIG_PAGE_SIZE = 'SET_VERSION_HISTORY_TABLE_CONFIG_PAGE_SIZE';
export const SET_MY_WORK_TABLE_CONFIG_PAGE_INDEX = 'SET_MY_WORK_TABLE_CONFIG_PAGE_INDEX';
export const SET_MY_WORK_TABLE_CONFIG_PAGE_SIZE = 'SET_MY_WORK_TABLE_CONFIG_PAGE_SIZE';
export const SET_MY_WORK_SORT_CONFIG = 'SET_MY_WORK_SORT_CONFIG';
export const SET_MY_WORK_TABLE_CONFIG_FILTERS = 'SET_MY_WORK_TABLE_CONFIG_FILTERS';
export const SET_FEATURE_SEARCH_FORM_DATA = 'SET_FEATURE_SEARCH_FORM_DATA';
export const SET_FEATURE_SEARCH_TABLE_CONFIG_PAGE_INDEX = 'SET_FEATURE_SEARCH_TABLE_CONFIG_PAGE_INDEX';
export const SET_FEATURE_SEARCH_TABLE_CONFIG_PAGE_SIZE = 'SET_FEATURE_SEARCH_TABLE_CONFIG_PAGE_SIZE';
export const SET_FULL_TEXT_SEARCH_FORM_DATA = 'SET_FULL_TEXT_SEARCH_FORM_DATA';
export const SET_FULL_TEXT_SEARCH_TABLE_CONFIG_PAGE_INDEX = 'SET_FULL_TEXT_SEARCH_TABLE_CONFIG_PAGE_INDEX';
export const SET_FULL_TEXT_SEARCH_TABLE_CONFIG_PAGE_SIZE = 'SET_FULL_TEXT_SEARCH_TABLE_CONFIG_PAGE_SIZE';
export const SET_RECENT_ARTICLES_TABLE_CONFIG_PAGE_INDEX = 'SET_RECENT_ARTICLES_TABLE_CONFIG_PAGE_INDEX';
export const SET_RECENT_ARTICLES_TABLE_CONFIG_PAGE_SIZE = 'SET_RECENT_ARTICLES_TABLE_CONFIG_PAGE_SIZE';
