import formatPostDate from '../../../utils/date-utils';

/**
 * @param  {array} searchResults - an array of search results from the backend
 * @return  {array} - an array of search results with only necessary data included
 */
const refineArticleSearchResults = (searchResults) =>
  searchResults.map((searchResult) => {
    const {
      articleId,
      name,
      titleXml,
      articleType,
      currentVersion,
      stable,
      inwork,
      mediaCount,
      cluster,
      topics,
      deleted,
      subject,
    } = searchResult;

    const subjectName = subject?.name || '';
    const { name: articleTypeName, articleTypeId, allowWip } = articleType;
    const postDate = currentVersion?.postDate || '';
    const articleCluster = cluster?.name || '';
    const articleClusterId = cluster?.clusterId || null;
    const stableValueToDisplay = stable ? 'Y' : 'N';
    const inWorkValueToDisplay = inwork ? 'Y' : 'N';
    const deletedValueToDisplay = deleted ? 'Y' : 'N';
    const topicId = topics.length !== 0 ? topics[0].topicId : null;
    const major = currentVersion?.major || null;
    const postDateValue = formatPostDate(postDate);

    return {
      articleId,
      name,
      titleXml,
      articleTypeName,
      postDateValue,
      stableValueToDisplay,
      inWorkValueToDisplay,
      mediaCount,
      articleCluster,
      topicId,
      topics,
      postDate,
      articleTypeId,
      deletedValueToDisplay,
      articleClusterId,
      subjectName,
      major,
      allowWip,
    };
  });

export default refineArticleSearchResults;
