/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, UncontrolledTooltip } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AiFillTags } from 'react-icons/ai';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import BackLink from '../../components/BackLink/BackLink';
import { Routes, EDITORIAL_API } from '../../App.constants';
import useFeatureApi from '../../hooks/useFeatureApi';
import styles from './FeatureViewPage.module.scss';

const FeatureViewPage = () => {
  const { featureId } = useParams();
  const { getFeatureView, featureViewData, loadingFeatureViewData } = useFeatureApi();
  const [styledView, setStyledView] = useState('');
  const [tagView, setTagView] = useState('');
  const [styled, setStyled] = useState(true);

  useEffect(() => {
    if (featureViewData) {
      setStyledView(
        `<link rel="stylesheet" href=${featureViewData.styledView?.css}><div id="content">${featureViewData.styledView?.fragment}</div>`.replaceAll(
          '"/static/images/link_table.png',
          `"${EDITORIAL_API}/static/images/link_table.png`
        )
      );
      setTagView(`<link rel="stylesheet" href=${featureViewData.tagView?.css}>${featureViewData.tagView?.fragment}`);
    }
  }, [featureViewData]);

  // get feature viewer content on mount
  useEffect(() => {
    getFeatureView(featureId);
  }, [featureId, getFeatureView]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Feature Viewer</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <BackLink to={Routes.FEATURE_SEARCH.toLink()} linkText="Feature Search" />
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Feature Viewer</h1>
              <div className="d-flex justify-content-end align-items-end">
                <Button
                  className="me-2 mb-1"
                  onClick={() => setStyled(!styled)}
                  aria-label="Show Tags"
                  color="primary"
                  id="showtag"
                >
                  <AiFillTags />
                </Button>
                <UncontrolledTooltip target="showtag">{styled ? 'Show Tags' : 'Hide Tags'}</UncontrolledTooltip>
              </div>
            </div>
          </Col>
        </Row>
        {loadingFeatureViewData && (
          <div className="mt-3">
            <SpinnerComponent size={spinnerSize1} setFullVh={false} />
          </div>
        )}
        {!loadingFeatureViewData && (
          <div className={styles.viewer}>
            <span dangerouslySetInnerHTML={{ __html: styled ? styledView : tagView }} />
          </div>
        )}
      </Container>
    </DefaultTemplate>
  );
};

export default FeatureViewPage;
