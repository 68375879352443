import formatPostDate from '../../../../../utils/date-utils';

/**
 * @param  {Object} articleVersionsData - article versions data from backend - format -> {versions:[{versions data}]}
 * @return  {Array} - array of objects with data in each object needed for a row of the article versions table
 */
export const buildArticleVersionsTableRowsData = (articleVersionsData = {}) => {
  const tableRowData = [];

  articleVersionsData?.versions?.forEach((articleVersionData) => {
    const { histories } = articleVersionData;
    const workflowPost = articleVersionData.workflowPost ? 'Y' : 'N';
    const postDate = formatPostDate(articleVersionData?.postDate);
    const attachments = refineAttachments(articleVersionData?.attachments);
    const wordCount = articleVersionData?.wordCount ?? null;
    const lexileScore = articleVersionData?.lexile ?? null;
    const version = articleVersionData.major || '';

    if (histories?.length) {
      histories.forEach((history, index) => {
        tableRowData.push({
          version: index === 0 ? version : null,
          postDate: index === 0 ? postDate : null,
          count: histories.length || 1, // set count to length, 1 if no histories exist to render the first row
          setRowspan: index === 0, // true if we render history td tags with rowspan
          currentIndex: index,
          workflowPost,
          title: articleVersionData?.titleXml || '',
          wordCount: index === 0 ? wordCount : null,
          lexileScore: index === 0 ? lexileScore : null,
          attachments: index === 0 ? attachments : null,
          articleVersionHistoryType: history?.type || '',
          articleVersionHistoryDescXml: history?.descriptionXml || '',
          contributors: buildContributorsList(history?.contributors),
          // The additional history key is used to toggle the edit icon in each row.
          // There is no POST for article version history so only cases where
          // history is not null will the icon be displayed.
          history,
          majorVersionNumber: version,
          articleVersionHistoryId: history?.articleVersionHistoryId || '',
          contributor1: history?.contributors
            ? history?.contributors.find((contributor) => contributor?.role === 'Editor')
            : null,
          contributor2: history?.contributors
            ? history?.contributors.find((contributor) => contributor?.role === 'Author')
            : null,
        });
      });
    } // else in case no histories are associated with that article
    else {
      tableRowData.push({
        version,
        postDate,
        setRowspan: true, // render a single row
        count: 1, //  1 since no histories exist, to render the first row
        workflowPost,
        title: articleVersionData?.titleXml || '',
        wordCount,
        lexileScore,
        attachments,
        articleVersionHistoryType: '',
        articleVersionHistoryDescXml: '',
        contributors: '',
        // Since we know that no history is defined for this row
        majorVersionNumber: version,
        history: null,
      });
    }
  });

  return tableRowData;
};

/**
 * @param  {Array} attachmentsData - array of objects returned from the backend containing the attachment data
 * @return  {Array} - refined array of attachment data for the versions table columns
 */
export const refineAttachments = (attachmentsData) => {
  if (!attachmentsData || attachmentsData.length === 0) {
    return [];
  }

  return attachmentsData.map((attachmentData, index) => ({
    fileName: attachmentData.filename,
    // Id is for key attribute.
    id: index,
  }));
};

/**
 * @param  {Array} contributorsData - array of objects returned from the backend containing the contributor data
 * @return  {string} - list of contributor names
 */
export const buildContributorsList = (contributorsData) => {
  if (!contributorsData || contributorsData.length === 0) {
    return '';
  }

  const contributorCount = contributorsData.length;

  if (contributorCount === 1) {
    return contributorsData[0].displayName;
  }

  const reducer = (accumulator, currentValue) => {
    return `${accumulator}, ${currentValue.displayName}`;
  };

  return contributorsData
    .filter((contributorData, index) => index > 0)
    .reduce(reducer, contributorsData[0].displayName);
};
