import React, { useEffect, useMemo } from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { Form, Button, UncontrolledTooltip } from 'reactstrap';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import MyWorkSearchPageColumns from './MyWorkTable.columns';
import { API_MAX_PER_PAGE } from '../../../App.constants';
import CustomMyWorkTable from './CustomMyWorkTable';
import stringFilterType from '../../../components/DefaultTable/DefaultTable.utils';
import DefaultTableControls from '../../../components/DefaultTableControls/DefaultTableControls';
import {
  setMyWorkTableConfigPageIndex,
  setMyWorkTableConfigPageSize,
  setMyWorkSortConfig,
  setMyWorkTableConfigFilters,
} from '../../../store/action-creators';

const MyWorkTable = ({ data, handleActionChange, setViewLinkUrl, updatingTasks, allWork, debugMode }) => {
  const dispatch = useDispatch();
  const pageIndexFromRedux = useSelector((state) => state.myWorkTablePageIndex);
  const pageSizeFromRedux = useSelector((state) => state.myWorkTablePageSize);
  const memoizedColumns = useMemo(
    () => MyWorkSearchPageColumns(handleActionChange, setViewLinkUrl, updatingTasks, allWork, debugMode),
    [handleActionChange, setViewLinkUrl, updatingTasks, allWork, debugMode]
  );
  const numOfRows = data.length;
  const sortState = useSelector((state) => state.myWorkSortConfig);
  const myWorkFilters = useSelector((state) => state.myWorkFilters);

  const handleReset = () => {
    dispatch(setMyWorkTableConfigPageIndex(0));
    dispatch(setMyWorkTableConfigFilters([]));
    dispatch(setMyWorkSortConfig([{ id: 'started', desc: false }]));

    // reset table states
    setAllFilters([]);
    setSortBy([{ id: 'started', desc: false }]);
    gotoPage(0);
  };

  const ResetBtn = () => (
    <Button type="reset" color="link">
      Reset
      <AiOutlineInfoCircle id="resetwips" size="1.125rem" className="ms-1 mb-1" />
      <UncontrolledTooltip target="resetwips">
        This will reset sort, filter and page number selections
      </UncontrolledTooltip>
    </Button>
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, filters },
    setAllFilters,
    setSortBy,
  } = useTable(
    {
      data,
      autoResetSortBy: false,
      disableSortRemove: true,
      columns: memoizedColumns,
      filterTypes: stringFilterType,
      initialState: {
        pageIndex: pageIndexFromRedux,
        pageSize: pageSizeFromRedux,
        sortBy: sortState,
        filters: myWorkFilters,
      },
      manualPagination: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    dispatch(setMyWorkTableConfigPageIndex(pageIndex));
  }, [pageIndex, dispatch]);

  useEffect(() => {
    dispatch(setMyWorkTableConfigPageSize(pageSize));
  }, [dispatch, pageSize]);

  return (
    <Form className="mt-3" onReset={handleReset}>
      {numOfRows < API_MAX_PER_PAGE ? (
        <div className="d-flex align-items-center justify-content-between">
          <span>
            WIPs found: <strong>{numOfRows}</strong>
          </span>
          <ResetBtn />
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-between">
          <span>
            WIPs found: <strong>{API_MAX_PER_PAGE}</strong> (API max), from <strong>{numOfRows}</strong>.
          </span>
          <ResetBtn />
        </div>
      )}

      <CustomMyWorkTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
        sortBy={sortBy}
        filters={filters}
      />
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
    </Form>
  );
};

MyWorkTable.defaultProps = {
  data: [],
  handleActionChange: null,
  setViewLinkUrl: null,
  updatingTasks: false,
  allWork: false,
  debugMode: false,
};

MyWorkTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  handleActionChange: PropTypes.func,
  setViewLinkUrl: PropTypes.func,
  updatingTasks: PropTypes.bool,
  allWork: PropTypes.bool,
  debugMode: PropTypes.bool,
};

export default MyWorkTable;
