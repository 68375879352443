import React, { lazy, Suspense } from 'react';

const LazyArticleCreatePage = lazy(() => import('./ArticleCreatePage'));

const ArticleCreatePage = (props) => (
  <Suspense fallback={null}>
    <LazyArticleCreatePage {...props} />
  </Suspense>
);

export default ArticleCreatePage;
