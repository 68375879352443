import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ModalHeader, ModalBody, Form, Input, Label, FormGroup, Button, Alert } from 'reactstrap';

import ARTICLE_SEARCH_FORM_INITIAL_STATE from './ArticleSearchModal.constants';
import SelectField from '../SelectField/SelectField';
import useFormHandler from '../../hooks/useFormHandler';
import ArticleSearchModalTable from './ArticleSearchModalTable/ArticleSearchModalTable';
import buildArticleSearchTableRowsData from './ArticleSearchModal.utils';
import useArticleApi from '../../hooks/useArticleApi';
import SpinnerComponent from '../SpinnerComponent/SpinnerComponent';
import { spinnerSize2 } from '../SpinnerComponent/SpinnerComponent.constants';

const ArticleSearchModal = ({ toggle, handleSelectArticle }) => {
  const { getArticles, articlesData, errorGettingArticles, loadingArticles, abortGettingArticles } = useArticleApi();
  const [articleDataResults, setArticleDataResults] = useState(null);
  const [errorGettingArticlesMessage, setErrorGettingArticlesMessage] = useState('');
  const [isWaitingForSearchResults, setIsWaitingForArticleSubjectSearchResults] = useState(false);
  const [tableRowsData, setTableRowsData] = useState([]);
  const { formData, handleInputChange, handleSelectChange, handleResetForm, formError, setFormError, isFormEmpty } =
    useFormHandler(ARTICLE_SEARCH_FORM_INITIAL_STATE);

  useEffect(() => {
    if (articleDataResults) {
      setTableRowsData(buildArticleSearchTableRowsData(articleDataResults));
    } else {
      setTableRowsData([]);
    }
  }, [articleDataResults]);

  // If the articles data is retrieved successfully set the error state to null,
  // the articles data state to the corresponding data and the page count state to
  // the current page count.
  useEffect(() => {
    if (articlesData) {
      setErrorGettingArticlesMessage(null);
      setArticleDataResults(articlesData?.articles);
    }
  }, [articlesData]);

  // If there is an error during the fetch (400 or 404) set the errorm message and remove the
  // current subjects
  useEffect(() => {
    if (errorGettingArticles) {
      const errorStatus = errorGettingArticles?.status;

      setArticleDataResults(null);

      if (errorStatus === 404 || errorStatus === 400) {
        setErrorGettingArticlesMessage('No subjects were found.');
      } else if (errorGettingArticles.message === 'The user aborted a request.') {
        setErrorGettingArticlesMessage(null);
      } else {
        setErrorGettingArticlesMessage('There was an issue loading the subjects.');
      }
    }
  }, [errorGettingArticles]);

  // Set waiting state based on status of fetch.
  useEffect(() => {
    setIsWaitingForArticleSubjectSearchResults(loadingArticles);
  }, [loadingArticles, setIsWaitingForArticleSubjectSearchResults]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormEmpty()) {
      const refinedFormData = { ...formData, typeId: formData.typeId?.value };

      setFormError('');

      setArticleDataResults(null);
      getArticles(refinedFormData);
    }
  };

  const onFormReset = (e) => {
    e.preventDefault();

    setArticleDataResults(null);
    setErrorGettingArticlesMessage(null);
    handleResetForm();
  };

  return (
    <Modal isOpen toggle={toggle} size="xl" centered className="px-0 px-sm-4">
      <ModalHeader toggle={toggle} className="text-primary fw-bold border-bottom border-primary border-1">
        <span className="primary">Article Search</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md="4" lg="3">
            <Form onSubmit={handleSubmit} onReset={onFormReset}>
              <FormGroup className="no-gutters">
                <Label htmlFor="name">Name:</Label>
                <Input name="name" id="name" bsSize="sm" value={formData.name} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="no-gutters">
                <Label htmlFor="typeId">Article Type:</Label>
                <SelectField
                  onChange={handleSelectChange}
                  name="typeId"
                  id="typeId"
                  isMulti={false}
                  placeholder="Select Article Type..."
                  reduxKey="articleTypes"
                  valueKey="articleTypeId"
                  value={formData?.typeId}
                  labelKey="name"
                  endpoint="/articles/articletypes"
                  isDisabled={false}
                />
              </FormGroup>
              <Alert color="danger" isOpen={!!formError} toggle={() => setFormError('')} fade={false}>
                {formError}
              </Alert>
              <div className="text-center mt-4">
                <Button type="submit" color="primary" block disabled={isWaitingForSearchResults} data-testid="Search">
                  {isWaitingForSearchResults ? 'Searching...' : 'Search'}
                </Button>
                {isWaitingForSearchResults && (
                  <Button type="button" color="link" className="mt-2" onClick={abortGettingArticles}>
                    Cancel
                  </Button>
                )}
                {!isWaitingForSearchResults && (
                  <Button type="reset" className="mt-2" color="link">
                    Reset
                  </Button>
                )}
              </div>
            </Form>
          </Col>

          <Col md="8" lg="9">
            <h1 className="text-primary fw-bold m-0">Results</h1>
            {!errorGettingArticlesMessage && !isWaitingForSearchResults && tableRowsData.length === 0 && (
              <p>Use the form to search for articles...</p>
            )}

            {tableRowsData.length !== 0 && !isWaitingForSearchResults && (
              <ArticleSearchModalTable handleSelectArticle={handleSelectArticle} rowsData={tableRowsData} />
            )}
            {errorGettingArticlesMessage && !isWaitingForSearchResults && errorGettingArticlesMessage}
            {isWaitingForSearchResults && <SpinnerComponent size={spinnerSize2} setFullVh={false} />}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

ArticleSearchModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  handleSelectArticle: PropTypes.func.isRequired,
};

export default ArticleSearchModal;
