import formatPostDate from '../../utils/date-utils';

/**
 * @param  {Object} myWorkData - myWorksData data from backend - format -> {tasks: [{myWorkData}]}
 * @return  {Array} - array of objects with data in each object needed for a row of the myWorkData table
 */

const buildAllTableRowsData = (myWorkData = {}) =>
  myWorkData
    ?.map((data) => ({
      workflow: data.entry?.processName,
      step: data.entry?.name,
      wipName: data.entry?.description,
      cluster: data.entry?.eb_cluster,
      started: formatPostDate(data.entry?.startedAt),
      user: data.entry.state === 'claimed' ? data.entry.assignee : data.entry.state,
      comment: data.entry?.bpm_comment,
      taskId: data.entry?.id,
      id: data.entry?.processId,
      startedAt: data.entry?.startedAt,
    }))
    .sort((a, b) => {
      const startedA = a.startedAt;
      const startedB = b.startedAt;

      if (startedA > startedB) {
        return -1;
      }

      if (startedA < startedB) {
        return 1;
      }

      return 0;
    }) || [];

export default buildAllTableRowsData;
