import formatPostDate from '../../utils/date-utils';

const refineRecentArticles = (results) =>
  results.map((searchResult) => {
    const { featureId, name, featureType, language, tier, inwork, purged, updated } = searchResult;

    const { name: featureTypeName, product } = featureType;
    const { name: languageName } = language;
    const { name: tierName } = tier;
    const { productId } = product;
    const postDateValue = formatPostDate(updated);

    return {
      featureId,
      name,
      featureTypeName,
      languageName,
      tierName,
      productId,
      inwork: inwork ? 'Y' : 'N',
      deleted: purged ? 'Y' : 'N',
      postDateValue,
    };
  });

export default refineRecentArticles;
