import React, { useEffect, useState, useRef } from 'react';
import { FormGroup, Label, Input, Button, FormFeedback, Col, Form } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { diff } from 'deep-object-diff';

import useFormHandler from '../../../../hooks/useFormHandler';
import useUserLoginModal from '../../../../hooks/useUserLoginModal';
import { BESSIE_API } from '../../../../App.constants';
import userEditSchema from './UserMetadataForm.schema';

const UserDetailMetadataForm = ({ userMetadata, onUpdate, error, onCancel, userId, loading }) => {
  const { formData, validationErrors, handleInputChange, isFormValid } = useFormHandler(userMetadata);
  const initialFormDataRef = useRef(userMetadata);
  const [refinedFormDataForRequest, setRefinedFormDataForRequest] = useState(null);

  useUserLoginModal({
    onModalClose: () => {
      onUpdate(refinedFormDataForRequest, userId);
    },
    onCloseActionId: `PATCH ${BESSIE_API}/user/${userId}`,
  });

  // to handle the different error statuses and respond accordingly, 403 indicates a duplicate
  useEffect(() => {
    if (error) {
      const errorStatus = error.status;

      if (errorStatus === 400) {
        toast.error('Invalid input');
      }

      if (errorStatus === 403) {
        toast.error('A user with this name already exists');
      } else toast.error('User cannot be updated');
    }
  }, [error]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid(userEditSchema)) {
      setRefinedFormDataForRequest({ ...formData });

      const refinedFormData = diff(initialFormDataRef.current, formData);

      if (!Object.keys(refinedFormData).length) {
        toast.error('No changes detected');
      } else {
        onUpdate({ ...formData }, userId);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <Label htmlFor="firstName" className="fw-bold text-start text-md-end pe-0" md={4}>
          First Name:
        </Label>
        <Col md={8}>
          <Input
            className="me-3"
            name="firstName"
            id="firstName"
            value={formData.firstName}
            invalid={!!validationErrors?.firstName}
            onChange={handleInputChange}
          />
          <FormFeedback>{validationErrors.firstName}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label htmlFor="lastName" className="fw-bold text-start text-md-end pe-0" md={4}>
          Last Name:
        </Label>
        <Col md={8}>
          <Input
            className="me-3"
            name="lastName"
            id="lastName"
            value={formData.lastName}
            invalid={!!validationErrors?.lastName}
            onChange={handleInputChange}
          />
          <FormFeedback>{validationErrors.lastName}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label htmlFor="email" className="fw-bold text-start text-md-end pe-0" md={4}>
          Email:
        </Label>
        <Col md={8}>
          <Input
            className="me-3"
            name="email"
            id="email"
            value={formData.email}
            invalid={!!validationErrors?.email}
            onChange={handleInputChange}
          />
          <FormFeedback>{validationErrors.email}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label htmlFor="username" className="fw-bold text-start text-md-end pe-0" md={4}>
          Username:
        </Label>
        <Col md={8}>
          <Input
            className="me-3"
            name="username"
            id="username"
            value={formData.username}
            invalid={!!validationErrors?.username}
            onChange={handleInputChange}
          />
          <FormFeedback>{validationErrors.username}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label htmlFor="inactive" className="fw-bold text-start text-md-end pe-0" md={4}>
          Inactivate:
        </Label>
        <Col md={8}>
          <Input
            className="me-3 mt-2"
            type="checkbox"
            name="inactive"
            id="inactive"
            checked={formData.inactive}
            onChange={handleInputChange}
            disabled={!!validationErrors?.inactive}
          />
        </Col>
      </FormGroup>

      <div className="d-flex justify-content-center align-items-center">
        <Button type="submit" color="success" disabled={loading} className="text-white">
          {loading ? 'Saving...' : 'Save'}
        </Button>
        <Button type="reset" color="link" disabled={loading} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Form>
  );
};

UserDetailMetadataForm.defaultProps = {
  userMetadata: null,
  error: null,
};

UserDetailMetadataForm.propTypes = {
  userMetadata: PropTypes.shape({}),
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  error: PropTypes.shape(),
  userId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UserDetailMetadataForm;
