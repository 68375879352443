import React from 'react';
import { Table, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import DateTableCell from '../../../../../components/DateTableCell/DateTableCell';

const FeatureDetailMetadataTable = ({ featureMetadata }) => {
  return (
    <>
      <Row>
        <Col md="6">
          <Table bordered>
            <tbody>
              <tr>
                <th scope="row">Feature ID</th>
                <td className="text-break">{featureMetadata.featureId}</td>
              </tr>
              <tr>
                <th scope="row">Feature Name</th>
                <td className="text-break">{featureMetadata.name}</td>
              </tr>
              <tr>
                <th scope="row">Feature Type</th>
                <td className="text-break">{featureMetadata.featureType}</td>
              </tr>
              <tr>
                <th scope="row">Tier</th>
                <td className="text-break">{featureMetadata.tier}</td>
              </tr>
              <tr>
                <th scope="row">Language</th>
                <td className="text-break">{featureMetadata.language}</td>
              </tr>
              <tr>
                <th scope="row">Product</th>
                <td className="text-break">{featureMetadata.product}</td>
              </tr>
              <tr>
                <th scope="row">Last Updated</th>
                <td>
                  <DateTableCell
                    id="updated"
                    dateString={featureMetadata.updated?.dateMonthYear}
                    timeString={featureMetadata.updated?.time}
                  />
                </td>
              </tr>

              {featureMetadata.product !== 'School' && (
                <tr>
                  <th scope="row">urlTitle</th>
                  <td className="text-break">{featureMetadata.urlTitle}</td>
                </tr>
              )}

              <tr>
                <th scope="row">Stable</th>
                <td className="text-break">{featureMetadata.stable}</td>
              </tr>
              <tr>
                <th scope="row">Deleted</th>
                <td className="text-break">{featureMetadata.purged}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

FeatureDetailMetadataTable.defaultProps = {
  featureMetadata: null,
};

FeatureDetailMetadataTable.propTypes = {
  featureMetadata: PropTypes.shape(),
};

export default FeatureDetailMetadataTable;
