import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '../../../App.constants';
import TextColumnFilter from '../../../components/TextColumnFilter/TextColumnFilter';

const articleDistributionListTableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { row, value } = e;
      const { original } = row;
      const { distributionId } = original;

      return <Link to={Routes.DISTRIBUTION_DETAIL.toLink({ distributionId })}>{value}</Link>;
    },
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    Filter: TextColumnFilter,
  },
];

export default articleDistributionListTableColumns;
