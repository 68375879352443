import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { MdDelete } from 'react-icons/md';

import { Routes } from '../../../App.constants';
import TextColumnFilter from '../../../components/TextColumnFilter/TextColumnFilter';

const articleDistributionDetailTableColumns = (handleArticleRemovalFromDistributions) => [
  {
    Header: 'Article ID',
    accessor: 'articleId',
    disableFilters: true,
    Cell: (e) => {
      const { value } = e;

      return <Link to={Routes.ARTICLE_DETAIL.toLink({ articleId: value })}>{value}</Link>;
    },
  },
  {
    Header: 'Article Name',
    accessor: 'name',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { value, row } = e;
      const { original } = row;
      const { articleId } = original;

      return (
        <Link to={Routes.ARTICLE_VIEW.toLink({ articleId, versionId: 'null' })} className="p-0">
          {value}
        </Link>
      );
    },
  },
  {
    Header: 'Type',
    accessor: 'articleTypeName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Title',
    accessor: 'titleXml',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Trash',
    accessor: 'articleId',
    id: 'delete-article-from-distribution',
    disableFilters: true,
    disableSortBy: true,
    Cell: (e) => {
      const { row, value } = e;
      const { id } = row;

      return (
        <Button
          className="p-0"
          type="button"
          color="link"
          aria-label="Trash"
          data-testid={`trash-${id}`}
          onClick={() => handleArticleRemovalFromDistributions(value)}
        >
          <MdDelete size={18} />
        </Button>
      );
    },
  },
];

export default articleDistributionDetailTableColumns;
