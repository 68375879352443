/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import useHelpApi from '../../hooks/useHelpApi';
import styles from './HelpPage.module.scss';
import { EDITORIAL_API } from '../../App.constants';

const HelpPage = () => {
  const { getHelp, helpData, errorGettingHelp } = useHelpApi();
  const [pageMessage, setPageMessage] = useState('Loading help page');
  const userGuide = helpData
    ?.replaceAll('"media', `"${EDITORIAL_API}/bsc/docs/media`)
    .replace('docs.css', `${EDITORIAL_API}/bsc/docs/docs.css`);

  useEffect(() => {
    if (errorGettingHelp) {
      setPageMessage(<p>Cannot load user guide</p>);
    }
  }, [errorGettingHelp]);

  // get help content on mount
  useEffect(() => {
    getHelp();
  }, [getHelp]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: User Guide</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">User Guide</h1>
            </div>
          </Col>
        </Row>
        {!userGuide && pageMessage}

        <div className={styles.help}>
          <span dangerouslySetInnerHTML={{ __html: userGuide }} />
        </div>
      </Container>
    </DefaultTemplate>
  );
};

export default HelpPage;
