import React from 'react';
import { Label, Form, FormGroup, Button, Alert, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SelectField from '../../../components/SelectField/SelectField';
import useFormHandler from '../../../hooks/useFormHandler';
import { sortSelectFieldOptionsByLabel } from '../../../utils/select-field-utils';
import styles from './FeatureSearchForm.module.scss';

const FeatureSearchForm = ({ loading, abort, onSearch, onReset, setFeatureSearchFormData, featureSearchFormData }) => {
  const { formData, formError, handleSelectChange, handleResetForm, isFormEmpty, setFormError, handleInputChange } =
    useFormHandler(
      {
        featureId: '',
        products: null,
        featureTypes: null,
        featureName: '',
        purged: false,
      },
      featureSearchFormData,
      setFeatureSearchFormData
    );
  const { input } = styles;

  const filterFeatureByProduct = (featureTypes) =>
    featureTypes?.filter((featureType) => featureType.product.name === formData.products.label);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormEmpty()) {
      setFormError('');

      if (formData.purged) {
        const refinedFormData = (({ purged, ...o }) => o)(formData);

        delete refinedFormData.purged;
        onSearch(refinedFormData);
      } else onSearch(formData);
    }
  };

  const onFormReset = (e) => {
    e.preventDefault();

    onReset();
    handleResetForm();
  };

  return (
    <Form onSubmit={handleSubmit} onReset={onFormReset} data-testid="featureSearchForm">
      <div className="d-flex flex-wrap align-items-center justify-content-center">
        <FormGroup>
          <Label for="products">Product:</Label>
          <SelectField
            onChange={handleSelectChange}
            name="products"
            id="products"
            isMulti={false}
            placeholder="Select Product..."
            reduxKey="products"
            valueKey="productId"
            value={formData.products}
            labelKey="name"
            endpoint="/feature/products"
            isDisabled={formData.featureId !== ''}
          />
        </FormGroup>
        <FormGroup className="me-3">
          <Label htmlFor="featureName">Feature Name:</Label>
          <Input
            name="featureName"
            id="featureName"
            className={classnames(input, 'me-3')}
            value={formData.featureName}
            onChange={handleInputChange}
            placeholder="Feature Name.."
          />
        </FormGroup>
        <FormGroup className="mb-0">
          <Label for="featureTypes">Feature Type:</Label>
          <SelectField
            onChange={handleSelectChange}
            name="featureTypes"
            id="featureTypes"
            isMulti={false}
            placeholder="Select Feature Type..."
            reduxKey="featureTypes"
            valueKey="featureTypeId"
            value={formData.featureTypes}
            labelKey="name"
            endpoint="/feature/featureTypes"
            filterMethod={formData.products !== null ? filterFeatureByProduct : null}
            sortMethod={sortSelectFieldOptionsByLabel}
            isDisabled={formData.products === null || formData.featureId !== ''}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="featureId">Feature ID:</Label>
          <Input
            name="featureId"
            id="featureId"
            className={classnames(input, 'me-3')}
            value={formData.featureId}
            onChange={handleInputChange}
            placeholder="Feature ID.."
          />
        </FormGroup>
        <FormGroup>
          <FormGroup check className="me-3" key="purged">
            <Label check for="purged">
              Include deletes
            </Label>
            <Input type="checkbox" id="purged" name="purged" checked={formData.purged} onChange={handleInputChange} />
          </FormGroup>
        </FormGroup>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <Button type="submit" color="primary" className="me-2" disabled={loading}>
          {loading ? 'Searching...' : 'Search'}
        </Button>
        {loading && (
          <Button type="button" color="link" onClick={abort}>
            Cancel
          </Button>
        )}
        {!loading && (
          <Button type="reset" color="link">
            Reset
          </Button>
        )}
      </div>
      <div className="d-flex justify-content-center align-items-center mt-3">
        <Alert
          color="danger"
          className={classnames(alert, 'mb-0')}
          isOpen={!!formError}
          toggle={() => setFormError('')}
          fade={false}
        >
          {formError}
        </Alert>
      </div>
    </Form>
  );
};

FeatureSearchForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  abort: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  setFeatureSearchFormData: PropTypes.func.isRequired,
  featureSearchFormData: PropTypes.shape({}).isRequired,
};

export default FeatureSearchForm;
