import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import FeatureSearchForm from './FeatureSearchForm/FeatureSearchForm';
import FeatureSearchTable from './FeatureSearchTable/FeatureSearchTable';
import useFeatureApi from '../../hooks/useFeatureApi';
import refineFeatureSearchResults from './FeatureSearchPage.utils';
import { checkForEmptyData } from '../../utils/form-utils';
import { setFeatureSearchFormData, setFeatureSearchTableConfigPageIndex } from '../../store/action-creators';

const FeatureSearchPage = () => {
  const { getFeatures, featuresData, errorGettingFeatures, loadingFeatures, abortGettingFeatures } = useFeatureApi();
  const [featureDataResults, setFeatureDataResults] = useState(null);
  const featureSearchFormData = useSelector((state) => state.featureSearchFormData);
  const dispatch = useDispatch();
  const [featureSearchRequestData, setFeatureSearchRequestData] = useState({
    productId: featureSearchFormData.products?.value,
    featureTypeId: featureSearchFormData.featureTypes?.value,
    featureId: featureSearchFormData?.featureId,
    featureName: featureSearchFormData?.featureName,
    purged: featureSearchFormData?.purged,
  });

  // Every time featureSearchRequestData state is set make sure featureSearchRequestData
  // exists and is not empty and if so set appropriate state and get articles.
  // This will happen on mount (if there is data for the form in Redux) or on form submit.
  useEffect(() => {
    if (featureSearchRequestData && !checkForEmptyData(featureSearchRequestData)) {
      getFeatures(featureSearchRequestData);
    }
  }, [featureSearchRequestData, getFeatures]);

  useEffect(() => {
    if (featuresData) {
      setFeatureDataResults(refineFeatureSearchResults(featuresData?.features));
      setFeatureSearchRequestData(null);
    }
  }, [featuresData]);

  const onSearch = (formData) => {
    setFeatureDataResults(null);

    setFeatureSearchRequestData({
      productId: formData.products?.value,
      featureTypeId: formData.featureTypes?.value,
      featureId: formData.featureId,
      featureName: formData.featureName,
      purged: formData.purged,
    });

    dispatch(setFeatureSearchFormData(formData));
    dispatch(setFeatureSearchTableConfigPageIndex(0));
  };

  const onReset = () => {
    setFeatureDataResults(null);
    dispatch(setFeatureSearchTableConfigPageIndex(0));
  };

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Feature Search</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Feature Search</h1>
            </div>
          </Col>
        </Row>
        <FeatureSearchForm
          loading={loadingFeatures}
          abort={abortGettingFeatures}
          onSearch={onSearch}
          onReset={onReset}
          setFeatureSearchFormData={setFeatureSearchFormData}
          featureSearchFormData={featureSearchFormData}
        />
        <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
          <h1 className="text-primary fw-bold">Results</h1>
          {/* <HelpIcon link="_Feature_Search_results" id="featureSearchResults" /> */}
        </div>
        {!errorGettingFeatures && !loadingFeatures && !featureDataResults && (
          <p>Use the form to search for features...</p>
        )}
        {featureDataResults && !loadingFeatures && <FeatureSearchTable featureDataResults={featureDataResults} />}
        {!loadingFeatures &&
          errorGettingFeatures &&
          (errorGettingFeatures?.status === 404 || errorGettingFeatures?.status === 400
            ? 'No features were found.'
            : 'There was an issue loading the features')}
        {loadingFeatures && <SpinnerComponent size={spinnerSize1} setFullVh={false} />}
      </Container>
    </DefaultTemplate>
  );
};

export default FeatureSearchPage;
