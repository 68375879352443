/**
 * @param  {Array} usersData - user search data from backend - format -> [{userData}]
 * @return  {Array} - array of objects with data in each object needed for a row of the user table
 */
const buildUsersTableRowsData = (usersData = []) =>
  usersData?.map((userData) => {
    return {
      userId: userData?.userId,
      lastName: userData?.lastName,
      firstName: userData?.firstName,
      username: userData?.username,
      email: userData?.email,
      active: userData?.inactive ? 'No' : 'Yes',
    };
  });

export default buildUsersTableRowsData;
