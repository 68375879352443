import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TiArrowBackOutline } from 'react-icons/ti';
import classnames from 'classnames';

import styles from './BackLink.module.scss';

const BackLink = ({ to, linkText }) => {
  const { backLink } = styles;

  return (
    <Link to={to} className={classnames(backLink, 'fw-bold mb-2 d-flex align-items-center')}>
      <TiArrowBackOutline size={18} className="me-1" data-testid="backLinkIcon" />
      {linkText}
    </Link>
  );
};

BackLink.propTypes = {
  to: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

export default BackLink;
