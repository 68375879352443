import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  Alert,
  FormFeedback,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { BiShow } from 'react-icons/bi';

import useFormHandler from '../../hooks/useFormHandler';
import usePasswordApi from '../../hooks/usePasswordApi';
import useAuthApi from '../../hooks/useAuthApi';
import changePasswordModalSchema from './ChangePasswordModal.schema';

const ChangePasswordModal = ({ toggle }) => {
  const { changePassword, changeData, errorChangingPassword, changingPassword } = usePasswordApi();
  const {
    formData,
    handleInputChange,
    handleResetForm,
    formError,
    setFormError,
    isFormEmpty,
    isFormValid,
    validationErrors,
  } = useFormHandler({
    oldPassword: '',
    newPassword: '',
    reenterPassword: '',
  });
  const { userDataFromStore } = useAuthApi();
  const username = userDataFromStore?.username;
  const [showPassword, setShowPassword] = useState(true);

  // If the passwords data is retrieved successfully set the toast, close the modal and reload page
  useEffect(() => {
    if (changeData) {
      toast.success('Password changed');
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [changeData]);

  // If there is an error during the fetch (400 or 404) set the errorm message and remove the
  // current subjects
  useEffect(() => {
    if (errorChangingPassword) {
      const errorStatus = errorChangingPassword?.status;

      if (errorStatus === 404) {
        toast.error('Password change failed. Please check the Old Password entered');
      } else if (errorStatus === 400) {
        toast.error('Invalid input');
      } else if (errorStatus === 403) {
        toast.error('Password with that name already exists');
      }
    }
  }, [errorChangingPassword]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormEmpty()) {
      setFormError('');

      if (formData.newPassword !== formData.reenterPassword) {
        toast.error('Re-entered password does not match!');
      } else if (isFormValid(changePasswordModalSchema)) {
        const refinedFormData = { oldPassword: formData.oldPassword, newPassword: formData.newPassword, username };

        changePassword(refinedFormData);
      }
    }
  };

  const onFormReset = (e) => {
    e.preventDefault();

    handleResetForm();
  };

  return (
    <Modal isOpen toggle={toggle} size="xl" centered className="px-0 px-sm-4">
      <ModalHeader toggle={toggle} className="text-primary fw-bold border-bottom border-primary border-1">
        <span className="primary">Change Password</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit} onReset={onFormReset}>
              <div className="d-flex flex-column align-items-end justify-content-between">
                <Button
                  className="me-2"
                  color="link"
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label="Show"
                  size="24"
                >
                  <BiShow /> Show password
                </Button>
              </div>

              <FormGroup className="no-gutters">
                <Label htmlFor="oldPassword">Old Password:</Label>
                <Input
                  name="oldPassword"
                  id="oldPassword"
                  bsSize="sm"
                  value={formData.oldPassword}
                  onChange={handleInputChange}
                  invalid={!!validationErrors?.oldPassword}
                  type={showPassword ? 'password' : 'text'}
                />
                <FormFeedback>{validationErrors.oldPassword}</FormFeedback>
              </FormGroup>
              <FormGroup className="no-gutters">
                <Label htmlFor="newPassword">New Password:</Label>
                <Input
                  name="newPassword"
                  id="newPassword"
                  bsSize="sm"
                  value={formData.newPassword}
                  onChange={handleInputChange}
                  invalid={!!validationErrors?.newPassword}
                  type={showPassword ? 'password' : 'text'}
                />
                <FormFeedback>{validationErrors.newPassword}</FormFeedback>
              </FormGroup>
              <FormGroup className="no-gutters">
                <Label htmlFor="reenterPassword">Re-enter New Password:</Label>
                <Input
                  name="reenterPassword"
                  id="reenterPassword"
                  bsSize="sm"
                  value={formData.reenterPassword}
                  onChange={handleInputChange}
                  invalid={!!validationErrors?.reenterPassword}
                  type={showPassword ? 'password' : 'text'}
                />
                <FormFeedback>{validationErrors.email}</FormFeedback>
              </FormGroup>
              <Alert color="danger" isOpen={!!formError} toggle={() => setFormError('')} fade={false}>
                {formError}
              </Alert>
              <div className="text-center mt-4">
                <Button type="submit" color="primary" block data-testid="Add">
                  {changingPassword ? 'Changing' : 'Change'}
                </Button>
                <Button type="reset" className="mt-2" color="link">
                  Reset
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default ChangePasswordModal;
