import { useCallback } from 'react';
import { stringify } from 'query-string';

import { API_MAX_PER_PAGE, ELSIE_API } from '../App.constants';
import useFetchWithState from './useFetchWithState';
import useAuthApi from './useAuthApi';

const useP2PInstitutionalApi = () => {
  const { getAuthCookies } = useAuthApi();
  const { encodedTicketFromCookie } = getAuthCookies();
  const {
    amFetchWithState: getInstitutionalArticlesData,
    data: institutionalArticlesData,
    error: errorGettingInstitutionalArticles,
    loading: gettingInstitutionalArticles,
  } = useFetchWithState();
  const {
    amFetchWithState: getInstitutionalFeaturesData,
    data: institutionalFeaturesData,
    error: errorGettingInstitutionalFeatures,
    loading: gettingInstitutionalFeatures,
  } = useFetchWithState();
  const {
    amFetchWithState: p2pInstitutionalArticleData,
    data: p2pArticleData,
    error: errorP2PingArticleGroup,
    loading: p2pingArticleGroup,
  } = useFetchWithState();
  const {
    amFetchWithState: p2pInstitutionalFeatureData,
    data: p2pFeatureData,
    error: errorP2PingFeatureGroup,
    loading: p2pingFeatureGroup,
  } = useFetchWithState();

  const getInstitutionalArticles = useCallback(
    (institutionalArticlesSearchFormData) => {
      getInstitutionalArticlesData(
        `${ELSIE_API}/p2p/institutional/articles?${stringify(institutionalArticlesSearchFormData, {
          arrayFormat: 'comma',
        })}&perPage=${API_MAX_PER_PAGE}`
      );
    },
    [getInstitutionalArticlesData]
  );

  const getInstitutionalFeatures = useCallback(
    (institutionalFeaturesSearchFormData) => {
      getInstitutionalFeaturesData(
        `${ELSIE_API}/p2p/institutional/features?${stringify(institutionalFeaturesSearchFormData, {
          arrayFormat: 'comma',
        })}&perPage=${API_MAX_PER_PAGE}`
      );
    },
    [getInstitutionalFeaturesData]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const p2pInstitutionalArticle = useCallback(
    (requestBody) => {
      p2pInstitutionalArticleData(`${ELSIE_API}/p2p/institutional/batches/0/articles`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [p2pInstitutionalArticleData, encodedTicketFromCookie]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const p2pInstitutionalFeature = useCallback(
    (requestBody) => {
      p2pInstitutionalFeatureData(`${ELSIE_API}/p2p/institutional/batches/0/features`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [encodedTicketFromCookie, p2pInstitutionalFeatureData]
  );

  return {
    getInstitutionalArticles,
    institutionalArticlesData,
    errorGettingInstitutionalArticles,
    gettingInstitutionalArticles,
    getInstitutionalFeatures,
    institutionalFeaturesData,
    errorGettingInstitutionalFeatures,
    gettingInstitutionalFeatures,
    p2pInstitutionalArticle,
    p2pArticleData,
    errorP2PingArticleGroup,
    p2pingArticleGroup,
    p2pInstitutionalFeature,
    p2pFeatureData,
    errorP2PingFeatureGroup,
    p2pingFeatureGroup,
  };
};

export default useP2PInstitutionalApi;
