import { useCallback } from 'react';
import { stringify } from 'query-string';

import { API_MAX_PER_PAGE, ELSIE_API } from '../App.constants';
import useFetchWithState from './useFetchWithState';
import useAuthApi from './useAuthApi';

const useP2PBDSApi = () => {
  const { getAuthCookies } = useAuthApi();
  const { encodedTicketFromCookie } = getAuthCookies();
  const {
    amFetchWithState: getBDSArticlesData,
    data: bdsArticlesData,
    error: errorGettingBDSArticles,
    loading: gettingBDSArticles,
  } = useFetchWithState();
  const {
    amFetchWithState: p2pBDSArtData,
    data: p2pBDSArticleData,
    error: errorP2PingBDSArticleGroup,
    loading: p2pingBDSArticleGroup,
  } = useFetchWithState();

  const getBDSArticles = useCallback(
    (bdsArticlesSearchFormData) => {
      getBDSArticlesData(
        `${ELSIE_API}/p2p/bds/articles?${stringify(bdsArticlesSearchFormData, {
          arrayFormat: 'comma',
        })}&perPage=${API_MAX_PER_PAGE}`
      );
    },
    [getBDSArticlesData]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const p2pBDSArticle = useCallback(
    (requestBody) => {
      p2pBDSArtData(`${ELSIE_API}/p2p/bds/batches/0/articles`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [encodedTicketFromCookie, p2pBDSArtData]
  );

  return {
    getBDSArticles,
    bdsArticlesData,
    errorGettingBDSArticles,
    gettingBDSArticles,
    p2pBDSArticle,
    p2pBDSArticleData,
    errorP2PingBDSArticleGroup,
    p2pingBDSArticleGroup,
  };
};

export default useP2PBDSApi;
