import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { MdDelete } from 'react-icons/md';

import { Routes } from '../../../App.constants';
import TextColumnFilter from '../../../components/TextColumnFilter/TextColumnFilter';

const groupsTableColumns = (handleDeleteGroups) => [
  {
    Header: 'Group ID',
    accessor: 'groupId',
    disableFilters: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { value, row } = e;
      const { original } = row;
      const { groupId } = original;

      return (
        <Link to={Routes.GROUP_DETAIL.toLink({ groupId })} className="p-0">
          {value}
        </Link>
      );
    },
  },
  {
    Header: 'Description',
    accessor: 'description',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Trash',
    accessor: 'groupId',
    id: 'delete-group',
    disableFilters: true,
    disableSortBy: true,
    Cell: (e) => {
      const { row, value } = e;
      const { id } = row;

      return (
        <Button
          className="p-0"
          type="button"
          color="link"
          aria-label="Trash"
          data-testid={`trash-${id}`}
          onClick={() => handleDeleteGroups(value)}
        >
          <MdDelete size={18} />
        </Button>
      );
    },
  },
];

export default groupsTableColumns;
