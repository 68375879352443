import React from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';

import TextColumnFilter from '../../TextColumnFilter/TextColumnFilter';
import { Routes } from '../../../App.constants';

const userSelectModalTableColumns = (handleSelectUser) => [
  {
    accessor: 'userId',
    id: 'select-user',
    disableFilters: true,
    disableSortBy: true,
    Cell: (d) => {
      const { value: userId, row } = d;
      const { id } = row;

      return (
        <Input
          type="radio"
          id={`row-${id}-checkbox`}
          name={`row-${id}-checkbox`}
          key={`row-${id}-checkbox`}
          data-testid={`row-${id}-checkbox`}
          onClick={() => {
            handleSelectUser({
              userId,
            });
          }}
        />
      );
    },
  },
  {
    Header: 'User ID',
    accessor: 'userId',
    disableFilters: true,
    Cell: (e) => {
      const { value } = e;

      return (
        <Link to={Routes.USER_DETAIL.toLink({ userId: value, isUser: 'null' })} className="p-0">
          {value}
        </Link>
      );
    },
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Username',
    accessor: 'username',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Email',
    accessor: 'email',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Active',
    accessor: 'active',
    Filter: TextColumnFilter,
  },
];

export default userSelectModalTableColumns;
