import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import BDSStatusArticles from './BDSStatusArticles/BDSStatusArticles';
import { tabIdAndLabelForBDSStatus } from './BDSStatusTabs.constants';
import styles from './BDSStatusTabs.module.scss';

const BDSStatusTabs = ({ formData }) => {
  const { allTabLinks } = styles;
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <Nav tabs className="mt-2">
        {tabIdAndLabelForBDSStatus.map((tabIdandLabel) => {
          return (
            <NavItem data-testid="alltabs" key={tabIdandLabel.tabId}>
              <NavLink
                className={classnames({ active: activeTab === tabIdandLabel.tabId }, allTabLinks)}
                onClick={() => {
                  toggle(tabIdandLabel.tabId);
                }}
              >
                {tabIdandLabel.label}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <BDSStatusArticles formData={formData} />
        </TabPane>
      </TabContent>
    </>
  );
};

BDSStatusTabs.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default BDSStatusTabs;
