import React from 'react';
import PropTypes from 'prop-types';
import { useSortBy, useTable, usePagination } from 'react-table';
import { Col } from 'reactstrap';

import DefaultTable from '../../../../../components/DefaultTable/DefaultTable';
import DefaultTableControls from '../../../../../components/DefaultTableControls/DefaultTableControls';
import featureVersionsTableColumns from './FeatureDetailVersionsTable.columns';
import { API_MAX_PER_PAGE } from '../../../../../App.constants';

const FeatureDetailVersionsTable = ({ rowsData, featureId }) => {
  const numOfRows = rowsData.length;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data: rowsData,
      columns: featureVersionsTableColumns,
      manualPagination: false,
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between align-items-center">
        {numOfRows < API_MAX_PER_PAGE ? (
          <p>
            {`Versions found for feature ID ${featureId}`}: <strong>{numOfRows}</strong>
          </p>
        ) : (
          <p>
            {`Versions found for feature ID ${featureId}`}: <strong>{API_MAX_PER_PAGE}</strong> (API max), from{' '}
            <strong>{numOfRows}</strong>.
          </p>
        )}
      </div>
      <Col md="2">
        <DefaultTable
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          prepareRow={prepareRow}
        />
      </Col>
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
    </div>
  );
};

FeatureDetailVersionsTable.defaultProps = {
  rowsData: [],
};

FeatureDetailVersionsTable.propTypes = {
  rowsData: PropTypes.arrayOf(PropTypes.shape({})),
  featureId: PropTypes.string.isRequired,
};

export default FeatureDetailVersionsTable;
