import { useCallback } from 'react';
import { stringify } from 'query-string';

import useFetchWithState from './useFetchWithState';
import { BESSIE_API } from '../App.constants';

const useFullTextApi = () => {
  const {
    amFetchWithState: getFullTextData,
    data: fullTextData,
    error: errorGettingFullText,
    loading: loadingFullText,
    abortActiveRequest: abortGettingFullText,
  } = useFetchWithState();

  /**
   * @param  {Object} fullTextSearchFormData - data from fullText search form
   */
  const getFullText = useCallback(
    (fullTextSearchFormData, pageNum, pageSize) => {
      getFullTextData(
        `${BESSIE_API}/search/articles?${stringify(fullTextSearchFormData, {
          arrayFormat: 'comma',
        })}&pageNum=${pageNum}&perPage=${pageSize}`
      );
    },
    [getFullTextData]
  );

  return {
    getFullText,
    fullTextData,
    errorGettingFullText,
    loadingFullText,
    abortGettingFullText,
  };
};

export default useFullTextApi;
