import React from 'react';

import TextColumnFilter from '../../../components/TextColumnFilter/TextColumnFilter';
import DateTableCell from '../../../components/DateTableCell/DateTableCell';

const wipDetailTableColumns = [
  {
    Header: 'Step',
    accessor: 'name',
    Filter: TextColumnFilter,
  },
  {
    Header: 'State',
    accessor: 'state',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Started',
    accessor: 'started',
    disableFilters: true,
    disableSortBy: true,
    Cell: (d) => {
      const { row } = d;
      const { original, id } = row;
      const { started } = original;

      return (
        <DateTableCell id={`row-${id}-started-date`} dateString={started?.dateMonthYear} timeString={started?.time} />
      );
    },
    Filter: TextColumnFilter,
  },
  {
    Header: 'Completed',
    accessor: 'completed',
    disableFilters: true,
    disableSortBy: true,
    Cell: (d) => {
      const { row } = d;
      const { original, id } = row;
      const { completed } = original;

      return (
        <DateTableCell
          id={`row-${id}-completed-date`}
          dateString={completed?.dateMonthYear}
          timeString={completed?.time}
        />
      );
    },
    Filter: TextColumnFilter,
  },
  {
    Header: 'Duration',
    accessor: 'duration',
    Filter: TextColumnFilter,
  },
  {
    Header: 'User',
    accessor: 'user',
    Filter: TextColumnFilter,
  },
];

export default wipDetailTableColumns;
