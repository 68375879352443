import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';

import { BESSIE_API, EDITORIAL_API } from '../../App.constants';
import { setSelectFieldOptions } from '../../store/action-creators';
import useFetchWithState from '../../hooks/useFetchWithState';
import { selectTheme, createRefinedSelectFieldOptions, defineSelectFieldStyles } from '../../utils/select-field-utils';

const SelectField = ({
  name,
  id,
  onChange,
  isMulti,
  endpoint,
  placeholder,
  reduxKey,
  isDisabled,
  valueKey,
  labelKey,
  value,
  stylesToOverride,
  filterMethod,
  sortMethod,
  database,
}) => {
  const dispatch = useDispatch();
  const optionsFromStore = useSelector((state) => state[reduxKey]);
  const [refinedSelectFieldOptions, setRefinedSelectFieldOptions] = useState([]);
  const {
    amFetchWithState: getSelectFieldOptions,
    data: selectFieldOptions,
    error: errorGettingSelectFieldOptions,
  } = useFetchWithState();
  const [noOptionsMessage, setNoOptionsMessage] = useState('No options found.');
  const styles = defineSelectFieldStyles(stylesToOverride);

  // If the options for the select field are not
  // stored already in the Redux store call the endpoint
  // passed via the props to retrieve the options.
  useEffect(() => {
    if (!optionsFromStore || !optionsFromStore.length) {
      if (database === 'EDITORIAL_API') {
        getSelectFieldOptions(`${EDITORIAL_API}${endpoint}`);
      } else getSelectFieldOptions(`${BESSIE_API}${endpoint}`);
    }
  }, [database, endpoint, getSelectFieldOptions, optionsFromStore]);

  // When the data (selectFieldOptions) is returned from the backend
  // store them in the Redux store.
  useEffect(() => {
    if (selectFieldOptions) {
      dispatch(setSelectFieldOptions(reduxKey, selectFieldOptions[reduxKey]));
    }
  }, [dispatch, reduxKey, selectFieldOptions]);

  // When the options in the Redux store are updated refine the options for react-select and
  // set these refined options in the component state.
  useEffect(() => {
    if (optionsFromStore || optionsFromStore?.length > 0) {
      const filteredOptions = filterMethod ? filterMethod(optionsFromStore) : optionsFromStore;
      const selectFieldOptionsRefined = createRefinedSelectFieldOptions(filteredOptions, valueKey, labelKey);

      if (sortMethod) {
        const sortedRefinedSelectFieldOptions = sortMethod(selectFieldOptionsRefined);

        setRefinedSelectFieldOptions(sortedRefinedSelectFieldOptions);
      } else {
        setRefinedSelectFieldOptions(selectFieldOptionsRefined);
      }
    }
  }, [optionsFromStore, valueKey, labelKey, filterMethod, sortMethod]);

  // Handle errors returned from useFetchWithState hook
  useEffect(() => {
    if (errorGettingSelectFieldOptions) {
      setNoOptionsMessage('We are having an issue getting the data. Please try again later.');
    }
  }, [errorGettingSelectFieldOptions]);

  return (
    <Select
      name={name}
      value={value}
      inputId={id}
      onChange={onChange}
      options={refinedSelectFieldOptions}
      isMulti={isMulti}
      theme={selectTheme}
      styles={styles}
      placeholder={placeholder}
      isClearable
      isDisabled={isDisabled}
      noOptionsMessage={() => noOptionsMessage}
      aria-label="select"
    />
  );
};

SelectField.defaultProps = {
  value: null,
  filterMethod: null,
  sortMethod: null,
  stylesToOverride: {},
  database: 'BESSIE_API',
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool.isRequired,
  endpoint: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  reduxKey: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  valueKey: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number, label: PropTypes.string })),
    PropTypes.shape({ value: PropTypes.number, label: PropTypes.string }),
  ]),
  filterMethod: PropTypes.func,
  sortMethod: PropTypes.func,
  stylesToOverride: PropTypes.shape({
    control: PropTypes.shape(),
    option: PropTypes.shape(),
    menu: PropTypes.shape(),
    placeholder: PropTypes.shape(),
  }),
  database: PropTypes.string,
};

export default SelectField;
