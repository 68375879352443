/**
 * @param  {Object} articleDistributionListData - articleDistributionList data from backend - format -> {distributions:[{articleDistributionListData}]}
 * @return  {Array} - array of objects with data in each object needed for a row of the article DistributionList table
 */
const buildArticleDistributionListTableRowsData = (articleDistributionListData) => {
  return articleDistributionListData?.distributions.map((articleDistributionList) => ({
    name: articleDistributionList.name,
    notes: articleDistributionList.notes,
    distributionId: articleDistributionList.distributionId,
  }));
};

export default buildArticleDistributionListTableRowsData;
