import React from 'react';
import PropTypes from 'prop-types';
import { useSortBy, useTable, usePagination } from 'react-table';

import DefaultTable from '../../../components/DefaultTable/DefaultTable';
import DefaultTableControls from '../../../components/DefaultTableControls/DefaultTableControls';
import wipDetailTableColumns from './WIPDetailTable.columns';

const WIPDetailTable = ({ data }) => {
  const numOfRows = data.length;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns: wipDetailTableColumns,
      manualPagination: false,
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      <DefaultTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
      />
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
    </div>
  );
};

WIPDetailTable.defaultProps = {
  data: [],
};

WIPDetailTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      started: PropTypes.shape().isRequired,
      completed: PropTypes.shape().isRequired,
      duration: PropTypes.string.isRequired,
      user: PropTypes.string.isRequired,
    })
  ),
};

export default WIPDetailTable;
