import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, Spinner } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import { Routes } from '../../App.constants';
import useWorkflowApi from '../../hooks/useWorkflowApi';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import MyWorkTable from '../MyWorkPage/MyWorkTable/MyWorkTable';
import buildAllTableRowsData from './AllWorkPage.utils';
import useAuthApi from '../../hooks/useAuthApi';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import AllWorkForm from './AllWorkForm/AllWorkForm';
import { checkForEmptyData } from '../../utils/form-utils';

const AllWorkPage = () => {
  const {
    allWorkData,
    errorGettingAllWork,
    loadingAllWork,
    getAllWork,
    abortGettingAllWork,
    getViewLink,
    viewLinkData,
    deleteWorkflow,
    deleteData,
    errorDeletingWorkflow,
    deletingWorkflow,
    updateTasks,
    updateData,
    errorUpdatingTasks,
    updatingTasks,
    getWorkflowsList,
    workflowsListData,
  } = useWorkflowApi();
  const debugMode = window.location.href.split('/').slice(-1).toString() === 'debug';
  const [errorGettingAllWorkMessage, setErrorGettingAllWorkMessage] = useState('');
  const [tableRowsData, setTableRowsData] = useState([]);
  const [viewLinkUrl, setViewLinkUrl] = useState(0);
  const [editLinkUrl, setEditLinkUrl] = useState(0);
  const [claiming, setClaiming] = useState(false);
  const { userDataFromStore } = useAuthApi();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [post, setPost] = useState(false);
  const history = useHistory();
  const [viewLink, setViewLink] = useState(null);
  const [allWorkSearchRequestData, setAllWorkSearchRequestData] = useState();
  const workflowOptions = workflowsListData?.workflows
    ? workflowsListData.workflows.map((workflow) => ({
        value: workflow.entry.processDefinitionKey,
        label: workflow.entry.name,
      }))
    : [];

  // Get workflow workflows list data on mount
  useEffect(() => {
    getWorkflowsList();
  }, [getWorkflowsList]);

  const onSearch = (formData) => {
    setErrorGettingAllWorkMessage('');

    setAllWorkSearchRequestData(formData);
  };

  const onReset = () => {
    setAllWorkSearchRequestData(null);
    setErrorGettingAllWorkMessage('');
    getAllWork();
  };

  useEffect(() => {
    if (viewLinkUrl) {
      getViewLink(viewLinkUrl);
    }
  }, [getViewLink, viewLinkUrl]);

  useEffect(() => {
    if (editLinkUrl) {
      getViewLink(editLinkUrl);
    }
  }, [getViewLink, editLinkUrl]);

  useEffect(() => {
    if (viewLinkData) {
      setViewLink(viewLinkData);

      if (claiming) {
        setClaiming(false);
        setTimeout(() => history.go(Routes.ALL_WORK_SEARCH.toLink()), 3000);
      }
    }
  }, [claiming, history, viewLinkData]);

  useEffect(() => {
    if (viewLink) {
      if (viewLinkUrl) {
        setViewLinkUrl(0);
        window.open(viewLink.oxygenUrl);
      }

      if (editLinkUrl) {
        setEditLinkUrl(0);

        if (viewLink.node.properties?.['cm:lockType'] === 'WRITE_LOCK') {
          toast.error('Article is already being edited');
        } else window.open(viewLink.oxygenEditUrl);
      }

      setViewLink(null);
    }
  }, [editLinkUrl, viewLink, viewLinkUrl]);

  useEffect(() => {
    getAllWork();
  }, [getAllWork]);

  useEffect(() => {
    if (allWorkSearchRequestData && !checkForEmptyData(allWorkSearchRequestData)) {
      getAllWork(allWorkSearchRequestData);
    }
  }, [allWorkSearchRequestData, getAllWork]);

  useEffect(() => {
    if (allWorkData) {
      if (allWorkData.tasks.length === 0) setErrorGettingAllWorkMessage('No WIPs found');
      else if (allWorkData.page.count !== allWorkData.page.totalItems)
        setErrorGettingAllWorkMessage(`Result is too large. Please refine your query to return fewer WIPs`);
      else setTableRowsData(buildAllTableRowsData(allWorkData.tasks));
    }
  }, [allWorkData, claiming, history]);

  // If there is an error during the fetch (400 or 404) remove the current articles
  // data from state and set the error messaging in state.
  useEffect(() => {
    if (errorGettingAllWork) {
      const errorStatus = errorGettingAllWork?.status;

      if (errorStatus === 404) {
        setErrorGettingAllWorkMessage('No processes were found.');
      } else if (errorStatus === 400) {
        setErrorGettingAllWorkMessage('Bad request');
      } else {
        setErrorGettingAllWorkMessage('There was an issue loading the processes.');
      }
    }
  }, [errorGettingAllWork]);

  // If there is an error during the delete (400 or 404) set the error messaging in state.
  useEffect(() => {
    if (errorDeletingWorkflow) {
      const errorStatus = errorDeletingWorkflow?.status;

      if (errorStatus === 404) {
        setErrorGettingAllWorkMessage('No WIP to delete was found.');
      } else if (errorStatus === 400) {
        setErrorGettingAllWorkMessage('Cannot delete WIP');
      } else {
        setErrorGettingAllWorkMessage('There was an issue deleting the WIP.');
      }
    }
  }, [errorDeletingWorkflow]);

  useEffect(() => {
    if (deleteData) {
      toast.success('WIP Deleted');
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [deleteData]);

  const onConfirmDeleteButtonClick = () => {
    deleteWorkflow(showConfirmModal.id);
    setShowConfirmModal(false);
    history.push(Routes.ALL_WORK_SEARCH.toLink());
  };

  useEffect(() => {
    if (updated) {
      if (post) toast.success('WIP Posted');
      else toast.success('WIP Updated');

      setUpdated(false);

      if (claiming) setEditLinkUrl(claiming);
      else setTimeout(() => history.go(Routes.ALL_WORK_SEARCH.toLink()), 3000);
    }
  }, [claiming, history, post, updated]);

  useEffect(() => {
    if (updateData) {
      setUpdated(true);
    }
  }, [updateData]);

  // If there is an error during the update (400 or 404) set the error messaging in state.
  useEffect(() => {
    if (errorUpdatingTasks) {
      setUpdated(false);

      const errorStatus = errorUpdatingTasks?.status;

      if (errorStatus === 403) {
        toast.error('WIP is claimed by another user');
        setTimeout(() => history.go(Routes.ALL_WORK_SEARCH.toLink()), 3000);
      } else toast.error('Cannot update');
    }
  }, [errorUpdatingTasks, history]);

  const handleActionChange = (selected) => {
    if (selected.label.props?.children[1] === ' Delete') {
      setShowConfirmModal(selected.value);
    } else if (selected.label.props?.children[1] === ' Edit') {
      if (selected.value?.user === 'unclaimed') {
        setClaiming(selected.value?.id);
        updateTasks({ state: 'claimed' }, selected.value.taskId);
      } else setEditLinkUrl(selected.value?.id);
    } else if (selected.label?.props?.children[1] === ' View') {
      setViewLinkUrl(selected.value);
    } else if (selected.label.includes('Promote') || selected.label.includes('Back')) {
      setPost(selected.label === 'Promote to Post');
      updateTasks({ action: { label: selected.label, name: selected.value.name } }, selected.value.id);
    } else if (selected.label === 'Unclaim') {
      updateTasks({ state: 'unclaimed' }, selected.value);
    } else if (selected.label === 'Claim') {
      updateTasks({ state: 'claimed' }, selected.value);
    } else if (selected.label === 'Create Proof') {
      history.push(Routes.PROOF.toLink({ processId: selected.value }));
    }
  };

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: All Work Search</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">All Work</h1>
              {(updatingTasks || deletingWorkflow) && (
                <Button color="info" disabled className="me-2">
                  <Spinner className="me-2" as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{' '}
                  Processing...
                </Button>
              )}
            </div>
            <AllWorkForm
              loading={loadingAllWork}
              abort={abortGettingAllWork}
              onSearch={onSearch}
              onReset={onReset}
              workflowOptions={workflowOptions}
            />
          </Col>
        </Row>
        {allWorkData && !loadingAllWork && !errorGettingAllWorkMessage && (
          <fieldset disabled={updatingTasks}>
            <MyWorkTable
              data={tableRowsData}
              setViewLinkUrl={setViewLinkUrl}
              handleActionChange={
                userDataFromStore.groups.find((group) => group.groupId === 1033) ? handleActionChange : null
              }
              allWork
              debugMode={debugMode}
            />
          </fieldset>
        )}
        {errorGettingAllWorkMessage && !loadingAllWork && errorGettingAllWorkMessage}
        {loadingAllWork && (
          <div className="mt-3">
            <SpinnerComponent size={spinnerSize1} setFullVh={false} />
          </div>
        )}
        {showConfirmModal && (
          <ConfirmModal
            toggle={() => setShowConfirmModal(false)}
            header={`Confirm Delete '${showConfirmModal.wipName}'`}
            confirmButton="Delete"
            message="This WIP will be deleted from the system. All promotion history of the WIP will be deleted. The XML file will no longer be available and all edits will be lost. The associated article will be unlocked for editing. This action can not be undone."
            onConfirmButtonClick={onConfirmDeleteButtonClick}
          />
        )}
      </Container>
    </DefaultTemplate>
  );
};

export default AllWorkPage;
