import { string, object } from 'yup';

const featureCreateSchema = object().shape({
  name: string()
    .required({
      field: 'name',
      errorMessage: 'Required field.',
    })
    .matches(/^[=\s\]\x5B0-9a-zA-Z!#$%&'()+,-]+$/, {
      excludeEmptyString: true,
      message: {
        field: 'name',
        errorMessage: "Only letters, numbers, spaces, and !#$%&'()+,-=[]",
      },
    }),
  templates: object().shape({
    label: string().required({
      field: 'templates',
      errorMessage: 'Required field.',
    }),
  }),
  languages: object().shape({
    label: string().required({
      field: 'languages',
      errorMessage: 'Required field.',
    }),
  }),
});

export default featureCreateSchema;
