import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import UserLoginForm from './UserLoginForm/UserLoginForm';
import styles from './UserLoginModal.module.scss';
import variables from '../../App.module.scss';
import useUserLoginModal from '../../hooks/useUserLoginModal';

const UserLoginModal = () => {
  const { isOpen } = useUserLoginModal();
  const { loginModal, modalBackdrop } = styles;

  return (
    <Modal
      isOpen={isOpen}
      centered
      backdropClassName={modalBackdrop}
      modalClassName={loginModal}
      zIndex={variables.loginModalZIndex}
      size="md"
    >
      <ModalHeader>Login</ModalHeader>
      <ModalBody>
        <UserLoginForm />
      </ModalBody>
    </Modal>
  );
};

export default UserLoginModal;
