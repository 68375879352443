import formatPostDate from '../../../../utils/date-utils';

/**
 * @param  {Object} bdsArticlesData - bds articles data from backend
 * @return  {Array} - array of objects with data in each object needed for a row of the bds articles table
 */
const buildBDSArticlesData = (bdsArticlesData = []) => {
  return (
    bdsArticlesData?.map((data) => ({
      ...data,
      batchId: data.batch.batchId,
      postedDate: formatPostDate(data.postedDate),
      publishedDate: formatPostDate(data.batch.publishEnd),
    })) || []
  );
};

export default buildBDSArticlesData;
