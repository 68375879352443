import { useCallback } from 'react';

import { EDITORIAL_API } from '../App.constants';
import useFetchWithState from './useFetchWithState';

const usePlanningApi = () => {
  const {
    amFetchWithState: getHelpData,
    data: helpData,
    error: errorGettingHelp,
    loading: gettingHelp,
  } = useFetchWithState();
  const {
    amFetchWithState: getOxygenHelpData,
    data: oxygenHelpData,
    error: errorGettingOxygenHelp,
    loading: gettingOxygenHelp,
  } = useFetchWithState();
  const {
    amFetchWithState: getSpecialCharactersData,
    data: specialCharactersData,
    error: errorGettingSpecialCharacters,
    loading: gettingSpecialCharacters,
  } = useFetchWithState();
  const {
    amFetchWithState: getWorkflowDiagramsData,
    data: workflowDiagramsData,
    error: errorGettingWorkflowDiagrams,
    loading: gettingWorkflowDiagrams,
  } = useFetchWithState();

  const getHelp = useCallback(() => {
    getHelpData(`${EDITORIAL_API}/bsc/docs/userguide-am.htm`);
  }, [getHelpData]);

  const getOxygenHelp = useCallback(() => {
    getOxygenHelpData(`${EDITORIAL_API}/bsc/docs/userguide-oxygen.xhtml`);
  }, [getOxygenHelpData]);

  const getSpecialCharacters = useCallback(() => {
    getSpecialCharactersData(`${EDITORIAL_API}/bsc/docs/special-characters-list.htm`);
  }, [getSpecialCharactersData]);

  const getWorkflowDiagrams = useCallback(() => {
    getWorkflowDiagramsData(`${EDITORIAL_API}/bsc/docs/workflow-diagrams.htm`);
  }, [getWorkflowDiagramsData]);

  return {
    getHelp,
    helpData,
    errorGettingHelp,
    gettingHelp,
    getOxygenHelp,
    oxygenHelpData,
    errorGettingOxygenHelp,
    gettingOxygenHelp,
    getSpecialCharacters,
    specialCharactersData,
    errorGettingSpecialCharacters,
    gettingSpecialCharacters,
    getWorkflowDiagrams,
    workflowDiagramsData,
    errorGettingWorkflowDiagrams,
    gettingWorkflowDiagrams,
  };
};

export default usePlanningApi;
