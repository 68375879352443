import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';

import useUserApi from '../../hooks/useUserApi';
import SpinnerComponent from '../SpinnerComponent/SpinnerComponent';
import { spinnerSize2 } from '../SpinnerComponent/SpinnerComponent.constants';
import UserSelectModalTable from './UserSelectModalTable/UserSelectModalTable';
import buildUsersTableRowsData from '../../pages/UsersPage/UsersPage.utils';

const UserSelectModal = ({ toggle, handleSelectUser }) => {
  const { getUsers, usersData, errorGettingUsers, gettingUsers } = useUserApi();
  const [errorGettingUsersMessage, setErrorGettingUsersMessage] = useState('');
  const [tableRowsData, setTableRowsData] = useState([]);

  // get users on mount
  useEffect(() => {
    getUsers(false);
  }, [getUsers]);

  useEffect(() => {
    if (usersData) {
      setTableRowsData(buildUsersTableRowsData(usersData?.users));
    }
  }, [usersData]);

  useEffect(() => {
    if (errorGettingUsers) {
      const errorStatus = errorGettingUsers?.status;

      if (errorStatus === 404) {
        setErrorGettingUsersMessage('No users found.');
      } else if (errorStatus === 400) {
        setErrorGettingUsersMessage('Bad request');
      } else {
        setErrorGettingUsersMessage('There was an issue loading the users.');
      }
    }
  }, [errorGettingUsers]);

  return (
    <Modal isOpen toggle={toggle} size="xl" centered className="px-0 px-sm-4">
      <ModalHeader toggle={toggle} className="text-primary fw-bold border-bottom border-primary border-1">
        <span className="primary">User Select</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {tableRowsData.length !== 0 && !gettingUsers && (
              <UserSelectModalTable handleSelectUser={handleSelectUser} rowsData={tableRowsData} />
            )}
            {errorGettingUsersMessage && !gettingUsers && errorGettingUsersMessage}
            {gettingUsers && <SpinnerComponent size={spinnerSize2} setFullVh={false} />}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

UserSelectModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  handleSelectUser: PropTypes.func.isRequired,
};

export default UserSelectModal;
