import { useCallback } from 'react';

import { BESSIE_API, API_MAX_PER_PAGE } from '../App.constants';
import useFetchWithState from './useFetchWithState';
import useAuthApi from './useAuthApi';

const usePlanningApi = () => {
  const { getAuthCookies } = useAuthApi();
  const { encodedTicketFromCookie } = getAuthCookies();

  const {
    amFetchWithState: getGroupsData,
    data: groupsData,
    error: errorGettingGroups,
    loading: gettingGroups,
  } = useFetchWithState();
  const {
    amFetchWithState: deleteGroupData,
    data: deleteData,
    error: errorDeletingGroup,
    loading: deletingGroup,
  } = useFetchWithState();
  const {
    amFetchWithState: addGroupData,
    data: addData,
    error: errorAddingGroup,
    loading: addingGroup,
  } = useFetchWithState();
  const {
    amFetchWithState: getGroupData,
    data: groupData,
    error: errorGettingGroup,
    loading: loadingGroup,
  } = useFetchWithState();
  const {
    amFetchWithState: editGroupData,
    data: editData,
    error: errorEditingGroup,
    loading: editingGroup,
  } = useFetchWithState();
  const {
    amFetchWithState: deleteUserFromGroupData,
    data: deleteUserData,
    error: errorDeletingUserGroup,
    loading: deletingUserGroup,
  } = useFetchWithState();
  const {
    amFetchWithState: addUserToGroupData,
    data: addUserData,
    error: errorAddingUserGroup,
    loading: addingUserGroup,
  } = useFetchWithState();

  const getGroups = useCallback(() => {
    getGroupsData(`${BESSIE_API}/groups?perPage=${API_MAX_PER_PAGE}`);
  }, [getGroupsData]);

  /**
   * @param  {string} groupId - group ID which has to be deleted
   */
  const deleteGroup = useCallback(
    (groupId) => {
      deleteGroupData(`${BESSIE_API}/groups/${groupId}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [deleteGroupData, encodedTicketFromCookie]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const addGroup = useCallback(
    (requestBody) => {
      addGroupData(`${BESSIE_API}/groups`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [addGroupData, encodedTicketFromCookie]
  );

  /**
   * @param  {string} groupId - group ID
   */
  const getGroup = useCallback(
    (groupId) => {
      getGroupData(`${BESSIE_API}/groups/${groupId}`);
    },
    [getGroupData]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   * @param  {string} groupId - group ID
   */
  const editGroup = useCallback(
    (requestBody, groupId) => {
      editGroupData(`${BESSIE_API}/groups/${groupId}`, {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [editGroupData, encodedTicketFromCookie]
  );

  /**
   * @param  {string} userId - user ID which has to be deleted
   * @param  {string} groupId - group ID to which it belongs
   */
  const deleteUserFromGroup = useCallback(
    (groupId, userId) => {
      deleteUserFromGroupData(`${BESSIE_API}/users/${userId}/groups/${groupId}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [deleteUserFromGroupData, encodedTicketFromCookie]
  );

  /**
   * @param  {string} userId - user ID which has to be added
   * @param  {string} groupId - group ID to which it belongs
   */
  const addUserToGroup = useCallback(
    (groupId, userId) => {
      addUserToGroupData(`${BESSIE_API}/users/${userId}/groups/${groupId}`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [addUserToGroupData, encodedTicketFromCookie]
  );

  return {
    getGroups,
    groupsData,
    errorGettingGroups,
    gettingGroups,
    deleteGroup,
    deleteData,
    errorDeletingGroup,
    deletingGroup,
    addGroup,
    addData,
    errorAddingGroup,
    addingGroup,
    getGroup,
    groupData,
    errorGettingGroup,
    loadingGroup,
    editGroup,
    editData,
    errorEditingGroup,
    editingGroup,
    deleteUserFromGroup,
    deleteUserData,
    errorDeletingUserGroup,
    deletingUserGroup,
    addUserToGroup,
    addUserData,
    errorAddingUserGroup,
    addingUserGroup,
  };
};

export default usePlanningApi;
