import { useCallback } from 'react';
import { stringify } from 'query-string';

import useFetchWithState from './useFetchWithState';
import { EDITORIAL_API } from '../App.constants';
import useAuthApi from './useAuthApi';

const useWorkflowApi = () => {
  const {
    amFetchWithState: getWorkflowsData,
    data: workflowsData,
    error: errorGettingWorkflows,
    loading: loadingWorkflows,
    abortActiveRequest: abortGettingWorkflows,
  } = useFetchWithState();
  const {
    amFetchWithState: getWorkflowData,
    data: workflowData,
    error: errorGettingWorkflow,
    loading: loadingWorkflow,
  } = useFetchWithState();
  const {
    amFetchWithState: getViewLinkData,
    data: viewLinkData,
    error: errorGettingViewLink,
    loading: loadingViewLink,
  } = useFetchWithState();
  const {
    amFetchWithState: getWorkflowsListData,
    data: workflowsListData,
    error: errorGettingWorkflowsList,
    loading: loadingWorkflowsList,
  } = useFetchWithState();
  const {
    amFetchWithState: getProjectsListData,
    data: projectsListData,
    error: errorGettingProjectsList,
    loading: loadingProjectsList,
  } = useFetchWithState();
  const {
    amFetchWithState: addNewWorkflow,
    data: addWorkflowData,
    error: errorAddingWorkflow,
    loading: addingWorkflow,
  } = useFetchWithState();
  const {
    amFetchWithState: getMyWorkData,
    data: myWorkData,
    error: errorGettingMyWork,
    loading: loadingMyWork,
  } = useFetchWithState();
  const {
    amFetchWithState: deleteWorkflowData,
    data: deleteData,
    error: errorDeletingWorkflow,
    loading: deletingWorkflow,
  } = useFetchWithState();
  const {
    amFetchWithState: getTasksData,
    data: tasksData,
    error: errorGettingTasks,
    loading: gettingTasks,
  } = useFetchWithState();
  const {
    amFetchWithState: updateTasksData,
    data: updateData,
    error: errorUpdatingTasks,
    loading: updatingTasks,
  } = useFetchWithState();
  const {
    amFetchWithState: getAllWorkData,
    data: allWorkData,
    error: errorGettingAllWork,
    loading: loadingAllWork,
    abortActiveRequest: abortGettingAllWork,
  } = useFetchWithState();
  const {
    amFetchWithState: getViewLinkValidData,
    data: viewLinkValidData,
    error: errorGettingViewLinkValid,
    loading: loadingViewLinkValid,
  } = useFetchWithState();
  const {
    amFetchWithState: getCreateProofData,
    data: createProofData,
    error: errorGettingCreateProof,
    loading: loadingCreateProof,
  } = useFetchWithState();
  const { getAuthCookies, userDataFromStore } = useAuthApi();
  const { encodedTicketFromCookie } = getAuthCookies();
  const username = userDataFromStore?.username;

  /**
   * @param  {Object} workflowsSearchFormData - data from workflow search form
   */
  const getWorkflows = useCallback(
    (workflowsSearchFormData) => {
      getWorkflowsData(
        `${EDITORIAL_API}/workflow/processes?${stringify(workflowsSearchFormData, {
          arrayFormat: 'comma',
        })}`,
        {
          headers: {
            Authorization: encodedTicketFromCookie,
          },
        }
      );
    },
    [encodedTicketFromCookie, getWorkflowsData]
  );

  /**
   * @param  {string} processId - process ID
   */
  const getWorkflow = useCallback(
    (processId) => {
      getWorkflowData(`${EDITORIAL_API}/workflow/processes/${processId}`, {
        headers: {
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [encodedTicketFromCookie, getWorkflowData]
  );

  /**
   * @param  {string} processId - process ID
   */
  const getViewLink = useCallback(
    (processId) => {
      getViewLinkData(`${EDITORIAL_API}/workflow/processes/${processId}/file?username=${username}`, {
        headers: {
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [encodedTicketFromCookie, getViewLinkData, username]
  );

  /**
   * @param  {string} processId - process ID
   */
  const getViewLinkValid = useCallback(
    (processId) => {
      getViewLinkValidData(`${EDITORIAL_API}/workflow/processes/${processId}/file?username=${username}&validate=true`, {
        headers: {
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [encodedTicketFromCookie, getViewLinkValidData, username]
  );

  const getWorkflowsList = useCallback(
    (formData) => {
      getWorkflowsListData(
        `${EDITORIAL_API}/workflow/workflows?${stringify(formData, {
          arrayFormat: 'comma',
        })}`,
        {
          headers: {
            Authorization: encodedTicketFromCookie,
          },
        }
      );
    },
    [encodedTicketFromCookie, getWorkflowsListData]
  );

  const getProjectsList = useCallback(() => {
    getProjectsListData(`${EDITORIAL_API}/workflow/projects`, {
      headers: {
        Authorization: encodedTicketFromCookie,
      },
    });
  }, [encodedTicketFromCookie, getProjectsListData]);

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const addWorkflow = useCallback(
    (requestBody) => {
      addNewWorkflow(`${EDITORIAL_API}/workflow/processes`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [addNewWorkflow, encodedTicketFromCookie]
  );

  const getMyWork = useCallback(() => {
    getMyWorkData(`${EDITORIAL_API}/workflow/tasks?username=${username}`, {
      headers: {
        Authorization: encodedTicketFromCookie,
      },
    });
  }, [encodedTicketFromCookie, getMyWorkData, username]);

  const getAllWork = useCallback(
    (allWorkSearchFormData) => {
      getAllWorkData(
        `${EDITORIAL_API}/workflow/tasks?${stringify(allWorkSearchFormData, {
          arrayFormat: 'comma',
        })}`,
        {
          headers: {
            Authorization: encodedTicketFromCookie,
          },
        }
      );
    },
    [encodedTicketFromCookie, getAllWorkData]
  );

  /**
   * @param  {string} processId - process ID which has to be deleted
   */
  const deleteWorkflow = useCallback(
    (processId) => {
      deleteWorkflowData(`${EDITORIAL_API}/workflow/processes/${processId}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [deleteWorkflowData, encodedTicketFromCookie]
  );

  /**
   * @param  {string} taskId - task ID
   */
  const getTasks = useCallback(
    (taskId) => {
      getTasksData(`${EDITORIAL_API}/workflow/tasks/${taskId}`, {
        headers: {
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [encodedTicketFromCookie, getTasksData]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   * @param  {string} taskId - task ID
   */
  const updateTasks = useCallback(
    (requestBody, taskId) => {
      updateTasksData(`${EDITORIAL_API}/workflow/tasks/${taskId}`, {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [encodedTicketFromCookie, updateTasksData]
  );

  /**
   * @param  {string} processId - process ID
   */
  const getCreateProof = useCallback(
    (processId) => {
      getCreateProofData(`${EDITORIAL_API}/workflow/processes/${processId}/proof`, {
        headers: {
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [encodedTicketFromCookie, getCreateProofData]
  );

  return {
    getWorkflows,
    workflowsData,
    errorGettingWorkflows,
    loadingWorkflows,
    abortGettingWorkflows,
    getWorkflow,
    workflowData,
    errorGettingWorkflow,
    loadingWorkflow,
    getViewLink,
    viewLinkData,
    errorGettingViewLink,
    loadingViewLink,
    getWorkflowsList,
    workflowsListData,
    errorGettingWorkflowsList,
    loadingWorkflowsList,
    getProjectsList,
    projectsListData,
    errorGettingProjectsList,
    loadingProjectsList,
    addWorkflow,
    addWorkflowData,
    errorAddingWorkflow,
    addingWorkflow,
    getMyWork,
    myWorkData,
    errorGettingMyWork,
    loadingMyWork,
    deleteWorkflow,
    deleteData,
    errorDeletingWorkflow,
    deletingWorkflow,
    getTasks,
    tasksData,
    errorGettingTasks,
    gettingTasks,
    updateTasks,
    updateData,
    errorUpdatingTasks,
    updatingTasks,
    allWorkData,
    errorGettingAllWork,
    loadingAllWork,
    getAllWork,
    abortGettingAllWork,
    getViewLinkValid,
    viewLinkValidData,
    errorGettingViewLinkValid,
    loadingViewLinkValid,
    getCreateProof,
    createProofData,
    errorGettingCreateProof,
    loadingCreateProof,
  };
};

export default useWorkflowApi;
