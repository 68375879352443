import React from 'react';
import { GoLinkExternal } from 'react-icons/go';
import { Link } from 'react-router-dom';

import { Routes } from '../../../../../App.constants';
import DateTableCell from '../../../../../components/DateTableCell/DateTableCell';

const consumerStatusTopicsPageColumns = [
  {
    Header: 'Topic ID',
    accessor: 'topicId',
    disableFilters: true,
  },
  {
    Header: 'Topic Title',
    accessor: 'topicTitle',
    disableFilters: true,
    Cell: (d) => {
      const { value, row } = d;
      const { original } = row;
      const { topicURL } = original;

      if (topicURL) {
        return (
          <span className="d-flex flex-wrap">
            <a
              color="link"
              href={topicURL}
              rel="noopener noreferrer"
              target="_blank"
              className="d-flex justify-content-center align-items-center p-0 mb-2 fw-bold me-1"
            >
              <GoLinkExternal />
            </a>
            {value}
          </span>
        );
      }

      return null;
    },
  },
  {
    Header: 'State',
    accessor: 'state',
    disableFilters: true,
  },
  {
    Header: 'Type',
    accessor: 'trigger',
    disableFilters: true,
  },
  {
    Header: 'Posted Date',
    accessor: 'postedDate',
    disableFilters: true,
    Cell: (d) => {
      const { value, row } = d;

      const { id } = row;

      return <DateTableCell id={`row${id}postedDate`} dateString={value?.dateMonthYear} timeString={value?.time} />;
    },
  },
  {
    Header: 'Published Time',
    accessor: 'publishedDate',
    disableFilters: true,
    Cell: (d) => {
      const { value } = d;

      return value?.time;
    },
  },
  {
    Header: 'Article ID',
    disableFilters: true,
    accessor: 'articleId',
    Cell: (e) => {
      const { value } = e;

      return <Link to={Routes.ARTICLE_DETAIL.toLink({ articleId: value || 'null' })}>{value}</Link>;
    },
  },
  {
    Header: 'Article Name',
    accessor: 'articleName',
    disableFilters: true,
    Cell: (e) => {
      const { value, row } = e;
      const { original } = row;
      const { articleId } = original;

      return (
        <Link to={Routes.ARTICLE_VIEW.toLink({ articleId: articleId || 'null', versionId: 'null' })}>{value}</Link>
      );
    },
  },
  {
    Header: 'Article Type',
    accessor: 'articleType',
    disableFilters: true,
  },
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    disableFilters: true,
  },
];

export default consumerStatusTopicsPageColumns;
