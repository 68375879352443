import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import { EDITORIAL_API } from '../../App.constants';

const StylebookPage = () => (
  <DefaultTemplate>
    <Helmet>
      <title>AM: Stylebook</title>
    </Helmet>
    <Container fluid>
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
            <h1 className="text-primary fw-bold">Stylebook</h1>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <object data={`${EDITORIAL_API}/bsc/docs/stylebook.pdf`} type="application/pdf" width="100%" height="400%">
            <p>
              Alternative text - include a link <a href={`${EDITORIAL_API}/bsc/docs/stylebook.pdf`}>to the PDF!</a>
            </p>
          </object>
        </Col>
      </Row>
    </Container>
  </DefaultTemplate>
);

export default StylebookPage;
