import variables from '../../../App.module.scss';

export const FEATURE_CREATE_FORM_INITIAL_STATE = {
  name: '',
  products: null,
  languages: null,
  tiers: null,
  urlTitle: '',
  templates: null,
};

// Select field custom styles
export const CreateFeatureSelectCustomStyles = {
  control: {
    minWidth: '20rem',
    maxWidth: '20rem',
  },
  menu: {
    minWidth: '20rem',
    maxWidth: '20rem',
  },
};

// Select field custom invalid styles
export const CreateFeatureSelectInvalidCustomStyles = {
  control: {
    borderColor: variables.red,
    minWidth: '20rem',
    maxWidth: '20rem',
  },
  menu: {
    minWidth: '20rem',
    maxWidth: '20rem',
  },
};
