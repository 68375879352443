import formatPostDate from '../../../../utils/date-utils';

/**
 * @param  {Object} institutionalFeaturesData - institutional features data from backend
 * @return  {Array} - array of objects with data in each object needed for a row of the institutional feature table
 */
const buildInstitutionalFeaturesData = (institutionalFeaturesData = []) => {
  return (
    institutionalFeaturesData?.map((data) => ({
      ...data,
      postedDate: formatPostDate(data.postedDate),
      publishedDate: formatPostDate(data.batch.publishEnd),
      batchId: data.batch.batchId,
    })) || []
  );
};

export default buildInstitutionalFeaturesData;
