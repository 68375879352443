import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';

import useGroupApi from '../../hooks/useGroupApi';
import SpinnerComponent from '../SpinnerComponent/SpinnerComponent';
import { spinnerSize2 } from '../SpinnerComponent/SpinnerComponent.constants';
import GroupSelectModalTable from './GroupSelectModalTable/GroupSelectModalTable';

const GroupSelectModal = ({ toggle, handleSelectGroup }) => {
  const { getGroups, groupsData, errorGettingGroups, gettingGroups } = useGroupApi();
  const [errorGettingGroupsMessage, setErrorGettingGroupsMessage] = useState('');
  const [tableRowsData, setTableRowsData] = useState([]);

  // get groups on mount
  useEffect(() => {
    getGroups(false);
  }, [getGroups]);

  useEffect(() => {
    if (groupsData) {
      setTableRowsData(groupsData?.groups);
    }
  }, [groupsData]);

  useEffect(() => {
    if (errorGettingGroups) {
      const errorStatus = errorGettingGroups?.status;

      if (errorStatus === 404) {
        setErrorGettingGroupsMessage('No groups found.');
      } else if (errorStatus === 400) {
        setErrorGettingGroupsMessage('Bad request');
      } else {
        setErrorGettingGroupsMessage('There was an issue loading the processes.');
      }
    }
  }, [errorGettingGroups]);

  return (
    <Modal isOpen toggle={toggle} size="xl" centered className="px-0 px-sm-4">
      <ModalHeader toggle={toggle} className="text-primary fw-bold border-bottom border-primary border-1">
        <span className="primary">Group Select</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {tableRowsData.length !== 0 && !gettingGroups && (
              <GroupSelectModalTable handleSelectGroup={handleSelectGroup} rowsData={tableRowsData} />
            )}
            {errorGettingGroupsMessage && !gettingGroups && errorGettingGroupsMessage}
            {gettingGroups && <SpinnerComponent size={spinnerSize2} setFullVh={false} />}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

GroupSelectModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  handleSelectGroup: PropTypes.func.isRequired,
};

export default GroupSelectModal;
