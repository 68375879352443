import React, { useEffect, useState } from 'react';
import { Input, FormGroup, Container, Row, Col, Label } from 'reactstrap';
import { Helmet } from 'react-helmet';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import ArticleDistributionListTable from './ArticleDistributionListTable/ArticleDistributionListTable';
import buildArticleDistributionListTableRowsData from './ArticleDistributionListPage.utils';
import useArticleDistributionListApi from '../../hooks/useArticleDistributionListApi';

const ArticleDistributionListPage = () => {
  const {
    getArticleDistributionLists,
    articleDistributionListsData,
    errorGettingArticleDistributionLists,
    loadingArticleDistributionLists,
  } = useArticleDistributionListApi();
  const [articleDistributionListDataResults, setArticleDistributionListDataResults] = useState(null);
  const [errorGettingArticleDistributionListsMessage, setErrorGettingArticleDistributionListsMessage] = useState('');
  const [showInactive, setShowInactive] = useState(false);

  useEffect(() => {
    setArticleDistributionListDataResults(null);
    getArticleDistributionLists(showInactive);
  }, [getArticleDistributionLists, showInactive]);

  // Set the results to distribution data if it exists
  useEffect(() => {
    if (articleDistributionListsData) {
      setArticleDistributionListDataResults(buildArticleDistributionListTableRowsData(articleDistributionListsData));
    }
  }, [articleDistributionListsData]);

  // If there is an error during the fetch (400 or 404) remove the current distributions
  // data from state and set the error messaging in state.
  useEffect(() => {
    if (errorGettingArticleDistributionLists) {
      setArticleDistributionListDataResults(null);

      const errorStatus = errorGettingArticleDistributionLists?.status;

      if (errorStatus === 404 || errorStatus === 400) {
        setErrorGettingArticleDistributionListsMessage('No article distributions were found.');
      } else if (errorGettingArticleDistributionLists.message === 'The user aborted a request.') {
        setErrorGettingArticleDistributionListsMessage('');
      } else setErrorGettingArticleDistributionListsMessage('There was an issue loading the article distribution.');
    }
  }, [errorGettingArticleDistributionLists]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Distribution List</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="mb-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Distribution List</h1>
            </div>
          </Col>
        </Row>

        <div className="d-flex justify-content-center align-items-center">
          <FormGroup switch>
            <Input
              type="switch"
              id="showInactiveSwitch"
              name="showInactiveSwitch"
              checked={showInactive}
              onChange={() => setShowInactive(!showInactive)}
            />
            <Label check for="showInactiveSwitch">
              Show Inactive
            </Label>
          </FormGroup>
        </div>

        {articleDistributionListDataResults && !loadingArticleDistributionLists && (
          <ArticleDistributionListTable articleDistributionListDataResults={articleDistributionListDataResults} />
        )}
        {errorGettingArticleDistributionListsMessage && !loadingArticleDistributionLists && (
          <p>{errorGettingArticleDistributionListsMessage}</p>
        )}
        {loadingArticleDistributionLists && <SpinnerComponent size={spinnerSize1} setFullVh={false} />}
      </Container>
    </DefaultTemplate>
  );
};

export default ArticleDistributionListPage;
