import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';

import useArticleApi from '../../hooks/useArticleApi';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import HelpIcon from '../../components/HelpIcon/HelpIcon';
import RecentArticlesTable from './RecentArticlesTable/RecentArticlesTable';
import refineRecentArticles from './RecentArticlesPage.utils';

const RecentArticlesPage = () => {
  const { getRecentArticles, recentArticlesData, errorGettingRecentArticles, loadingRecentArticles } = useArticleApi();
  const [rowsData, setRowsData] = useState([]);

  useEffect(() => {
    getRecentArticles();
  }, [getRecentArticles]);

  useEffect(() => {
    if (recentArticlesData) {
      setRowsData(refineRecentArticles(recentArticlesData?.articleVersions));
    }
  }, [recentArticlesData]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Recently Updated Articles</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Recently Updated Articles</h1>
              <HelpIcon link="_Article_Search" id="recentArticles" />
            </div>
          </Col>
        </Row>

        {recentArticlesData && !loadingRecentArticles && <RecentArticlesTable data={rowsData} />}
        {errorGettingRecentArticles && !loadingRecentArticles && <p> No new articles in the last 7 days</p>}
        {loadingRecentArticles && <SpinnerComponent size={spinnerSize1} setFullVh={false} />}
      </Container>
    </DefaultTemplate>
  );
};

export default RecentArticlesPage;
