export const ARTICLE_SEARCH_FORM_CHECKBOX_OPTIONS = [
  { id: 'stable', label: 'Stable only' },
  { id: 'inwork', label: 'In work only' },
  { id: 'deleted', label: 'Include deletes' },
  { id: 'unillustrated', label: 'Unillustrated only' },
];

export const ARTICLE_SEARCH_FORM_EMPTY_STATE = {
  articleId: '',
  name: '',
  clusters: [],
  corpus: null,
  deleted: false,
  unillustrated: false,
  inwork: false,
  stable: false,
  topicId: '',
  articleTypes: [],
  subjectName: '',
};
