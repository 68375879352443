import React, { lazy, Suspense } from 'react';

const LazyUserLoginPage = lazy(() => import('./UserLoginPage'));

const UserLoginPage = (props) => (
  <Suspense fallback={null}>
    <LazyUserLoginPage {...props} />
  </Suspense>
);

export default UserLoginPage;
