import React from 'react';
import { Input } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Routes } from '../../../App.constants';
import TextColumnFilter from '../../TextColumnFilter/TextColumnFilter';

const articleSearchModalTableColumns = (handleSelectArticle) => [
  {
    accessor: 'articleId',
    id: 'select-article',
    disableFilters: true,
    disableSortBy: true,
    Cell: (d) => {
      const { value: articleId, row } = d;
      const { original, id } = row;
      const { articleName } = original;

      return (
        <Input
          type="radio"
          id={`row-${id}-checkbox`}
          name={`row-${id}-checkbox`}
          key={`row-${id}-checkbox`}
          data-testid={`row-${id}-checkbox`}
          onClick={() => {
            handleSelectArticle({
              articleId,
              articleName,
            });
          }}
        />
      );
    },
  },
  {
    Header: 'ID',
    accessor: 'articleId',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Name',
    accessor: 'articleName',
    Cell: (d) => {
      const { value, row } = d;
      const { original } = row;
      const { articleId } = original;

      return <Link to={Routes.ARTICLE_VIEW.toLink({ articleId, versionId: 'null' })}>{value}</Link>;
    },
    Filter: TextColumnFilter,
  },
  {
    Header: 'Type',
    accessor: 'articleTypeName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Stable',
    accessor: 'stableValueToDisplay',
    disableFilters: true,
  },
];

export default articleSearchModalTableColumns;
