import React, { lazy, Suspense } from 'react';

const LazySignUpPage = lazy(() => import('./TestPage'));

const TestPage = (props) => (
  <Suspense fallback={null}>
    <LazySignUpPage {...props} />
  </Suspense>
);

export default TestPage;
