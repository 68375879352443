import React, { useEffect, useState, useRef } from 'react';
import { FormGroup, Label, Input, Button, FormFeedback, Col, Form } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { diff } from 'deep-object-diff';

import useFormHandler from '../../../../hooks/useFormHandler';
import useUserLoginModal from '../../../../hooks/useUserLoginModal';
import useAuthApi from '../../../../hooks/useAuthApi';
import { BESSIE_API } from '../../../../App.constants';
import groupEditSchema from './GroupMetadataForm.schema';

const GroupDetailMetadataForm = ({ groupMetadata, onUpdate, error, onCancel, groupId, loading }) => {
  const { formData, validationErrors, setValidationErrors, handleInputChange, isFormValid } =
    useFormHandler(groupMetadata);
  const initialFormDataRef = useRef(groupMetadata);
  const [refinedFormDataForRequest, setRefinedFormDataForRequest] = useState(null);
  const { userDataFromStore } = useAuthApi();

  const username = userDataFromStore?.username;

  useUserLoginModal({
    onModalClose: () => {
      onUpdate(refinedFormDataForRequest, groupId);
    },
    onCloseActionId: `PATCH ${BESSIE_API}/group/${groupId}`,
  });

  // to handle the different error statuses and respond accordingly, 403 indicates a duplicate
  useEffect(() => {
    if (error) {
      const errorStatus = error?.status;

      if (errorStatus === 403) {
        setValidationErrors({ name: 'Group with that name already exists' });
      } else if (errorStatus !== 401) {
        toast.error('Group cannot be updated');
      }
    }
  }, [error, setValidationErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid(groupEditSchema)) {
      setRefinedFormDataForRequest({ ...formData, username });

      const refinedFormData = diff(initialFormDataRef.current, formData);

      if (!Object.keys(refinedFormData).length) {
        toast.error('No changes detected');
      } else {
        onUpdate({ ...formData, username }, groupId);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <Label htmlFor="name" className="fw-bold text-start text-md-end pe-0" md={4}>
          Group Name:
        </Label>
        <Col md={8}>
          <Input
            className="me-3"
            name="name"
            id="name"
            value={formData.name}
            invalid={!!validationErrors?.name}
            onChange={handleInputChange}
            maxLength="30"
          />
          <FormFeedback>{validationErrors.name}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label htmlFor="description" className="fw-bold text-start text-md-end pe-0" md={4}>
          Description:
        </Label>
        <Col md={8}>
          <Input
            className="me-3"
            name="description"
            id="description"
            value={formData.description}
            invalid={!!validationErrors?.description}
            onChange={handleInputChange}
            type="textarea"
          />
          <FormFeedback>{validationErrors.description}</FormFeedback>
        </Col>
      </FormGroup>

      <div className="d-flex justify-content-center align-items-center">
        <Button type="submit" color="success" disabled={loading} className="text-white">
          {loading ? 'Saving...' : 'Save'}
        </Button>
        <Button type="reset" color="link" disabled={loading} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Form>
  );
};

GroupDetailMetadataForm.defaultProps = {
  groupMetadata: null,
  error: null,
};

GroupDetailMetadataForm.propTypes = {
  groupMetadata: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  error: PropTypes.shape(),
  groupId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default GroupDetailMetadataForm;
