import React from 'react';
import { Link } from 'react-router-dom';
import { GoLinkExternal } from 'react-icons/go';

import { Routes } from '../../../../../App.constants';
import DateTableCell from '../../../../../components/DateTableCell/DateTableCell';

const institutionalStatusFeaturesPageColumns = [
  {
    Header: 'Feature ID',
    accessor: 'featureId',
    disableFilters: true,
    Cell: (e) => {
      const { value } = e;

      return <Link to={Routes.FEATURE_DETAIL.toLink({ featureId: value })}>{value}</Link>;
    },
  },
  {
    Header: 'Feature Name',
    accessor: 'featureName',
    disableFilters: true,
    Cell: (d) => {
      const { value, row } = d;
      const { original } = row;
      const { featureURL } = original;

      if (featureURL) {
        return (
          <span className="d-flex flex-wrap">
            <a
              color="link"
              href={featureURL}
              rel="noopener noreferrer"
              target="_blank"
              className="d-flex justify-content-center align-items-center p-0 mb-2 fw-bold me-1"
            >
              <GoLinkExternal />
            </a>
            {value}
          </span>
        );
      }

      return null;
    },
  },
  {
    Header: 'State',
    accessor: 'state',
    disableFilters: true,
  },
  {
    Header: 'Type',
    accessor: 'featureType',
    disableFilters: true,
  },
  {
    Header: 'Posted Date',
    accessor: 'postedDate',
    disableFilters: true,
    Cell: (d) => {
      const { value, row } = d;

      const { id } = row;

      return <DateTableCell id={`row${id}postedDate`} dateString={value?.dateMonthYear} timeString={value?.time} />;
    },
  },
  {
    Header: 'Published Time',
    accessor: 'publishedDate',
    disableFilters: true,
    Cell: (d) => {
      const { value } = d;

      return value?.time;
    },
  },
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    disableFilters: true,
  },
];

export default institutionalStatusFeaturesPageColumns;
