import React from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';

import TextColumnFilter from '../../TextColumnFilter/TextColumnFilter';
import { Routes } from '../../../App.constants';

const groupSelectModalTableColumns = (handleSelectGroup) => [
  {
    accessor: 'groupId',
    id: 'select-group',
    disableFilters: true,
    disableSortBy: true,
    Cell: (d) => {
      const { value: groupId, row } = d;
      const { id } = row;

      return (
        <Input
          type="radio"
          id={`row-${id}-checkbox`}
          name={`row-${id}-checkbox`}
          key={`row-${id}-checkbox`}
          data-testid={`row-${id}-checkbox`}
          onClick={() => {
            handleSelectGroup({
              groupId,
            });
          }}
        />
      );
    },
  },
  {
    Header: 'Group ID',
    accessor: 'groupId',
    disableFilters: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { value, row } = e;
      const { original } = row;
      const { groupId } = original;

      return (
        <Link to={Routes.GROUP_DETAIL.toLink({ groupId })} className="p-0">
          {value}
        </Link>
      );
    },
  },
  {
    Header: 'Description',
    accessor: 'description',
    Filter: TextColumnFilter,
  },
];

export default groupSelectModalTableColumns;
