import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './App.routes';

const App = () => (
  <>
    <ToastContainer autoClose={3000} closeOnClick pauseOnFocusLoss={false} hideProgressBar />
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </>
);

export default App;
