/**
 * @param  {string} postDate - a date string from the backend
 * @return  {Object} - a formatted date object
 */
const formatPostDate = (postDate) => {
  let postDateValue;

  if (postDate) {
    postDateValue = {
      dateMonthYear: new Date(postDate).toLocaleString('en-US', {
        day: '2-digit',
        year: 'numeric',
        month: '2-digit',
      }),
      time: new Date(postDate).toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      }),
      dmyt: new Date(postDate).toLocaleString('en-US', {
        day: '2-digit',
        year: 'numeric',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }),
    };
  } else {
    postDateValue = { dateMonthYear: '', time: '', dmyt: '' };
  }

  return postDateValue;
};

export default formatPostDate;
