export const tabIdAndLabelForConsumerStatus = [
  { tabId: '1', label: 'Topics' },
  { tabId: '2', label: 'Features' },
];

export const CONSUMER_STATUS_TOPICS_FORM_INITIAL_STATE = {
  date: new Date(),
  onlyWips: true,
  pending: false,
};
