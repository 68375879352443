import formatPostDate from '../../utils/date-utils';

/**
 * @param  {Object} featureData - feature data from backend
 * @return  {Array} - array of objects with data in each object needed for a feature detail table
 */
const buildFeatureMetadata = (featureMetadata = {}) => ({
  featureId: featureMetadata.featureId,
  name: featureMetadata?.name,
  featureType: featureMetadata.featureType.name,
  tier: featureMetadata.featureType.tier.name,
  language: featureMetadata.language.name,
  updated: formatPostDate(featureMetadata.updated),
  urlTitle: featureMetadata.urlTitle,
  featureProductId: featureMetadata.featureType.product.productId,
  inwork: featureMetadata.inwork,
  deleted: featureMetadata.purged,
  purged: featureMetadata.purged ? 'Y' : 'N',
  stable: featureMetadata.stable ? 'Y' : 'N',
  product: featureMetadata.featureType.product.name,
});

export default buildFeatureMetadata;
