import { useCallback } from 'react';

import useFetchWithState from './useFetchWithState';
import { BESSIE_API } from '../App.constants';
import useAuthApi from './useAuthApi';

const useArticleDistributionListApi = () => {
  const {
    amFetchWithState: getArticleDistributionListsData,
    data: articleDistributionListsData,
    error: errorGettingArticleDistributionLists,
    loading: loadingArticleDistributionLists,
    abortActiveRequest: abortGettingArticleDistributionLists,
  } = useFetchWithState();
  const {
    amFetchWithState: deleteArticleFromDistribution,
    data: deleteData,
    error: errorDeletingArticle,
    loading: deletingArticle,
  } = useFetchWithState();
  const { getAuthCookies } = useAuthApi();
  const {
    amFetchWithState: getArticleDistributionData,
    data: articleDistributionData,
    error: errorGettingArticleDistribution,
    loading: loadingArticleDistribution,
    abortActiveRequest: abortGettingArticleDistribution,
  } = useFetchWithState();
  const {
    amFetchWithState: addArticleToDistribution,
    data: addData,
    error: errorAddingArticle,
    loading: addingArticle,
  } = useFetchWithState();

  const getArticleDistribution = useCallback(
    (distributionId) => {
      getArticleDistributionData(`${BESSIE_API}/distribution/${distributionId}`);
    },
    [getArticleDistributionData]
  );

  const getArticleDistributionLists = useCallback(
    (showInactive) => {
      getArticleDistributionListsData(`${BESSIE_API}/distributions?inactive=${showInactive}`);
    },
    [getArticleDistributionListsData]
  );

  /**
   * @param  {string} articleId - article ID which has to be deleted
   */
  const deleteArticle = useCallback(
    (distributionId, articleId) => {
      const { encodedTicketFromCookie } = getAuthCookies();

      deleteArticleFromDistribution(`${BESSIE_API}/distribution/${distributionId}/article/${articleId}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [deleteArticleFromDistribution, getAuthCookies]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const addArticle = useCallback(
    (distributionId, articleId) => {
      const { encodedTicketFromCookie } = getAuthCookies();

      addArticleToDistribution(`${BESSIE_API}/distribution/${distributionId}/article/${articleId}`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [addArticleToDistribution, getAuthCookies]
  );

  return {
    getArticleDistributionLists,
    articleDistributionListsData,
    errorGettingArticleDistributionLists,
    loadingArticleDistributionLists,
    abortGettingArticleDistributionLists,
    deleteArticle,
    deleteData,
    errorDeletingArticle,
    deletingArticle,
    getArticleDistribution,
    articleDistributionData,
    errorGettingArticleDistribution,
    loadingArticleDistribution,
    abortGettingArticleDistribution,
    addArticle,
    addData,
    errorAddingArticle,
    addingArticle,
  };
};

export default useArticleDistributionListApi;
