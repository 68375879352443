import React from 'react';
import { Table, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { MdModeEdit } from 'react-icons/md';

const UserDetailMetadataTable = ({ userMetadata, onEdit, isUsersDetailPage }) => (
  <>
    <Table>
      <tbody>
        <tr>
          <th scope="row" className="border-0 text-end">
            First Name:
          </th>
          <td className="border-0">{userMetadata.firstName}</td>
        </tr>
        <tr>
          <th scope="row" className="border-0 text-end">
            Last Name:
          </th>
          <td className="border-0">{userMetadata.lastName}</td>
        </tr>
        <tr>
          <th scope="row" className="border-0 text-end">
            Email:
          </th>
          <td className="border-0">{userMetadata.email}</td>
        </tr>
        <tr>
          <th scope="row" className="border-0 text-end">
            Username:
          </th>
          <td className="border-0">{userMetadata.username}</td>
        </tr>
        <tr>
          <th scope="row" className="border-0 pt-3 text-end">
            Active:
          </th>
          <td className="border-0 pt-3">{userMetadata.inactive ? 'No' : 'Yes'}</td>
        </tr>
      </tbody>
    </Table>

    {!isUsersDetailPage && (
      <div className="d-flex justify-content-center align-items-center">
        <Button type="button" color="link" onClick={onEdit}>
          <MdModeEdit className="mb-1" /> Edit
        </Button>
      </div>
    )}
  </>
);

UserDetailMetadataTable.defaultProps = {
  userMetadata: {},
};

UserDetailMetadataTable.propTypes = {
  userMetadata: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    username: PropTypes.string,
    inactive: PropTypes.bool,
  }),
  onEdit: PropTypes.func.isRequired,
  isUsersDetailPage: PropTypes.bool.isRequired,
};

export default UserDetailMetadataTable;
