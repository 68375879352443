import { string, object } from 'yup';

const addUserModalSchema = object().shape({
  firstName: string().required({
    field: 'firstName',
    errorMessage: 'Required field.',
  }),
  lastName: string().required({
    field: 'lastName',
    errorMessage: 'Required field.',
  }),
  email: string().required({
    field: 'email',
    errorMessage: 'Required field.',
  }),
  username: string().required({
    field: 'username',
    errorMessage: 'Required field.',
  }),
});

export default addUserModalSchema;
