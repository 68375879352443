import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import UserDetailMetadataTable from './UserDetailMetadataTable/UserDetailMetadataTable';
import UserDetailMetadataForm from './UserDetailMetadataForm/UserDetailMetadataForm';

const UserDetailMetadata = ({
  userMetadata,
  setUserMetadata,
  editUserData,
  userId,
  onUpdate,
  error,
  loading,
  isUsersDetailPage,
}) => {
  const [edit, setEdit] = useState(false);

  // if editUserData exists, send toast feedback to user, toggle from the form to the table
  // we need a separate state for this so as to trigger the toast on only edit update and not on page load
  useEffect(() => {
    if (editUserData) {
      toast.success('User details updated');
      setEdit(false);
      setUserMetadata(editUserData);
    }
  }, [editUserData, setUserMetadata]);

  const onCancel = () => {
    setEdit(false);
  };

  const onEdit = () => {
    setEdit(true);
  };

  return (
    <>
      {edit ? (
        <UserDetailMetadataForm
          userMetadata={userMetadata}
          onUpdate={onUpdate}
          error={error}
          onCancel={onCancel}
          userId={userId}
          loading={loading}
        />
      ) : (
        <UserDetailMetadataTable userMetadata={userMetadata} onEdit={onEdit} isUsersDetailPage={isUsersDetailPage} />
      )}
    </>
  );
};

UserDetailMetadata.defaultProps = {
  userMetadata: null,
  editUserData: null,
  error: null,
};

UserDetailMetadata.propTypes = {
  userMetadata: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  userId: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  editUserData: PropTypes.shape(),
  error: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
  setUserMetadata: PropTypes.func.isRequired,
  isUsersDetailPage: PropTypes.bool.isRequired,
};

export default UserDetailMetadata;
