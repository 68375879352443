/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import useHelpApi from '../../hooks/useHelpApi';
import styles from './OxygenHelpPage.module.scss';
import { EDITORIAL_API } from '../../App.constants';

const OxygenHelpPage = () => {
  const { getOxygenHelp, oxygenHelpData, errorGettingOxygenHelp } = useHelpApi();
  const [pageMessage, setPageMessage] = useState('Loading oxygen user guide page');
  const userGuide = oxygenHelpData
    ?.replaceAll('src="media', `src="${EDITORIAL_API}/bsc/docs/media`)
    .replace('docs.css', `${EDITORIAL_API}/bsc/docs/docs.css`);

  useEffect(() => {
    if (errorGettingOxygenHelp) {
      setPageMessage(<p>Cannot load oxygen user guide</p>);
    }
  }, [errorGettingOxygenHelp]);

  // get oxygen help content on mount
  useEffect(() => {
    getOxygenHelp();
  }, [getOxygenHelp]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Oxygen User Guide</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Oxygen User Guide</h1>
            </div>
          </Col>
        </Row>
        {!userGuide && pageMessage}

        <div className={styles.help}>
          <span dangerouslySetInnerHTML={{ __html: userGuide }} />
        </div>
      </Container>
    </DefaultTemplate>
  );
};

export default OxygenHelpPage;
