import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Routes } from '../../../../../App.constants';

const featureVersionsTableColumns = [
  {
    Header: 'Updated',
    accessor: 'updated',
    disableFilters: true,
    disableSortBy: true,
    Cell: (d) => {
      const { value, row } = d;
      const { original, id } = row;
      const { featureId } = original;
      const dateString = value?.dateMonthYear;
      const timeString = value?.time;

      return (
        <>
          {dateString && (
            <>
              <Link id={`id-${id}`} to={Routes.FEATURE_VIEW.toLink({ featureId })} className="me-0">
                {dateString}
              </Link>

              {timeString && (
                <UncontrolledTooltip placement="right" target={`id-${id}`} data-testid="date-table-cell-tooltip">
                  {timeString}
                </UncontrolledTooltip>
              )}
            </>
          )}
        </>
      );
    },
  },
];

export default featureVersionsTableColumns;
