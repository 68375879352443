import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  Alert,
  FormFeedback,
} from 'reactstrap';
import { toast } from 'react-toastify';

import useFormHandler from '../../hooks/useFormHandler';
import useUserApi from '../../hooks/useUserApi';
import addUserModalSchema from './AddUserModal.schema';

const AddUserModal = ({ toggle }) => {
  const { addUser, addData, errorAddingUser } = useUserApi();
  const {
    formData,
    handleInputChange,
    handleResetForm,
    formError,
    setFormError,
    isFormEmpty,
    isFormValid,
    validationErrors,
  } = useFormHandler({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
  });

  // If the users data is retrieved successfully set the toast, close the modal and reload page
  useEffect(() => {
    if (addData) {
      toast.success('User added');
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [addData]);

  // If there is an error during the fetch (400 or 404) set the errorm message and remove the
  // current subjects
  useEffect(() => {
    if (errorAddingUser) {
      const errorStatus = errorAddingUser?.status;

      if (errorStatus === 404) {
        toast.error('Cannot add user');
      } else if (errorStatus === 400) {
        toast.error('Invalid input');
      } else if (errorStatus === 403) {
        toast.error('User with that name already exists');
      }
    }
  }, [errorAddingUser]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormEmpty()) {
      setFormError('');

      if (isFormValid(addUserModalSchema)) {
        const refinedFormData = { ...formData };

        addUser(refinedFormData);
      }
    }
  };

  const onFormReset = (e) => {
    e.preventDefault();

    handleResetForm();
  };

  return (
    <Modal isOpen toggle={toggle} size="xl" centered className="px-0 px-sm-4">
      <ModalHeader toggle={toggle} className="text-primary fw-bold border-bottom border-primary border-1">
        <span className="primary">Add User</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit} onReset={onFormReset}>
              <FormGroup className="no-gutters">
                <Label htmlFor="firstName">First Name:</Label>
                <Input
                  name="firstName"
                  id="firstName"
                  bsSize="sm"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  invalid={!!validationErrors?.firstName}
                />
                <FormFeedback>{validationErrors.firstName}</FormFeedback>
              </FormGroup>
              <FormGroup className="no-gutters">
                <Label htmlFor="lastName">Last Name:</Label>
                <Input
                  name="lastName"
                  id="lastName"
                  bsSize="sm"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  invalid={!!validationErrors?.lastName}
                />
                <FormFeedback>{validationErrors.lastName}</FormFeedback>
              </FormGroup>
              <FormGroup className="no-gutters">
                <Label htmlFor="email">Email:</Label>
                <Input
                  name="email"
                  id="email"
                  bsSize="sm"
                  value={formData.email}
                  onChange={handleInputChange}
                  invalid={!!validationErrors?.email}
                />
                <FormFeedback>{validationErrors.email}</FormFeedback>
              </FormGroup>
              <FormGroup className="no-gutters">
                <Label htmlFor="username">Username:</Label>
                <Input
                  name="username"
                  id="username"
                  bsSize="sm"
                  value={formData.username}
                  onChange={handleInputChange}
                  invalid={!!validationErrors?.username}
                />
                <FormFeedback>{validationErrors.username}</FormFeedback>
              </FormGroup>
              <Alert color="danger" isOpen={!!formError} toggle={() => setFormError('')} fade={false}>
                {formError}
              </Alert>
              <div className="text-center mt-4">
                <Button type="submit" color="primary" block data-testid="Add">
                  Add
                </Button>
                <Button type="reset" className="mt-2" color="link">
                  Reset
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

AddUserModal.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default AddUserModal;
