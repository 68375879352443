import { useState, useCallback, useRef, useEffect } from 'react';
import useFetch from './useFetch';

const useFetchWithState = () => {
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const { abortActiveRequest, amFetch } = useFetch();

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  /**
   * @param  {string} url - full API endpoint path
   * @param  {Object} options - fetch options
   */
  const amFetchWithState = useCallback(
    async (url, options) => {
      try {
        setLoading(true);
        setError(null);

        const responseData = await amFetch(url, options);

        if (isMounted.current) {
          setData(responseData);
          setLoading(false);
        }

        return responseData;
      } catch (e) {
        // If the hook is still mounted set the error and loading state.
        if (isMounted.current) {
          setError(e);
          setLoading(false);
        }

        return e;
      }
    },
    [amFetch]
  );

  return {
    loading,
    data,
    error,
    amFetchWithState,
    abortActiveRequest,
  };
};

export default useFetchWithState;
