import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import useP2PBDSApi from '../../../../hooks/useP2PBDSApi';
import SpinnerComponent from '../../../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../../../components/SpinnerComponent/SpinnerComponent.constants';
import buildBDSArticlesData from './BDSStatusArticles.utils';
import BDSStatusArticlesTable from './BDSStatusArticlesTable/BDSStatusArticlesTable';

const BDSStatusArticles = ({ formData }) => {
  const { getBDSArticles, bdsArticlesData, errorGettingBDSArticles, gettingBDSArticles } = useP2PBDSApi();
  const tableRowsData = bdsArticlesData ? buildBDSArticlesData(bdsArticlesData?.articles) : null;
  const noBDSArticlesFoundMessage = <p className="mt-3">There are no articles.</p>;

  useEffect(() => {
    const result = new Date(formData.date);

    result.setDate(result.getDate() + 1);

    getBDSArticles({
      startedAfter: `${formData.date?.getFullYear()}-${formData.date?.getMonth() + 1}-${formData.date?.getDate()}`,
      startedBefore: `${formData.date?.getFullYear()}-${result.getMonth() + 1}-${result.getDate()}`,
      pending: formData.pending,
    });
  }, [formData.date, formData.onlyWips, formData.pending, getBDSArticles]);

  return (
    <>
      <Helmet>
        <title>AM: BDS Status: Articles</title>
      </Helmet>

      {errorGettingBDSArticles?.status === 404 && noBDSArticlesFoundMessage}
      {errorGettingBDSArticles?.status !== 404 && errorGettingBDSArticles && (
        <p className="mt-3">There was an issue loading the bds articles.</p>
      )}
      {(gettingBDSArticles || !bdsArticlesData) && !errorGettingBDSArticles && (
        <div className="mt-3">
          <SpinnerComponent size={spinnerSize1} setFullVh={false} />
        </div>
      )}
      {tableRowsData && !gettingBDSArticles && !errorGettingBDSArticles && (
        <BDSStatusArticlesTable data={tableRowsData} />
      )}
    </>
  );
};

BDSStatusArticles.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default BDSStatusArticles;
