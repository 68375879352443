import PropTypes from 'prop-types';

const DataProp = PropTypes.arrayOf(
  PropTypes.shape({
    articleId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    titleXml: PropTypes.string,
    articleTypeName: PropTypes.string.isRequired,
    postDateValue: PropTypes.shape({
      dateMonthYear: PropTypes.string.isRequired,
      dmyt: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
    }),
    stableValueToDisplay: PropTypes.string.isRequired,
    inWorkValueToDisplay: PropTypes.string.isRequired,
    deletedValueToDisplay: PropTypes.string.isRequired,
    mediaCount: PropTypes.number.isRequired,
    articleCluster: PropTypes.string.isRequired,
    articleTypeId: PropTypes.number.isRequired,
    topics: PropTypes.arrayOf(
      PropTypes.shape({ articleId: PropTypes.number, identifier: PropTypes.string, topicId: PropTypes.number })
    ).isRequired,
    postDate: PropTypes.string.isRequired,
    subjectName: PropTypes.string.isRequired,
    topicId: PropTypes.number,
    articleClusterId: PropTypes.number,
  })
);

export default DataProp;
