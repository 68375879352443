import {
  SET_SELECT_FIELD_OPTIONS,
  SET_USER,
  SET_ARTICLE_SEARCH_FORM_DATA,
  SET_ARTICLE_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_ARTICLE_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_CLUSTER_SEARCH_FORM_DATA,
  SET_CLUSTER_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_CLUSTER_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_VERSION_HISTORY_TABLE_CONFIG_PAGE_INDEX,
  SET_VERSION_HISTORY_TABLE_CONFIG_PAGE_SIZE,
  SET_INDEX_SEARCH_FORM_DATA,
  SET_INDEX_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_INDEX_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_MY_WORK_TABLE_CONFIG_PAGE_INDEX,
  SET_MY_WORK_TABLE_CONFIG_PAGE_SIZE,
  SET_MY_WORK_TABLE_CONFIG_FILTERS,
  SET_LOGIN_MODAL_CONFIG,
  SET_LOGIN_MODAL_CONFIG_IS_OPEN,
  SET_LOGIN_MODAL_CONFIG_ID,
  SET_MY_WORK_SORT_CONFIG,
  SET_FEATURE_SEARCH_FORM_DATA,
  SET_FEATURE_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_FEATURE_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_FULL_TEXT_SEARCH_FORM_DATA,
  SET_FULL_TEXT_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_FULL_TEXT_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_RECENT_ARTICLES_TABLE_CONFIG_PAGE_INDEX,
  SET_RECENT_ARTICLES_TABLE_CONFIG_PAGE_SIZE,
} from './action-types';

export const setSelectFieldOptions = (selectFieldOptionKey, selectFieldOptions) => ({
  selectFieldOptionKey,
  selectFieldOptions,
  type: SET_SELECT_FIELD_OPTIONS,
});

export const setUser = (user) => ({
  user,
  type: SET_USER,
});

export const setArticleSearchFormData = (articleSearchFormData) => ({
  articleSearchFormData,
  type: SET_ARTICLE_SEARCH_FORM_DATA,
});

export const setArticleSearchTableConfigPageIndex = (articleSearchTablePageIndex) => ({
  articleSearchTablePageIndex,
  type: SET_ARTICLE_SEARCH_TABLE_CONFIG_PAGE_INDEX,
});

export const setArticleSearchTableConfigPageSize = (articleSearchTablePageSize) => ({
  articleSearchTablePageSize,
  type: SET_ARTICLE_SEARCH_TABLE_CONFIG_PAGE_SIZE,
});

export const setClusterSearchFormData = (clusterSearchFormData) => ({
  clusterSearchFormData,
  type: SET_CLUSTER_SEARCH_FORM_DATA,
});

export const setClusterSearchTableConfigPageIndex = (clusterSearchTablePageIndex) => ({
  clusterSearchTablePageIndex,
  type: SET_CLUSTER_SEARCH_TABLE_CONFIG_PAGE_INDEX,
});

export const setClusterSearchTableConfigPageSize = (clusterSearchTablePageSize) => ({
  clusterSearchTablePageSize,
  type: SET_CLUSTER_SEARCH_TABLE_CONFIG_PAGE_SIZE,
});

export const setVersionHistoryTableConfigPageIndex = (versionHistoryTablePageIndex) => ({
  versionHistoryTablePageIndex,
  type: SET_VERSION_HISTORY_TABLE_CONFIG_PAGE_INDEX,
});

export const setVersionHistoryTableConfigPageSize = (versionHistoryTablePageSize) => ({
  versionHistoryTablePageSize,
  type: SET_VERSION_HISTORY_TABLE_CONFIG_PAGE_SIZE,
});

export const setIndexSearchFormData = (indexSearchFormData) => ({
  indexSearchFormData,
  type: SET_INDEX_SEARCH_FORM_DATA,
});

export const setIndexSearchTableConfigPageIndex = (indexSearchTablePageIndex) => ({
  indexSearchTablePageIndex,
  type: SET_INDEX_SEARCH_TABLE_CONFIG_PAGE_INDEX,
});

export const setIndexSearchTableConfigPageSize = (indexSearchTablePageSize) => ({
  indexSearchTablePageSize,
  type: SET_INDEX_SEARCH_TABLE_CONFIG_PAGE_SIZE,
});

export const setMyWorkTableConfigPageIndex = (myWorkTablePageIndex) => ({
  myWorkTablePageIndex,
  type: SET_MY_WORK_TABLE_CONFIG_PAGE_INDEX,
});

export const setMyWorkTableConfigPageSize = (myWorkTablePageSize) => ({
  myWorkTablePageSize,
  type: SET_MY_WORK_TABLE_CONFIG_PAGE_SIZE,
});

export const setMyWorkSortConfig = (myWorkSortConfig) => ({
  myWorkSortConfig,
  type: SET_MY_WORK_SORT_CONFIG,
});

export const setMyWorkTableConfigFilters = (myWorkFilters) => {
  return {
    myWorkFilters,
    type: SET_MY_WORK_TABLE_CONFIG_FILTERS,
  };
};

export const setLoginModalConfig = (config) => ({
  config,
  type: SET_LOGIN_MODAL_CONFIG,
});

export const setLoginModalConfigIsOpen = (isOpen) => ({
  isOpen,
  type: SET_LOGIN_MODAL_CONFIG_IS_OPEN,
});

export const setLoginModalConfigId = (id) => ({
  id,
  type: SET_LOGIN_MODAL_CONFIG_ID,
});

export const setFeatureSearchFormData = (featureSearchFormData) => ({
  featureSearchFormData,
  type: SET_FEATURE_SEARCH_FORM_DATA,
});

export const setFeatureSearchTableConfigPageIndex = (featureSearchTablePageIndex) => ({
  featureSearchTablePageIndex,
  type: SET_FEATURE_SEARCH_TABLE_CONFIG_PAGE_INDEX,
});

export const setFeatureSearchTableConfigPageSize = (featureSearchTablePageSize) => ({
  featureSearchTablePageSize,
  type: SET_FEATURE_SEARCH_TABLE_CONFIG_PAGE_SIZE,
});

export const setFullTextSearchFormData = (fullTextSearchFormData) => ({
  fullTextSearchFormData,
  type: SET_FULL_TEXT_SEARCH_FORM_DATA,
});

export const setFullTextSearchTableConfigPageIndex = (fullTextSearchTablePageIndex) => ({
  fullTextSearchTablePageIndex,
  type: SET_FULL_TEXT_SEARCH_TABLE_CONFIG_PAGE_INDEX,
});

export const setFullTextSearchTableConfigPageSize = (fullTextSearchTablePageSize) => ({
  fullTextSearchTablePageSize,
  type: SET_FULL_TEXT_SEARCH_TABLE_CONFIG_PAGE_SIZE,
});

export const setRecentArticlesTableConfigPageIndex = (recentArticlesTablePageIndex) => ({
  recentArticlesTablePageIndex,
  type: SET_RECENT_ARTICLES_TABLE_CONFIG_PAGE_INDEX,
});

export const setRecentArticlesTableConfigPageSize = (recentArticlesTablePageSize) => ({
  recentArticlesTablePageSize,
  type: SET_RECENT_ARTICLES_TABLE_CONFIG_PAGE_SIZE,
});
