import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import useP2PSyndicationApi from '../../../../hooks/useP2PSyndicationApi';
import SpinnerComponent from '../../../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../../../components/SpinnerComponent/SpinnerComponent.constants';
import buildSyndicationArticlesData from './SyndicationStatusArticles.utils';
import SyndicationStatusArticlesTable from './SyndicationStatusArticlesTable/SyndicationStatusArticlesTable';

const SyndicationStatusArticles = ({ formData }) => {
  const {
    getSyndicationArticles,
    syndicationArticlesData,
    errorGettingSyndicationArticles,
    gettingSyndicationArticles,
  } = useP2PSyndicationApi();
  const tableRowsData = syndicationArticlesData
    ? buildSyndicationArticlesData(syndicationArticlesData?.articles)
    : null;
  const noSyndicationArticlesFoundMessage = <p className="mt-3">There are no articles.</p>;

  useEffect(() => {
    const result = new Date(formData.date);

    result.setDate(result.getDate() + 1);

    getSyndicationArticles({
      startedAfter: `${formData.date?.getFullYear()}-${formData.date?.getMonth() + 1}-${formData.date?.getDate()}`,
      startedBefore: `${formData.date?.getFullYear()}-${result.getMonth() + 1}-${result.getDate()}`,
      pending: formData.pending,
    });
  }, [formData.date, formData.onlyWips, formData.pending, getSyndicationArticles]);

  return (
    <>
      <Helmet>
        <title>AM: Syndication Status: Articles</title>
      </Helmet>

      {errorGettingSyndicationArticles?.status === 404 && noSyndicationArticlesFoundMessage}
      {errorGettingSyndicationArticles?.status !== 404 && errorGettingSyndicationArticles && (
        <p className="mt-3">There was an issue loading the syndication articles.</p>
      )}
      {(gettingSyndicationArticles || !syndicationArticlesData) && !errorGettingSyndicationArticles && (
        <div className="mt-3">
          <SpinnerComponent size={spinnerSize1} setFullVh={false} />
        </div>
      )}
      {tableRowsData && !gettingSyndicationArticles && !errorGettingSyndicationArticles && (
        <SyndicationStatusArticlesTable data={tableRowsData} />
      )}
    </>
  );
};

SyndicationStatusArticles.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default SyndicationStatusArticles;
