import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { Routes } from '../../../../App.constants';
import SpinnerComponent from '../../../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../../../components/SpinnerComponent/SpinnerComponent.constants';
import FeatureDetailTable from './FeatureDetailTable/FeatureDetailTable';
import FeatureDetailForm from './FeatureDetailForm/FeatureDetailForm';
import EditDeleteButtons from '../../../../components/EditDeleteButtons/EditDeleteButtons';
import useFeatureApi from '../../../../hooks/useFeatureApi';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';

const FeatureDetailMetadata = ({ featureMetadata, errorGettingFeatureData, loadingFeatureData }) => {
  const {
    editFeature,
    editData,
    errorEditingFeature,
    editingFeature,
    deleteFeature,
    deleteData,
    errorDeletingFeature,
    deletingFeature,
  } = useFeatureApi();
  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const history = useHistory();

  const onConfirmDeleteButtonClick = () => {
    deleteFeature(featureMetadata.featureId);
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (editData) {
      toast.success('Feature updated');

      setEdit(false);
      setSave(false);

      window.location.reload();
    }
  }, [editData]);

  useEffect(() => {
    if (errorDeletingFeature) {
      toast.error('Feature cannot be deleted');
    }
  }, [errorDeletingFeature]);

  useEffect(() => {
    if (deleteData) {
      toast.success('Feature deleted');
      history.push(Routes.FEATURE_SEARCH.toLink());
    }
  }, [deleteData, history]);

  const handleEdit = () => {
    setEdit(true);
  };

  const handleCancel = () => {
    setEdit(false);
    setSave(false);
  };

  const handleSave = () => {
    setSave(true);
  };

  const handleDelete = () => {
    setShowConfirmModal(true);
  };

  if (errorGettingFeatureData?.status === 404) {
    return <p className="mt-3">There is no metadata for this feature.</p>;
  }

  if (errorGettingFeatureData) {
    return <p className="mt-3">There was an issue loading the feature metadata.</p>;
  }

  if (editingFeature || !featureMetadata || loadingFeatureData || deletingFeature) {
    return (
      <div className="mt-3">
        <SpinnerComponent size={spinnerSize1} setFullVh={false} />
      </div>
    );
  }

  return (
    <>
      <EditDeleteButtons
        edit={edit}
        handleEdit={handleEdit}
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleDelete={handleDelete}
        hideDelete={featureMetadata?.deleted}
      />
      {!edit ? (
        <FeatureDetailTable featureMetadata={featureMetadata} />
      ) : (
        <FeatureDetailForm
          featureMetadata={featureMetadata}
          save={save}
          setSave={setSave}
          editFeature={editFeature}
          error={errorEditingFeature}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          toggle={() => setShowConfirmModal(false)}
          header="Confirm Delete"
          confirmButton="Soft Delete"
          message="There are versions for this feature, are you sure you want to do a soft delete?"
          onConfirmButtonClick={onConfirmDeleteButtonClick}
        />
      )}
    </>
  );
};

FeatureDetailMetadata.defaultProps = {
  featureMetadata: null,
  errorGettingFeatureData: null,
};

FeatureDetailMetadata.propTypes = {
  featureMetadata: PropTypes.shape(),
  errorGettingFeatureData: PropTypes.shape(),
  loadingFeatureData: PropTypes.bool.isRequired,
};

export default FeatureDetailMetadata;
