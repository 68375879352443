// Select field custom styles
export const AllWorkWIPSelectCustomStyles = {
  control: {
    minWidth: '15rem',
    maxWidth: '15rem',
    margin: '0rem',
  },
  menu: {
    minWidth: '15rem',
    maxWidth: '15rem',
  },
};

export const ALL_WORK_FORM_INITIAL_STATE = {
  processDefinitionKey: '',
};
