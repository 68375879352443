import { string, object } from 'yup';

const indexCreateSchema = object().shape({
  name: string().when('indexed', {
    is: (val) => val?.label === 'Currently indexed',
    then: string().required({
      field: 'name',
      errorMessage: 'Name or title should have at least three characters',
    }),
  }),
});

export default indexCreateSchema;
