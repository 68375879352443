/**
 * Remove keys from object if there is no useful data as value.
 * @param  {Object} formData - original form data
 * @return  {Object} - form data with keys with no useful data removed
 */
export const removeEmptyFormDataValues = (formData) => {
  const refinedFormData = {};

  Object.keys(formData).forEach((key) => {
    if ((formData[key] && !Array.isArray(formData[key])) || (Array.isArray(formData[key]) && formData[key].length)) {
      refinedFormData[key] = formData[key];
    }
  });

  return refinedFormData;
};

/**
 * @param  {Object} dataToCheck - form, request or any other kind of data object needing a empty validation check
 * @return  {boolean} - is the data empty
 */
export const checkForEmptyData = (dataToCheck) => {
  const dataWithEmptyValuesRemoved = removeEmptyFormDataValues(dataToCheck);

  if (!Object.keys(dataWithEmptyValuesRemoved).length) {
    return true;
  }

  return false;
};

/**
 * @param  {Array} articleTypes - an array of article type objects returned from backend
 * @return  {Array} - an array of article type objects with inactive types excluded
 */
export const filterInactiveArticleTypes = (articleTypes) => articleTypes.filter((articleType) => !articleType.inactive);
