import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSortBy, useTable, usePagination, useFilters } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';

import DefaultTable from '../../../components/DefaultTable/DefaultTable';
import DefaultTableControls from '../../../components/DefaultTableControls/DefaultTableControls';
import featureSearchTableColumns from './FeatureSearchTable.column';
import stringFilterType from '../../../components/DefaultTable/DefaultTable.utils';
import {
  setFeatureSearchTableConfigPageIndex,
  setFeatureSearchTableConfigPageSize,
} from '../../../store/action-creators';

const FeatureSearchTable = ({ featureDataResults }) => {
  const numOfRows = featureDataResults.length;
  const dispatch = useDispatch();
  const pageIndexFromRedux = useSelector((state) => state.featureSearchTablePageIndex);
  const pageSizeFromRedux = useSelector((state) => state.featureSearchTablePageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data: featureDataResults,
      columns: featureSearchTableColumns,
      initialState: {
        pageIndex: pageIndexFromRedux,
        pageSize: pageSizeFromRedux,
      },
      filterTypes: stringFilterType,
      manualPagination: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    dispatch(setFeatureSearchTableConfigPageIndex(pageIndex));
  }, [pageIndex, dispatch]);

  useEffect(() => {
    dispatch(setFeatureSearchTableConfigPageSize(pageSize));
  }, [dispatch, pageSize]);

  return (
    <div className="mt-3">
      <p>
        Features found: <strong>{numOfRows}</strong>
      </p>
      <DefaultTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
      />
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
    </div>
  );
};

FeatureSearchTable.defaultProps = {
  featureDataResults: [],
};

FeatureSearchTable.propTypes = {
  featureDataResults: PropTypes.arrayOf(PropTypes.shape()),
};

export default FeatureSearchTable;
