export const STATUS_OPTIONS = [
  { value: false, label: 'To be indexed' },
  { value: true, label: 'Currently indexed' },
];

export const INDEX_SEARCH_INITIAL_FORM_DATA = {
  name: '',
  corpusId: null,
  indexed: null,
  articleId: '',
  topicId: '',
};
