import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { MdErrorOutline } from 'react-icons/md';

import { Routes } from '../../../App.constants';
import TextColumnFilter from '../../../components/TextColumnFilter/TextColumnFilter';
import DateTableCell from '../../../components/DateTableCell/DateTableCell';
import BuildAction from './MyWorkTable.utils';

const MyWorkSearchPageColumns = (handleActionChange, setViewLinkUrl, updatingTasks, allWork, debugMode) => {
  let columns = [
    {
      Header: 'Workflow',
      accessor: 'workflow',
      Filter: TextColumnFilter,
    },
    {
      Header: 'Step',
      accessor: 'step',
      Filter: TextColumnFilter,
    },
    {
      Header: 'WIP Name',
      accessor: 'wipName',
      Filter: TextColumnFilter,
      Cell: (d) => {
        const { value, row } = d;
        const { original } = row;
        const { id } = original;

        return (
          <Link
            to={{
              pathname: Routes.WIP_DETAIL.toLink({ articleId: 'null', processId: id }),
              state: { backLink: { to: Routes.MY_WORK.toLink(), text: 'My Work Search' } },
            }}
          >
            {value}
          </Link>
        );
      },
    },
    {
      Header: 'Cluster',
      accessor: 'cluster',
      Filter: TextColumnFilter,
    },
    {
      Header: 'Started',
      accessor: 'started',
      disableFilters: true,
      Cell: (d) => {
        const { row } = d;
        const { original, id } = row;
        const { started } = original;

        return (
          <DateTableCell id={`row-${id}-post-date`} dateString={started?.dateMonthYear} timeString={started?.time} />
        );
      },
      Filter: TextColumnFilter,
    },
    {
      Header: 'User',
      Filter: TextColumnFilter,
      accessor: 'user',
    },
    {
      Header: <MdErrorOutline size={20} />,
      disableFilters: true,
      accessor: 'comment',
      Cell: (e) => {
        const { row } = e;
        const { original } = row;
        const { comment } = original;

        return (
          <>
            {comment && comment.trim().length > 1 && (
              <>
                <MdErrorOutline size={20} className="me-3 mt-1" id="notValid" color="red" />
                <UncontrolledTooltip placement="right" target="notValid">
                  {comment}
                </UncontrolledTooltip>
              </>
            )}
          </>
        );
      },
    },
    {
      Header: 'Action',
      disableFilters: true,
      accessor: 'action',
      disableSortBy: true,
      Cell: (e) => {
        return BuildAction(handleActionChange, setViewLinkUrl, updatingTasks, allWork, e);
      },
    },
  ];

  if (debugMode) {
    columns = columns.concat([
      {
        Header: 'TaskId',
        accessor: 'taskId',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ProcessId',
        accessor: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
    ]);
  }

  return columns;
};

export default MyWorkSearchPageColumns;
