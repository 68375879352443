import React from 'react';
import { Link } from 'react-router-dom';
import { GoLinkExternal } from 'react-icons/go';

import { Routes } from '../../../../../App.constants';
import DateTableCell from '../../../../../components/DateTableCell/DateTableCell';

const bdsStatusArticlesPageColumns = [
  {
    Header: 'Article ID',
    accessor: 'articleId',
    disableFilters: true,
    Cell: (e) => {
      const { value } = e;

      return <Link to={Routes.ARTICLE_DETAIL.toLink({ articleId: value })}>{value}</Link>;
    },
  },
  {
    Header: 'Article Name',
    accessor: 'articleName',
    disableFilters: true,
    Cell: (e) => {
      const { value, row } = e;
      const { original } = row;
      const { articleId } = original;

      return <Link to={Routes.ARTICLE_VIEW.toLink({ articleId, versionId: 'null' })}>{value}</Link>;
    },
  },
  {
    Header: 'Article Title',
    accessor: 'articleTitle',
    disableFilters: true,
    Cell: (e) => {
      const { value, row } = e;
      const { original } = row;
      const { articleURL } = original;

      if (articleURL) {
        return (
          <>
            <span className="d-flex flex-wrap">
              <a
                color="link"
                href={articleURL}
                rel="noopener noreferrer"
                target="_blank"
                className="d-flex justify-content-center align-items-center p-0 mb-2 fw-bold me-1"
              >
                <GoLinkExternal />
              </a>
              {value}
            </span>
          </>
        );
      }

      return null;
    },
  },
  {
    Header: 'Type',
    accessor: 'articleType',
    disableFilters: true,
  },
  {
    Header: 'State',
    accessor: 'state',
    disableFilters: true,
  },
  {
    Header: 'Posted Date',
    accessor: 'postedDate',
    disableFilters: true,
    Cell: (d) => {
      const { value, row } = d;

      const { id } = row;

      return <DateTableCell id={`row${id}postedDate`} dateString={value?.dateMonthYear} timeString={value?.time} />;
    },
  },
  {
    Header: 'Published Time',
    accessor: 'publishedDate',
    disableFilters: true,
    Cell: (d) => {
      const { value } = d;

      return value?.time;
    },
  },
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    disableFilters: true,
  },
];

export default bdsStatusArticlesPageColumns;
