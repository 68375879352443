/**
 * @param  {array} searchResults - an array of search results from the backend
 * @return  {array} - an array of search results with only necessary data included
 */
const refineArticleSearchResults = (searchResults) =>
  searchResults.map((searchResult) => {
    const { articleId, name, titleXml, articleType } = searchResult;

    const { name: articleTypeName, articleTypeId } = articleType;

    return {
      articleId,
      name,
      titleXml,
      articleTypeName,
      articleTypeId,
    };
  });

export default refineArticleSearchResults;
