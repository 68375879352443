import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import SyndicationStatusArticles from './SyndicationStatusArticles/SyndicationStatusArticles';
import { tabIdAndLabelForSyndicationStatus } from './SyndicationStatusTabs.constants';
import styles from './SyndicationStatusTabs.module.scss';

const SyndicationStatusTabs = ({ formData }) => {
  const { allTabLinks } = styles;
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <Nav tabs className="mt-2">
        {tabIdAndLabelForSyndicationStatus.map((tabIdandLabel) => {
          return (
            <NavItem data-testid="alltabs" key={tabIdandLabel.tabId}>
              <NavLink
                className={classnames({ active: activeTab === tabIdandLabel.tabId }, allTabLinks)}
                onClick={() => {
                  toggle(tabIdandLabel.tabId);
                }}
              >
                {tabIdandLabel.label}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <SyndicationStatusArticles formData={formData} />
        </TabPane>
      </TabContent>
    </>
  );
};

SyndicationStatusTabs.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default SyndicationStatusTabs;
