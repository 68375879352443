import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSortBy, useTable, usePagination, useFilters } from 'react-table';

import DefaultTable from '../../../components/DefaultTable/DefaultTable';
import DefaultTableControls from '../../../components/DefaultTableControls/DefaultTableControls';
import stringFilterType from '../../../components/DefaultTable/DefaultTable.utils';
import articleDistributionDetailTableColumns from './ArticleDistributionDetailTable.columns';

const ArticleDistributionDetailTable = ({ data, handleArticleRemovalFromDistributions }) => {
  const numOfRows = data.length;
  const memoizedColumns = useMemo(
    () => articleDistributionDetailTableColumns(handleArticleRemovalFromDistributions),
    [handleArticleRemovalFromDistributions]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns: memoizedColumns,
      filterTypes: stringFilterType,
      manualPagination: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="mt-3">
      <p>
        Articles found: <strong>{numOfRows}</strong>
      </p>
      <DefaultTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
      />
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
    </div>
  );
};

ArticleDistributionDetailTable.defaultProps = {
  data: [],
};

ArticleDistributionDetailTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  handleArticleRemovalFromDistributions: PropTypes.func.isRequired,
};

export default ArticleDistributionDetailTable;
