import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ConsumerStatusTopics from './ConsumerStatusTopics/ConsumerStatusTopics';
import ConsumerStatusFeatures from './ConsumerStatusFeatures/ConsumerStatusFeatures';
import { tabIdAndLabelForConsumerStatus } from './ConsumerStatusTabs.constants';
import styles from './ConsumerStatusTabs.module.scss';

const ConsumerStatusTabs = ({ formData, handleInputChange }) => {
  const { allTabLinks } = styles;
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <Nav tabs className="mt-2">
        {tabIdAndLabelForConsumerStatus.map((tabIdandLabel) => {
          return (
            <NavItem data-testid="consumerTabs" key={tabIdandLabel.tabId}>
              <NavLink
                className={classnames({ active: activeTab === tabIdandLabel.tabId }, allTabLinks)}
                onClick={() => {
                  toggle(tabIdandLabel.tabId);
                }}
              >
                {tabIdandLabel.label}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ConsumerStatusTopics formData={formData} handleInputChange={handleInputChange} />
        </TabPane>
        <TabPane tabId="2">
          <ConsumerStatusFeatures formData={formData} />
        </TabPane>
      </TabContent>
    </>
  );
};

ConsumerStatusTabs.propTypes = {
  formData: PropTypes.shape().isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default ConsumerStatusTabs;
