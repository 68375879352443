/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { BsPlusCircle } from 'react-icons/bs';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import useGroupApi from '../../hooks/useGroupApi';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import GroupsTable from './GroupsTable/GroupsTable';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import AddGroupModal from '../../components/AddGroupModal/AddGroupModal';

const GroupsPage = () => {
  const {
    getGroups,
    groupsData,
    gettingGroups,
    errorGettingGroups,
    deleteGroup,
    deleteData,
    errorDeletingGroup,
    deletingGroup,
  } = useGroupApi();
  const [errorGettingAllWorkMessage, setErrorGettingAllWorkMessage] = useState('');
  const [groupDataResults, setGroupDataResults] = useState();
  const [groupId, setShowGroupId] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const loading = gettingGroups || deletingGroup;
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);

  useEffect(() => {
    if (errorGettingGroups) {
      const errorStatus = errorGettingGroups?.status;

      if (errorStatus === 404) {
        setErrorGettingAllWorkMessage('No groups found.');
      } else if (errorStatus === 400) {
        setErrorGettingAllWorkMessage('Bad request');
      } else {
        setErrorGettingAllWorkMessage('There was an issue loading the processes.');
      }
    }
  }, [errorGettingGroups]);

  // get groups on mount
  useEffect(() => {
    getGroups();
  }, [getGroups]);

  useEffect(() => {
    if (groupsData) {
      setGroupDataResults(groupsData?.groups);
    }
  }, [groupsData]);

  const handleDeleteGroups = (value) => {
    setShowConfirmModal(true);
    setShowGroupId(value);
  };

  const onConfirmDeleteButtonClick = () => {
    deleteGroup(groupId);
    setShowConfirmModal(false);
  };

  // delete is successful, show the toast and reroute to homepage
  useEffect(() => {
    if (deleteData) {
      toast.success('Group deleted');
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [deleteData]);

  // if there is an error while deleting the article, show the error toast
  useEffect(() => {
    if (errorDeletingGroup) {
      const errorStatus = errorDeletingGroup.status;

      if (errorStatus === 400) {
        toast.error('Invalid input');
      }

      if (errorStatus === 404) {
        toast.error('Unable to find group');
      } else toast.error('Cannot be deleted');
    }
  }, [errorDeletingGroup]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Groups</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Groups</h1>
            </div>
          </Col>
        </Row>

        <div className="d-flex justify-content-center align-items-center mb-3">
          <Button className="d-flex align-items-center" color="link" onClick={() => setShowAddGroupModal(true)}>
            <BsPlusCircle className="me-1" />
            Add group
          </Button>
        </div>

        {!errorGettingAllWorkMessage && !loading && !groupDataResults && <p>Use the form to search for groups...</p>}
        {loading && (
          <div className="mt-3">
            <SpinnerComponent size={spinnerSize1} setFullVh={false} />
          </div>
        )}
        {groupDataResults && !loading && (
          <GroupsTable data={groupDataResults} handleDeleteGroups={handleDeleteGroups} />
        )}
        {errorGettingAllWorkMessage && !loading && errorGettingAllWorkMessage}
        {showConfirmModal && (
          <ConfirmModal
            toggle={() => setShowConfirmModal(false)}
            header="Confirm Delete"
            confirmButton="Delete"
            message="Are you sure you want to delete this group?"
            showButtons
            onConfirmButtonClick={onConfirmDeleteButtonClick}
          />
        )}
        {showAddGroupModal && <AddGroupModal toggle={() => setShowAddGroupModal(false)} />}
      </Container>
    </DefaultTemplate>
  );
};

export default GroupsPage;
