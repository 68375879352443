import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FeatureDetailMetadata from './FeatureDetailMetadata/FeatureDetailMetadata';
import FeatureDetailVersions from './FeatureDetailVersions/FeatureDetailVersions';
import FeatureDetailAssemblies from './FeatureDetailAssemblies/FeatureDetailAssemblies';
import tabIdAndLabelForFeatureDetail from './FeatureDetailTabs.constants';

const FeatureDetailTabs = ({ featureId, featureMetadata, errorGettingFeatureData, loadingFeatureData }) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <Nav tabs>
        {tabIdAndLabelForFeatureDetail.map((tabIdandLabel) => {
          return (
            <NavItem
              data-testid="alltabs"
              key={tabIdandLabel.tabId}
              className={classnames({ 'd-none d-md-block': tabIdandLabel.tabId > 3 })}
            >
              <NavLink
                className={classnames({ active: activeTab === tabIdandLabel.tabId })}
                onClick={() => {
                  toggle(tabIdandLabel.tabId);
                }}
              >
                {tabIdandLabel.label}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <FeatureDetailMetadata
            featureId={featureId}
            featureMetadata={featureMetadata}
            errorGettingFeatureData={errorGettingFeatureData}
            loadingFeatureData={loadingFeatureData}
          />
        </TabPane>
        <TabPane tabId="2">
          <FeatureDetailVersions featureId={featureId} />
        </TabPane>
        <TabPane tabId="3">
          <FeatureDetailAssemblies featureId={featureId} />
        </TabPane>
      </TabContent>
    </>
  );
};

FeatureDetailTabs.defaultProps = {
  featureMetadata: null,
  errorGettingFeatureData: null,
};

FeatureDetailTabs.propTypes = {
  featureId: PropTypes.string.isRequired,
  featureMetadata: PropTypes.shape(),
  errorGettingFeatureData: PropTypes.shape(),
  loadingFeatureData: PropTypes.bool.isRequired,
};

export default FeatureDetailTabs;
