import formatPostDate from '../../utils/date-utils';

/**
 * @param  {Object} workflowData - workflow data from backend - format
 * @return  {Array} - array of objects with data in each object needed for a row of the workflow table
 */
export const buildWorkflowTableRowsData = (workflowData = {}) => {
  return (
    workflowData.map((data) => ({
      name: data.entry?.name,
      state: data.entry?.state,
      started: formatPostDate(data.entry?.startedAt),
      completed: formatPostDate(data.entry?.endedAt),
      duration: convertMStoDate(data.entry?.durationInMs),
      user: data.entry?.assignee,
    })) || []
  );
};

export const convertMStoDate = (ms) => {
  if (!Number.isInteger(ms)) return '';

  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);

  return `${days}d ${hours}:${minutes}:${sec}`;
};
