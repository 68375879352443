/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import useWorkflowApi from '../../hooks/useWorkflowApi';
import styles from './CreateProofPage.module.scss';
import BackLink from '../../components/BackLink/BackLink';
import { Routes } from '../../App.constants';

const CreateProofPage = () => {
  const { processId } = useParams();
  const { getCreateProof, createProofData, errorGettingCreateProof } = useWorkflowApi();
  const pageMessage = errorGettingCreateProof ? <p>Cannot load proof page</p> : <p>Loading proof page</p>;
  const [styled, setStyled] = useState(true);

  // get proof on mount
  useEffect(() => {
    getCreateProof(processId);
  }, [getCreateProof, processId]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>{styled ? 'Author Proof' : 'Copy Proof'}</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <BackLink to={Routes.MY_WORK.toLink()} linkText="My Work in Progress" />
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">{styled ? 'Author Proof' : 'Copy Proof'}</h1>
              <div className="d-flex justify-content-end align-items-end">
                <Button
                  className="me-2 mb-1"
                  onClick={() => setStyled(!styled)}
                  aria-label={styled ? 'Show Copy Proof' : 'Show Author Proof'}
                  color="primary"
                  id="showtag"
                >
                  {styled ? 'Show Copy Proof' : 'Show Author Proof'}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        {!createProofData && pageMessage}
        {createProofData && (
          <div className={styles.help}>
            <div
              dangerouslySetInnerHTML={{
                __html: `<link rel="stylesheet" href=${
                  styled ? createProofData.html.styledView?.css : createProofData.html.copyView?.css
                }><div id="content">${
                  styled ? createProofData.html.styledView?.fragment : createProofData.html.copyView?.fragment
                }</div>`,
              }}
            />
          </div>
        )}
      </Container>
    </DefaultTemplate>
  );
};

export default CreateProofPage;
