import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import IndexSearchForm from './IndexSearchForm/IndexSearchForm';
import useArticleApi from '../../hooks/useArticleApi';
import ArticleSearchTable from '../ArticleSearchPage/ArticleSearchTable/ArticleSearchTable';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import refineArticleSearchResults from '../ArticleSearchPage/ArticleSearchTable/ArticleSearchTable.utils';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { setIndexSearchFormData, setIndexSearchTableConfigPageIndex } from '../../store/action-creators';
import { checkForEmptyData } from '../../utils/form-utils';
import HelpIcon from '../../components/HelpIcon/HelpIcon';

const IndexSearchPage = () => {
  const {
    getArticles,
    articlesData,
    errorGettingArticles,
    loadingArticles,
    abortGettingArticles,
    updateArticle,
    updateData,
    errorUpdatingArticle,
    updatingArticle,
  } = useArticleApi();
  const [errorGettingIndexMessage, setErrorGettingIndexMessage] = useState('');
  const [tableRowsData, setTableRowsData] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [status, setStatus] = useState(false);
  const dispatch = useDispatch();
  const indexSearchFormData = useSelector((state) => state.indexSearchFormData);
  const [indexSearchRequestData, setIndexSearchRequestData] = useState({
    name: indexSearchFormData?.name,
    corpusId: indexSearchFormData.corpus?.value,
    indexed: indexSearchFormData.indexed?.value,
  });
  const history = useHistory();

  // Every time indexSearchRequestData state is set make sure indexSearchRequestData
  // exists and is not empty and if so set appropriate state and get articles.
  // This will happen on mount (if there is data for the form in Redux) or on form submit.
  useEffect(() => {
    if (indexSearchRequestData && !checkForEmptyData(indexSearchRequestData)) {
      setTableRowsData(null);
      getArticles(indexSearchRequestData);
      setStatus(!indexSearchRequestData?.indexed);
    }
  }, [getArticles, indexSearchRequestData]);

  const onSearch = (formData) => {
    dispatch(setIndexSearchFormData(formData));
    dispatch(setIndexSearchTableConfigPageIndex(0));

    setErrorGettingIndexMessage('');
    setStatus(!formData?.indexed?.value);
    setIndexSearchRequestData({
      name: formData?.name,
      corpusId: formData.corpus?.value,
      indexed: formData.indexed?.value,
      articleId: formData.articleId,
      topicId: formData.topicId,
    });
  };

  const onReset = () => {
    setErrorGettingIndexMessage('');
    setTableRowsData(null);
    setIndexSearchRequestData(null);
    dispatch(setIndexSearchTableConfigPageIndex(0));
  };

  useEffect(() => {
    if (articlesData) {
      setTableRowsData(refineArticleSearchResults(articlesData?.articles));
      setIndexSearchRequestData(null);
    }
  }, [articlesData]);

  // If there is an error during the fetch (400 or 404) remove the current articles
  // data from state and set the error messaging in state.
  useEffect(() => {
    if (errorGettingArticles) {
      const errorStatus = errorGettingArticles?.status;

      if (errorStatus === 404) {
        setErrorGettingIndexMessage('No articles were found.');
      } else if (errorStatus === 400) {
        setErrorGettingIndexMessage('Bad request');
      } else {
        setErrorGettingIndexMessage('There was an issue loading the processes.');
      }
    }
  }, [errorGettingArticles]);

  // If there is an error during the update (400 or 404) send toast feedback
  useEffect(() => {
    if (errorUpdatingArticle) {
      toast.error('Article cannot be accepted');
    }
  }, [errorUpdatingArticle]);

  // if updateData exists, send toast feedback to user
  useEffect(() => {
    if (updateData) {
      toast.success('Article accepted');
      setTimeout(() => window.location.reload(), 500);
    }
  }, [history, updateData]);

  const onConfirmDeleteButtonClick = () => {
    updateArticle(showConfirmModal.articleId, showConfirmModal.major);
    setShowConfirmModal(false);
  };

  const handleAcceptAction = (articleId, major) => {
    setShowConfirmModal({ articleId, major });
  };

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Index Search</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Index Search</h1>
              <HelpIcon link="indexing" id="indexSearch" />
            </div>
            <IndexSearchForm
              loading={loadingArticles}
              onSearch={onSearch}
              onReset={onReset}
              abort={abortGettingArticles}
              setIndexSearchFormData={setIndexSearchFormData}
              indexSearchFormData={indexSearchFormData}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
          <h1 className="text-primary fw-bold">Results</h1>
        </div>
        {!errorGettingIndexMessage && !loadingArticles && !updatingArticle && !tableRowsData && (
          <p>Use the form to search for articles to index...</p>
        )}
        {tableRowsData && !loadingArticles && !updatingArticle && (
          <ArticleSearchTable
            data={tableRowsData}
            actionSelect={{ status, wftype: 'indexing' }}
            handleAcceptAction={handleAcceptAction}
          />
        )}
        {errorGettingIndexMessage && !loadingArticles && !updatingArticle && errorGettingIndexMessage}
        {loadingArticles && (
          <div className="mt-3">
            <SpinnerComponent size={spinnerSize1} setFullVh={false} />
          </div>
        )}
        {showConfirmModal && (
          <ConfirmModal
            toggle={() => setShowConfirmModal(false)}
            header="Confirm Accept"
            confirmButton="Confirm"
            message="Article will no longer appear in the to be indexed list"
            onConfirmButtonClick={onConfirmDeleteButtonClick}
          />
        )}
      </Container>
    </DefaultTemplate>
  );
};

export default IndexSearchPage;
