import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { UncontrolledTooltip } from 'reactstrap';

import { EDITORIAL_API, Routes } from '../../../App.constants';
import TextColumnFilter from '../../../components/TextColumnFilter/TextColumnFilter';
import DateTableCell from '../../../components/DateTableCell/DateTableCell';
import { selectTheme } from '../../../utils/select-field-utils';
import styles from './ArticleSearchTable.module.scss';

const ArticleSearchPageColumns = (actionSelect, handleAcceptAction) => [
  {
    Header: 'Article ID',
    accessor: 'articleId',
    disableFilters: true,
    Cell: (e) => {
      const { value } = e;

      return <Link to={Routes.ARTICLE_DETAIL.toLink({ articleId: value })}>{value}</Link>;
    },
  },
  {
    Header: 'Name',
    accessor: 'name',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { value, row } = e;
      const { original } = row;
      const { articleId } = original;

      return <Link to={Routes.ARTICLE_VIEW.toLink({ articleId, versionId: 'null' })}>{value}</Link>;
    },
  },
  {
    Header: 'Title',
    accessor: 'titleXml',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Type',
    accessor: 'articleTypeName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Posted',
    disableFilters: true,
    accessor: 'postDate',
    Cell: (e) => {
      const { row } = e;
      const { original, id } = row;
      const { postDateValue } = original;

      return (
        <DateTableCell id={`row${id}`} dateString={postDateValue?.dateMonthYear} timeString={postDateValue?.time} />
      );
    },
  },
  {
    Header: 'Stable',
    disableFilters: true,
    accessor: 'stableValueToDisplay',
  },
  {
    Header: 'In Work',
    disableFilters: true,
    accessor: 'inWorkValueToDisplay',
    Cell: (d) => {
      const { value, row } = d;
      const { original } = row;
      const { articleId } = original;

      if (value === 'Y') {
        return <Link to={Routes.WIP_DETAIL.toLink({ articleId, processId: 'null' })}>{value}</Link>;
      }

      return value;
    },
  },
  {
    Header: 'Deleted',
    disableFilters: true,
    id: 'deleted',
    accessor: 'deletedValueToDisplay',
  },
  {
    Header: 'Asm Count',
    disableFilters: true,
    accessor: 'mediaCount',
  },
  {
    Header: 'Subject',
    disableFilters: true,
    accessor: 'subjectName',
  },
  {
    Header: 'Cluster',
    disableFilters: true,
    accessor: 'articleCluster',
    Cell: (e) => {
      const { row, value } = e;
      const { original } = row;
      const { articleClusterId } = original;

      if (articleClusterId) {
        return (
          <Link
            to={{
              pathname: Routes.CLUSTER_DETAIL.toLink({ clusterId: articleClusterId }),
              state: { backLink: { to: Routes.ARTICLE_SEARCH.toLink(), text: 'Article Search' } },
            }}
          >
            {value}
          </Link>
        );
      }

      return value;
    },
  },
  {
    Header: 'Topic ID',
    disableFilters: true,
    id: 'topicId',
    accessor: 'topicId',
    Cell: (e) => {
      const { row } = e;
      const { original } = row;
      const { topics } = original;

      return topics.map((topic) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          key={topic.topicId}
          className="p-0"
          href={`http://www.britannica.com/EBchecked/topic/${topic.topicId}`}
        >
          {topic.topicId}
        </a>
      ));
    },
  },
  {
    Header: 'Action',
    disableFilters: true,
    id: 'wipActionLink',
    Cell: (e) => {
      const { row } = e;
      const { original } = row;
      const { articleTypeId, name, articleId, inWorkValueToDisplay, major, deletedValueToDisplay, allowWip } = original;
      const ACTION_OPTIONS = [
        {
          value: 'Review',
          label: (
            <div>
              <p id="review" className="mb-0">
                Review
              </p>
              <UncontrolledTooltip placement="left" target="review">
                Review changes since last indexing
              </UncontrolledTooltip>
            </div>
          ),
        },
        {
          value: 'Create WIP',
          label: (
            <>
              <p id="createwip" className="mb-0">
                Create WIP
              </p>
              <UncontrolledTooltip placement="left" target="createwip">
                Index this article
              </UncontrolledTooltip>
            </>
          ),
        },
        {
          value: 'Accept',
          label: (
            <>
              <p id="accept" className="mb-0">
                Accept
              </p>
              <UncontrolledTooltip placement="left" target="accept">
                No indexing required
              </UncontrolledTooltip>
            </>
          ),
        },
      ];
      const history = useHistory();

      if (allowWip && inWorkValueToDisplay === 'N' && deletedValueToDisplay !== 'Y' && !actionSelect?.status) {
        return (
          <Link
            to={{
              pathname: Routes.WIP_CREATE.toLink(),
              state: {
                backLink: { to: Routes.ARTICLE_SEARCH.toLink(), text: 'Article Search' },
                articleTypeId,
                name,
                articleId,
                wftype: actionSelect?.wftype,
              },
            }}
          >
            Create WIP
          </Link>
        );
      }

      const handleActionChange = (selected) => {
        if (selected.value === 'Review') {
          window.open(`${EDITORIAL_API}/ecm/index/articles/${articleId}/diffview`);
        }

        if (selected.value === 'Create WIP') {
          history.push({
            pathname: Routes.WIP_CREATE.toLink(),
            state: {
              backLink: { to: Routes.INDEX_SEARCH.toLink(), text: 'Index Search' },
              articleTypeId,
              name,
              articleId,
              wftype: 'indexing',
            },
          });
        }

        if (selected.value === 'Accept') {
          handleAcceptAction(articleId, major);
        }

        return null;
      };

      if (actionSelect?.status && inWorkValueToDisplay === 'N') {
        return (
          <Select
            name="action"
            value="Select"
            onChange={handleActionChange}
            options={ACTION_OPTIONS}
            className={styles.input}
            theme={selectTheme}
            menuPlacement="bottom"
          />
        );
      }

      if (actionSelect?.status) {
        return (
          <>
            <a
              href={`${EDITORIAL_API}/ecm/index/articles/${articleId}/diffview`}
              className="p-0"
              target="_blank"
              rel="noreferrer noopener"
            >
              Review
            </a>
          </>
        );
      }

      return null;
    },
  },
];

export default ArticleSearchPageColumns;
