import React, { useEffect } from 'react';
import { Form, Label, Input, FormGroup, Button, FormFeedback, FormText } from 'reactstrap';

import userLoginSchema from './UserLoginForm.schema';
import userFormHandler from '../../../hooks/useFormHandler';
import USER_LOGIN_FORM_INITIAL_STATE from './UserLoginForm.constants';
import useAuthApi from '../../../hooks/useAuthApi';
import styles from './UserLoginForm.module.scss';

const UserLoginForm = () => {
  const { formData, validationErrors, handleInputChange, isFormValid, setFormError, formError } =
    userFormHandler(USER_LOGIN_FORM_INITIAL_STATE);
  const { signInUser, errorSigningIn, signingIn, errorGettingUser, gettingUser } = useAuthApi();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid(userLoginSchema)) {
      const requestBody = JSON.stringify(formData);

      signInUser(requestBody);
    }
  };

  // If there is an error during sign in set the error messaging in state.
  useEffect(() => {
    const errorStatus = errorSigningIn?.status;

    if (errorStatus === 404 || errorStatus === 400) {
      setFormError('Invalid username or password.');
    } else if (errorSigningIn) {
      setFormError('There was an issue signing you in. Please try again.');
    }
  }, [errorSigningIn, setFormError]);

  // If there is an error getting user data set the error messaging in state.
  useEffect(() => {
    if (errorGettingUser) {
      setFormError('There was an issue signing you in. Please try again.');
    }
  }, [errorGettingUser, setFormError]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="username">Username:</Label>
        <Input
          id="username"
          name="username"
          value={formData?.username}
          onChange={handleInputChange}
          invalid={!!validationErrors?.username || !!formError}
        />
        <FormFeedback>{validationErrors?.username}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="password">Password:</Label>
        <Input
          id="password"
          name="password"
          type="password"
          value={formData?.password}
          onChange={handleInputChange}
          invalid={!!validationErrors?.password || !!formError}
        />
        <FormFeedback>{validationErrors?.password}</FormFeedback>
      </FormGroup>
      {formError && <FormText color="danger">{formError}</FormText>}
      <div className="w-100 d-flex justify-content-end align-items-center">
        <Button type="submit" color="primary" className={styles.loginBtn} disabled={signingIn || gettingUser}>
          {signingIn || gettingUser ? 'Signing in...' : 'Sign in'}
        </Button>
      </div>
    </Form>
  );
};

export default UserLoginForm;
