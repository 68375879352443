import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '../../../App.constants';
import TextColumnFilter from '../../../components/TextColumnFilter/TextColumnFilter';
import DateTableCell from '../../../components/DateTableCell/DateTableCell';

const RecentArticlesPageColumns = [
  {
    Header: 'Feature ID',
    accessor: 'featureId',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { value } = e;

      return <Link to={Routes.FEATURE_DETAIL.toLink({ featureId: value })}>{value}</Link>;
    },
  },
  {
    Header: 'Name',
    accessor: 'name',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { row, value } = e;
      const { original } = row;
      const { featureId } = original;

      return <Link to={Routes.FEATURE_VIEW.toLink({ featureId })}>{value}</Link>;
    },
  },
  {
    Header: 'Feature Type',
    accessor: 'featureTypeName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Language',
    accessor: 'languageName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Tier',
    accessor: 'tierName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'In Work',
    accessor: 'inwork',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Deleted',
    accessor: 'deleted',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Posted',
    disableFilters: true,
    accessor: 'postDate',
    Cell: (e) => {
      const { row } = e;
      const { original, id } = row;
      const { postDateValue } = original;

      return (
        <DateTableCell id={`row${id}`} dateString={postDateValue?.dateMonthYear} timeString={postDateValue?.time} />
      );
    },
  },
];

export default RecentArticlesPageColumns;
