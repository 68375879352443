import formatPostDate from '../../../../utils/date-utils';

/**
 * @param  {Object} consumerTopicsData - consumer topics data from backend
 * @return  {Array} - array of objects with data in each object needed for a row of the consumer topics table
 */
const buildConsumerTopicsData = (consumerTopicsData = []) => {
  return (
    consumerTopicsData?.map((data) => ({
      ...data,
      postedDate: formatPostDate(data.postedDate),
      publishedDate: formatPostDate(data.batch.publishEnd),
      batchId: data.batch.batchId,
    })) || []
  );
};

export default buildConsumerTopicsData;
