import React, { useEffect } from 'react';
import { Row, Col, Input, FormGroup, Label, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { diff } from 'deep-object-diff';

import DateTableCell from '../../../../../components/DateTableCell/DateTableCell';
import useFormHandler from '../../../../../hooks/useFormHandler';
import featureDetailSchema from './FeatureDetailForm.schema';

const FeatureDetailForm = ({ featureMetadata, save, setSave, editFeature, error }) => {
  const { formData, handleInputChange, isFormValid, validationErrors, setValidationErrors } = useFormHandler({
    name: featureMetadata.name,
    urlTitle: featureMetadata.urlTitle,
    stable: featureMetadata.stable,
  });

  // to handle the  error status
  useEffect(() => {
    if (error) {
      const errorStatus = error?.status;

      if (errorStatus === 403) {
        setValidationErrors({ ...validationErrors, name: 'Feature Name already exists' });
      } else if (errorStatus === 460) {
        setValidationErrors({ ...validationErrors, urlTitle: 'UrlTitle already exists' });
      } else if (errorStatus !== 401) {
        toast.error('Feature cannot be created');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, setValidationErrors]);

  // handles when save button is selected
  useEffect(() => {
    if (save) {
      setSave(false);

      const refinedFormData = diff({ name: featureMetadata.name }, formData);

      if (!Object.keys(refinedFormData).length) {
        toast.error('No changes detected');
      } else if (isFormValid(featureDetailSchema)) {
        editFeature(
          { name: formData.name, stable: formData.stable !== 'N', urlTitle: formData.urlTitle },
          featureMetadata.featureId
        );
      }
    }
  }, [editFeature, featureMetadata.featureId, featureMetadata.name, formData, isFormValid, save, setSave]);

  return (
    <Row>
      <Col md="6">
        <FormGroup row>
          <Label for="featureId" md={4} className="fw-bold">
            Feature ID
          </Label>
          <Col md={8}>
            <Input id="featureId" name="featureId" value={featureMetadata.featureId} disabled />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="name" md={4} className="fw-bold">
            Feature Name
          </Label>
          <Col md={8}>
            <Input
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              invalid={!!validationErrors?.name}
            />
            <FormFeedback>{validationErrors.name}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="featureType" md={4} className="fw-bold">
            Feature Type
          </Label>
          <Col md={8}>
            <Input id="featureType" name="featureType" value={featureMetadata.featureType} disabled />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="tier" md={4} className="fw-bold">
            Tier
          </Label>
          <Col md={8}>
            <Input id="tier" name="tier" value={featureMetadata.tier} disabled />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="language" md={4} className="fw-bold">
            Language
          </Label>
          <Col md={8}>
            <Input id="language" name="language" value={featureMetadata.language} disabled />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="product" md={4} className="fw-bold">
            Product
          </Label>
          <Col md={8}>
            <Input id="product" name="product" value={featureMetadata.product} disabled />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="updated" md={4} className="fw-bold">
            Last Updated
          </Label>
          <Col md={8}>
            <DateTableCell
              id="updated"
              dateString={featureMetadata.updated?.dateMonthYear}
              timeString={featureMetadata.updated?.time}
            />
          </Col>
        </FormGroup>

        {featureMetadata.product !== 'School' && (
          <FormGroup row>
            <Label for="urlTitle" md={4} className="fw-bold">
              urlTitle
            </Label>
            <Col md={8}>
              <Input
                id="urlTitle"
                name="urlTitle"
                onChange={handleInputChange}
                placeholder="Url Title..."
                value={formData.urlTitle}
                invalid={!!validationErrors?.urlTitle}
              />
              <FormFeedback>{validationErrors.urlTitle}</FormFeedback>
            </Col>
          </FormGroup>
        )}

        <FormGroup row>
          <Label for="stable" md={4} className="fw-bold">
            Stable
          </Label>
          <Col md={8}>
            <Input
              id="stable"
              name="stable"
              onChange={handleInputChange}
              value={formData.stable}
              invalid={!!validationErrors?.stable}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="purged" md={4} className="fw-bold">
            Purged
          </Label>
          <Col md={8}>
            <Input id="purged" name="purged" value={featureMetadata.purged} disabled />
          </Col>
        </FormGroup>
      </Col>
    </Row>
  );
};

FeatureDetailForm.defaultProps = {
  featureMetadata: null,
  editFeature: null,
  error: null,
};

FeatureDetailForm.propTypes = {
  featureMetadata: PropTypes.shape(),
  save: PropTypes.bool.isRequired,
  setSave: PropTypes.func.isRequired,
  editFeature: PropTypes.func,
  error: PropTypes.shape(),
};

export default FeatureDetailForm;
