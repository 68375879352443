import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ModalHeader, ModalBody, Form, Input, Label, FormGroup, Button, Alert } from 'reactstrap';
import { toast } from 'react-toastify';

import useFormHandler from '../../hooks/useFormHandler';
import useGroupApi from '../../hooks/useGroupApi';
import useAuthApi from '../../hooks/useAuthApi';

const AddGroupModal = ({ toggle }) => {
  const { addGroup, addData, errorAddingGroup } = useGroupApi();
  const { formData, handleInputChange, handleResetForm, formError, setFormError, isFormEmpty } = useFormHandler({
    name: '',
    description: '',
  });
  const { userDataFromStore } = useAuthApi();
  const username = userDataFromStore?.username;

  // If the groups data is retrieved successfully set the toast, close the modal and reload page
  useEffect(() => {
    if (addData) {
      toast.success('Group added');
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [addData]);

  // If there is an error during the fetch (400 or 404) set the errorm message and remove the
  // current subjects
  useEffect(() => {
    if (errorAddingGroup) {
      const errorStatus = errorAddingGroup?.status;

      if (errorStatus === 404) {
        toast.error('Cannot add group');
      } else if (errorStatus === 400) {
        toast.error('Invalid input');
      } else if (errorStatus === 403) {
        toast.error('Group with that name already exists');
      }
    }
  }, [errorAddingGroup]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormEmpty()) {
      const refinedFormData = { ...formData, username };

      addGroup(refinedFormData);

      setFormError('');
    }
  };

  const onFormReset = (e) => {
    e.preventDefault();

    handleResetForm();
  };

  return (
    <Modal isOpen toggle={toggle} size="xl" centered className="px-0 px-sm-4">
      <ModalHeader toggle={toggle} className="text-primary fw-bold border-bottom border-primary border-1">
        <span className="primary">Add Group</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit} onReset={onFormReset}>
              <FormGroup className="no-gutters">
                <Label htmlFor="name">Name:</Label>
                <Input name="name" id="name" bsSize="sm" value={formData.name} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="no-gutters">
                <Label htmlFor="description">Description:</Label>
                <Input
                  name="description"
                  id="description"
                  bsSize="sm"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <Alert color="danger" isOpen={!!formError} toggle={() => setFormError('')} fade={false}>
                {formError}
              </Alert>
              <div className="text-center mt-4">
                <Button type="submit" color="primary" block data-testid="Add">
                  Add
                </Button>
                <Button type="reset" className="mt-2" color="link">
                  Reset
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

AddGroupModal.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default AddGroupModal;
