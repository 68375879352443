import React from 'react';
import classnames from 'classnames';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './SpinnerComponent.module.scss';

const SpinnerComponent = ({ size, setFullVh }) => (
  <div
    className={classnames(
      { [styles.spinnerContainer]: setFullVh },
      'w-100 d-flex justify-content-center align-items-center'
    )}
  >
    <Spinner color="info" style={size} />
  </div>
);

SpinnerComponent.propTypes = {
  size: PropTypes.shape({ width: PropTypes.string, height: PropTypes.string }).isRequired,
  setFullVh: PropTypes.bool.isRequired,
};

export default SpinnerComponent;
