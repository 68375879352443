/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, FormGroup, Label, Input, UncontrolledTooltip } from 'reactstrap';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AiFillTags } from 'react-icons/ai';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import BackLink from '../../components/BackLink/BackLink';
import { Routes, EDITORIAL_API } from '../../App.constants';
import useArticleApi from '../../hooks/useArticleApi';
import styles from './ArticleViewPage.module.scss';

const ArticleViewPage = () => {
  const { articleId, versionId } = useParams();
  const { getArticleView, articleViewData, loadingArticleViewData } = useArticleApi();
  const [styledView, setStyledView] = useState('');
  const [tagView, setTagView] = useState('');
  const [styled, setStyled] = useState(true);
  const [showIndexRefs, setShowIndexRefs] = useState(false);
  const [showEdNotes, setShowEdNotes] = useState(false);
  const backLinkState = useLocation().state?.backLink;

  useEffect(() => {
    if (articleViewData) {
      setStyledView(
        `<link rel="stylesheet" href=${articleViewData.html.styledView?.css}><div id="content">${articleViewData.html.styledView?.fragment}</div>`.replaceAll(
          '"/static/images/link_table.png',
          `"${EDITORIAL_API}/static/images/link_table.png`
        )
      );
      setTagView(
        `<link rel="stylesheet" href=${articleViewData.html.tagView?.css}>${articleViewData.html.tagView?.fragment}`
      );
    }
  }, [articleViewData]);

  // get article viewer content on mount
  useEffect(() => {
    getArticleView(articleId, versionId);
  }, [articleId, getArticleView, versionId]);

  useEffect(() => {
    if (styledView) {
      const atags = document.getElementsByClassName('index');

      for (let i = 0; i < atags.length; i += 1) {
        atags[i].style.display = showIndexRefs ? 'inline' : 'none';
      }
    }
  }, [showIndexRefs, styledView, styled]);

  useEffect(() => {
    if (styledView) {
      const ednotes = document.getElementsByClassName('ednote');

      for (let i = 0; i < ednotes.length; i += 1) {
        ednotes[i].style.display = showEdNotes ? 'block' : 'none';
      }
    }

    const { hash } = window.location;

    if (hash) {
      window.location.replace(hash);
    }
  }, [showEdNotes, styledView]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Article Viewer</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <BackLink
              to={backLinkState?.to || Routes.ARTICLE_SEARCH.toLink()}
              linkText={backLinkState?.text || 'Article Search'}
            />

            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Article Viewer</h1>
              <div className="d-flex justify-content-end align-items-end">
                <FormGroup switch className="me-2">
                  <Label check for="showIndexRefsSwitch">
                    Index Refs
                  </Label>
                  <Input
                    type="switch"
                    id="showIndexRefsSwitch"
                    name="showIndexRefsSwitch"
                    checked={showIndexRefs}
                    onChange={() => setShowIndexRefs(!showIndexRefs)}
                  />
                </FormGroup>

                <FormGroup switch>
                  <Label check for="showEdNotesSwitch">
                    Editorial Notes
                  </Label>
                  <Input
                    type="switch"
                    id="showEdNotesSwitch"
                    name="showEdNotesSwitch"
                    checked={showEdNotes}
                    className="mt-1"
                    onChange={() => setShowEdNotes(!showEdNotes)}
                    color="primary"
                  />
                </FormGroup>
              </div>
              <div className="d-flex justify-content-end align-items-end">
                <Button
                  className="me-2 mb-1"
                  onClick={() => setStyled(!styled)}
                  aria-label="Show Tags"
                  color="primary"
                  id="showtag"
                >
                  <AiFillTags />
                </Button>
                <UncontrolledTooltip target="showtag">{styled ? 'Show Tags' : 'Hide Tags'}</UncontrolledTooltip>
              </div>
            </div>
          </Col>
        </Row>
        {loadingArticleViewData && (
          <div className="mt-3">
            <SpinnerComponent size={spinnerSize1} setFullVh={false} />
          </div>
        )}
        {!loadingArticleViewData && (
          <div className={styles.viewer}>
            <span dangerouslySetInnerHTML={{ __html: styled ? styledView : tagView }} />
          </div>
        )}
      </Container>
    </DefaultTemplate>
  );
};

export default ArticleViewPage;
