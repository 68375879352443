import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '../../../App.constants';
import TextColumnFilter from '../../../components/TextColumnFilter/TextColumnFilter';

const fullTextSearchTableColumns = [
  {
    Header: 'Article ID',
    accessor: 'articleId',
    disableFilters: true,
    Cell: (e) => {
      const { value } = e;

      return <Link to={Routes.ARTICLE_DETAIL.toLink({ articleId: value })}>{value}</Link>;
    },
  },
  {
    Header: 'Name',
    accessor: 'name',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { value, row } = e;
      const { original } = row;
      const { articleId } = original;

      return <Link to={Routes.ARTICLE_VIEW.toLink({ articleId, versionId: 'null' })}>{value}</Link>;
    },
  },
  {
    Header: 'Title',
    accessor: 'title',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Type',
    accessor: 'articleTypeName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Snippet',
    accessor: 'snippet',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { value } = e;

      // eslint-disable-next-line react/no-danger
      return <span dangerouslySetInnerHTML={{ __html: value }} />;
    },
  },
];

export default fullTextSearchTableColumns;
