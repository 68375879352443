import { string, object } from 'yup';

const groupEditSchema = object().shape({
  name: string().required({
    field: 'name',
    errorMessage: 'Required field.',
  }),
  description: string().required({
    field: 'description',
    errorMessage: 'Required field.',
  }),
});

export default groupEditSchema;
