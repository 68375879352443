import React, { lazy, Suspense } from 'react';

const LazyArticleSearchPage = lazy(() => import('./ArticleSearchPage'));

const ArticleSearchPage = (props) => (
  <Suspense fallback={null}>
    <LazyArticleSearchPage {...props} />
  </Suspense>
);

export default ArticleSearchPage;
