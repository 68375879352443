import { useCallback } from 'react';
import { stringify } from 'query-string';

import { API_MAX_PER_PAGE, ELSIE_API } from '../App.constants';
import useFetchWithState from './useFetchWithState';
import useAuthApi from './useAuthApi';

const useP2PConsumerApi = () => {
  const { getAuthCookies } = useAuthApi();
  const { encodedTicketFromCookie } = getAuthCookies();

  const {
    amFetchWithState: getConsumerTopicsData,
    data: consumerTopicsData,
    error: errorGettingConsumerTopics,
    loading: gettingConsumerTopics,
  } = useFetchWithState();
  const {
    amFetchWithState: getConsumerPortalsData,
    data: consumerPortalsData,
    error: errorGettingConsumerPortals,
    loading: gettingConsumerPortals,
  } = useFetchWithState();
  const {
    amFetchWithState: getConsumerFeaturesData,
    data: consumerFeaturesData,
    error: errorGettingConsumerFeatures,
    loading: gettingConsumerFeatures,
  } = useFetchWithState();
  const {
    amFetchWithState: p2pConsumerTopicData,
    data: p2pTopicData,
    error: errorP2PingTopicGroup,
    loading: p2pingTopicGroup,
  } = useFetchWithState();
  const {
    amFetchWithState: p2pConsumerPortalData,
    data: p2pPortalData,
    error: errorP2PingPortalGroup,
    loading: p2pingPortalGroup,
  } = useFetchWithState();
  const {
    amFetchWithState: p2pConsumerFeatureData,
    data: p2pFeatureData,
    error: errorP2PingFeatureGroup,
    loading: p2pingFeatureGroup,
  } = useFetchWithState();

  const getConsumerTopics = useCallback(
    (consumerTopicsSearchFormData) => {
      getConsumerTopicsData(
        `${ELSIE_API}/p2p/consumer/topics?${stringify(consumerTopicsSearchFormData, {
          arrayFormat: 'comma',
        })}&perPage=${API_MAX_PER_PAGE}`
      );
    },
    [getConsumerTopicsData]
  );

  const getConsumerPortals = useCallback(
    (consumerPortalsSearchFormData) => {
      getConsumerPortalsData(
        `${ELSIE_API}/p2p/consumer/portals?${stringify(consumerPortalsSearchFormData, {
          arrayFormat: 'comma',
        })}&perPage=${API_MAX_PER_PAGE}`
      );
    },
    [getConsumerPortalsData]
  );

  const getConsumerFeatures = useCallback(
    (consumerFeaturesSearchFormData) => {
      getConsumerFeaturesData(
        `${ELSIE_API}/p2p/consumer/features?${stringify(consumerFeaturesSearchFormData, {
          arrayFormat: 'comma',
        })}&perPage=${API_MAX_PER_PAGE}`
      );
    },
    [getConsumerFeaturesData]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const p2pConsumerTopic = useCallback(
    (requestBody) => {
      p2pConsumerTopicData(`${ELSIE_API}/p2p/consumer/batches/0/topics`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [p2pConsumerTopicData, encodedTicketFromCookie]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const p2pConsumerPortal = useCallback(
    (requestBody) => {
      p2pConsumerPortalData(`${ELSIE_API}/p2p/consumer/batches/0/portals`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [p2pConsumerPortalData, encodedTicketFromCookie]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const p2pConsumerFeature = useCallback(
    (requestBody) => {
      p2pConsumerFeatureData(`${ELSIE_API}/p2p/consumer/batches/0/features`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [p2pConsumerFeatureData, encodedTicketFromCookie]
  );

  return {
    getConsumerTopics,
    consumerTopicsData,
    errorGettingConsumerTopics,
    gettingConsumerTopics,
    getConsumerPortals,
    consumerPortalsData,
    errorGettingConsumerPortals,
    gettingConsumerPortals,
    getConsumerFeatures,
    consumerFeaturesData,
    errorGettingConsumerFeatures,
    gettingConsumerFeatures,
    p2pConsumerTopic,
    p2pTopicData,
    errorP2PingTopicGroup,
    p2pingTopicGroup,
    p2pConsumerPortal,
    p2pPortalData,
    errorP2PingPortalGroup,
    p2pingPortalGroup,
    p2pConsumerFeature,
    p2pFeatureData,
    errorP2PingFeatureGroup,
    p2pingFeatureGroup,
  };
};

export default useP2PConsumerApi;
