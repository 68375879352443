import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { BsPlusCircle } from 'react-icons/bs';

import useUserApi from '../../hooks/useUserApi';
import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import BackLink from '../../components/BackLink/BackLink';
import { Routes } from '../../App.constants';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import UserDetailMetadata from './UserDetailMetadata/UserDetailMetadata';
import UserDetailTable from './UserDetailTable/UserDetailTable';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import GroupSelectModal from '../../components/GroupSelectModal/GroupSelectModal';

const UserDetailPage = () => {
  const { userId, isUser } = useParams();
  const {
    getUser,
    userData,
    errorGettingUser,
    loadingUser,
    editUser,
    editData,
    errorEditingUser,
    editingUser,
    deleteGroupFromUser,
    deleteGroupData,
    errorDeletingGroupUser,
    deletingGroupUser,
    addGroupToUser,
    addGroupData,
    errorAddingGroupUser,
    addingGroupUser,
  } = useUserApi();
  const [tableRowsData, setTableRowsData] = useState([]);
  const [userMetadata, setUserMetadata] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUserSelectModal, setShowUserSelectModal] = useState(false);
  const [groupId, setGroupId] = useState();
  const backLinkState = useLocation().state?.backLink;
  const loading = editingUser || deletingGroupUser || loadingUser || addingGroupUser;

  const handleDeleteGroup = (value) => {
    setShowConfirmModal(true);
    setGroupId(value);
  };

  const onConfirmDeleteButtonClick = () => {
    deleteGroupFromUser(groupId, userId);
    setShowConfirmModal(false);
    setGroupId(null);
  };

  const handleSelectGroup = (value) => {
    addGroupToUser(value.groupId, userId);
  };

  // If the users data is retrieved successfully set the toast, close the modal and reload page
  useEffect(() => {
    if (addGroupData) {
      toast.success('Group added to user');
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [addGroupData]);

  // If there is an error during the fetch (400 or 404) set the errorm message and remove the
  // current subjects
  useEffect(() => {
    if (errorAddingGroupUser) {
      const errorStatus = errorAddingGroupUser?.status;

      if (errorStatus === 404) {
        toast.error('Cannot add group');
      } else if (errorStatus === 400) {
        toast.error('Invalid input');
      } else if (errorStatus === 403) {
        toast.error('Group with that name already exists');
      }
    }
  }, [errorAddingGroupUser]);

  // delete is successful, show the toast and reroute to homepage
  useEffect(() => {
    if (deleteGroupData) {
      toast.success('Group deleted');
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [deleteGroupData]);

  // if there is an error while deleting the article, show the error toast
  useEffect(() => {
    if (errorDeletingGroupUser) {
      const errorStatus = errorDeletingGroupUser.status;

      if (errorStatus === 400) {
        toast.error('Invalid input');
      }

      if (errorStatus === 404) {
        toast.error('Unable to find group');
      } else toast.error('Cannot be deleted');
    }
  }, [errorDeletingGroupUser]);

  // Get user data on mount
  useEffect(() => {
    getUser(userId);
  }, [userId, getUser]);

  useEffect(() => {
    if (userData) {
      setUserMetadata(userData);
      setTableRowsData(userData.groups);
    }
  }, [userData]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: User Detail {userId}</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            {isUser === 'null' && (
              <BackLink to={backLinkState?.to || Routes.USERS.toLink()} linkText={backLinkState?.text || 'Users'} />
            )}

            <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">User Detail</h1>
              {/* <HelpIcon link="user-details" id="userDetail" /> */}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={5} className="my-4">
            {userMetadata && (
              <UserDetailMetadata
                userMetadata={userMetadata}
                setUserMetadata={setUserMetadata}
                editUserData={editData}
                userId={userId}
                onUpdate={editUser}
                error={errorEditingUser}
                loading={editingUser}
                isUsersDetailPage={isUser !== 'null'}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {isUser === 'null' && (
              <div className="d-flex justify-content-center align-items-center mb-3">
                <Button
                  type="button"
                  className="d-flex align-items-center"
                  color="link"
                  onClick={() => setShowUserSelectModal(true)}
                >
                  <BsPlusCircle className="me-1" />
                  Add group
                </Button>
              </div>
            )}

            {errorGettingUser && <p>There was an issue loading the user data.</p>}
            {(loading || !userData) && (
              <div className="mt-3">
                <SpinnerComponent size={spinnerSize1} setFullVh={false} />
              </div>
            )}
            {tableRowsData.length === 0 && !loading && <p>There are no users for the user.</p>}
            {tableRowsData.length !== 0 && !loading && (
              <UserDetailTable
                rowsData={tableRowsData}
                handleDeleteGroup={handleDeleteGroup}
                isUsersDetailPage={isUser !== 'null'}
              />
            )}
            {showConfirmModal && (
              <ConfirmModal
                toggle={() => setShowConfirmModal(false)}
                header="Confirm Delete"
                confirmButton="Delete"
                message="Are you sure you want to delete this user from this user?"
                showButtons
                onConfirmButtonClick={onConfirmDeleteButtonClick}
              />
            )}
            {showUserSelectModal && (
              <GroupSelectModal toggle={() => setShowUserSelectModal(false)} handleSelectGroup={handleSelectGroup} />
            )}
          </Col>
        </Row>
      </Container>
    </DefaultTemplate>
  );
};

export default UserDetailPage;
