import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from './reducer';
import { ARTICLE_SEARCH_FORM_EMPTY_STATE } from '../pages/ArticleSearchPage/ArticleSearchForm/ArticleSearchForm.constants';
import ARTICLE_CLUSTER_SEARCH_FORM_EMPTY_STATE from '../pages/ArticleClusterSearchPage/ArticleClusterSearchForm/ArticleClusterSearchForm.constants';
import { DEFAULT_TABLE_PAGE_SIZE, DEFAULT_TABLE_PAGE_INDEX } from '../App.constants';
import { INDEX_SEARCH_INITIAL_FORM_DATA } from '../pages/IndexSearchPage/IndexSearchForm/IndexSearchForm.constants';

const initialState = {
  articleTypes: [],
  corpusTypes: [],
  user: undefined,
  articleSearchFormData: ARTICLE_SEARCH_FORM_EMPTY_STATE,
  articleSearchTablePageSize: DEFAULT_TABLE_PAGE_SIZE,
  articleSearchTablePageIndex: DEFAULT_TABLE_PAGE_INDEX,
  clusterSearchFormData: ARTICLE_CLUSTER_SEARCH_FORM_EMPTY_STATE,
  clusterSearchTablePageSize: DEFAULT_TABLE_PAGE_SIZE,
  clusterSearchTablePageIndex: DEFAULT_TABLE_PAGE_INDEX,
  versionHistoryTablePageSize: DEFAULT_TABLE_PAGE_SIZE,
  versionHistoryTablePageIndex: DEFAULT_TABLE_PAGE_INDEX,
  indexSearchFormData: INDEX_SEARCH_INITIAL_FORM_DATA,
  indexSearchTablePageSize: DEFAULT_TABLE_PAGE_SIZE,
  indexSearchTablePageIndex: DEFAULT_TABLE_PAGE_INDEX,
  myWorkTablePageSize: DEFAULT_TABLE_PAGE_SIZE,
  myWorkTablePageIndex: DEFAULT_TABLE_PAGE_INDEX,
  loginModalConfig: { isOpen: false, id: null },
  myWorkSortConfig: [{ id: 'started', desc: false }],
  myWorkFilters: [],
  featureSearchFormData: { products: null, featureTypes: null, featureId: '', featureName: '', purged: false },
  featureSearchTablePageSize: DEFAULT_TABLE_PAGE_SIZE,
  featureSearchTablePageIndex: DEFAULT_TABLE_PAGE_INDEX,
  fullTextSearchFormData: { text: '', articleTypes: null, corpus: null },
  fullTextSearchTablePageSize: DEFAULT_TABLE_PAGE_SIZE,
  fullTextSearchTablePageIndex: DEFAULT_TABLE_PAGE_INDEX,
  recentArticlesTablePageSize: DEFAULT_TABLE_PAGE_SIZE,
  recentArticlesTablePageIndex: DEFAULT_TABLE_PAGE_INDEX,
};

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'myWorkFilters',
    'user',
    'articleSearchTablePageSize',
    'clusterSearchTablePageSize',
    'articleSearchFormData',
    'clusterSearchFormData',
    'indexSearchFormData',
    'myWorkTablePageSize',
    'myWorkTablePageIndex',
    'myWorkSortConfig',
  ],
};
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer, initialState, composeWithDevTools());
export const persistor = persistStore(store);
