import { string, object } from 'yup';

const changePasswordModalSchema = object().shape({
  oldPassword: string().required({
    field: 'oldPassword',
    errorMessage: 'Required field.',
  }),
  newPassword: string().required({
    field: 'newPassword',
    errorMessage: 'Required field.',
  }),
  reenterPassword: string().required({
    field: 'reenterPassword',
    errorMessage: 'Required field.',
  }),
});

export default changePasswordModalSchema;
